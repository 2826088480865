import React, { useState, useEffect } from "react";

const TopBar = () => {
  const [currentText, setCurrentText] = useState(0);
  const textLines = [
    "Free Pick Up Options Available",
    "Akhuwat Makhwat Book Sale",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % textLines.length);
    }, 10000); // Change every 10 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [textLines.length]);

  const handleScroll = () => {
    setCurrentText((prevText) => (prevText + 1) % textLines.length);
  };

  return (
    <div 
      style={{
        backgroundColor: "black",
        color: "white",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleScroll} // Allow manual scroll on click
    >
      <p className="font-manrope font-bold text-[18px] text-white">{textLines[currentText]}</p>
    </div>
  );
};

export default TopBar;
