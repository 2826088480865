import React from 'react';
import { Box } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AkspStories = ({ topHead, para, gridImage, paddingLength }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className='flex flex-col h-full overflow-hidden rounded-[40px]'>
            <Box>
                <img src={gridImage} alt="Donate" className='rounded-t-[40px] w-full min-h-[238px] 
                object-cover lg:h-auto' />
            </Box>

            <Box
                className='bg-white flex-grow lg:px-80px px-6 flex flex-col justify-between'
                style={{
                    paddingTop: isMobile ? "32px" : paddingLength,
                    paddingBottom: isMobile ? "32px" : paddingLength
                }}
            >
                <div>


                    <CustomTypography
                        as='p' variant='p1' color='text-topGreen' weightFont='font-semibold'
                        alignment='text-center'

                    >
                        {topHead}

                    </CustomTypography>


                    <CustomTypography className="lg:pt-11 pt-6"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                            alignment='text-center'

                        >
                            {para}
                        </CustomTypography>

                </div>
            </Box>
        </Box>
    )
}

export default AkspStories;
