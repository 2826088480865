import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import { Link } from "react-router-dom";
import location from "../pictures/Privacy_location_icon.png";
import mobile from "../pictures/privacy_mobile_icon.png";
import phone from "../pictures/privacy_phone_icon.png";
import mail from "../pictures/privacy_mail_icon.png";

const PrivacyPolicy = () => {
    return (
        <Box className="lg:py-90px lg:px-[300px] md:py-16 md:px-16 py-6 px-4 bg-white max-w-screen-2xl mx-auto">

            <CustomTypography
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
            >
                Current as of June 21, 2024

            </CustomTypography>

            <CustomTypography className="pt-[10px]"
                as='h2' variant='h21' color='text-customBlack' weightFont='font-bold' alignment='text-center'
            >
                Privacy Policy

            </CustomTypography>



            <CustomTypography className="pt-10"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                This website is operated and owned by Akhuwat. We understand that visitors to our website
                may have concerns about the information they share with us and how to utilize that
                information. Akhuwat (Registration Number 3048949, NTN 3048949) is a non-profit
                organization which was registered in 2003 under Pakistan’s Societies Registration Act XXI
                of 1860. Akhuwat is an NGO approved Non-governmental organization (NGO) by Pakistan’s
                Federal Board of Revenue. For documentation review please <Link className="text-topGreen" to='/about'> click here </Link>

            </CustomTypography>

            <CustomTypography className="pt-10"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                Akhuwat tries its best to ensure that we protect the privacy of our online visitors.
                We don’t require or gather any data that hasn’t been shared with us knowingly by the
                visitors themselves. Akhuwat gathers data, such as name, address, email address, etc.,
                when submitted by the visitors. We gather this info to facilitate your donation to Akhuwat
                and also, communicate with you about it. This data is used by Akhuwat and associated
                entities for internal purposes. We don’t sell your data to any third parties. However, we
                share the data with third parties only when the visitors themselves authorize us or we are
                compelled by law to share the data. Like other websites, Akhuwat also recognizes the domain
                name of visitors to our website and sometimes, the email address too. However, we use this
                information to improve our web content and identify issues in our server. Again, we don’t
                share the information with third parties for commercial purposes. <br></br> <br></br>

                When you subscribe to our email updates, you may receive periodic emails from us with information
                about donations or programs. You have the choice to unsubscribe at any point and not receive any further
                updates from us.

            </CustomTypography>



            <CustomTypography className="pt-10"
                as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-left'
            >
                Credit/Debit Card Information

            </CustomTypography>


            <CustomTypography className="pt-6"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                We care about the safety and privacy of our donors. When you donate online through our website, we
                maintain industry-standard protocols to protect your credit/debit card information. However,
                you should be aware that no data transmission on the internet is 100% secure and that illegal
                third parties may try to intercept or steal our private data, although we try our best to protect
                it.

            </CustomTypography>



            <CustomTypography className="pt-10"
                as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-left'
            >
                Cookies

            </CustomTypography>


            <CustomTypography className="pt-6"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                Akhuwat website may collect non-personal information and data automatically and it is used
                to improve the online experience of the visitors. If a visitor doesn’t want their
                non-personal information to be collected, they can refuse the cookies.

            </CustomTypography>





            <CustomTypography className="pt-10"
                as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-left'
            >
                Donation Acknowledgment Policy (Delivery Policy)

            </CustomTypography>


            <CustomTypography className="pt-6"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                Donors will get a formal acknowledgment of the donations in terms of proper receipt via email,
                SMS, and WhatsApp Terms and Conditions If we ever make any changes to our donor’s privacy policy,
                we will straightforwardly post any changes on our website so that you are always up-to-date
                regarding the information that we collect and how do we utilize it.
            </CustomTypography>




            <CustomTypography className="pt-10"
                as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-left'
            >
                Refund & Category Change Policy

            </CustomTypography>


            <CustomTypography className="pt-6"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                After donating, donors cannot request refunds if they think that the donation amount was
                transferred by mistake. Moreover, donation amount once transferred in any category (Islamic
                Microfinance, Education Services, Clothes Bank, Khawajasira Support Program, and Health
                Services etc.) will not be transferable in any other category All donation categories are
                fully explained and donors are free to contact us to get more info about our programs.

            </CustomTypography>



            <CustomTypography className="pt-10"
                as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-left'
            >
                Policy Update

            </CustomTypography>


            <CustomTypography className="pt-6"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                Akhuwat may modify parts or whole of the Privacy Policy at any time. You can check the
                privacy policy from time to time to remain updated about any changes in the policy.

            </CustomTypography>






            <CustomTypography className="pt-10"
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
            >
                If you have any further questions about our Privacy Policy, you can contact us at:

            </CustomTypography>



            <Box className="pt-6 flex flex-row  gap-4">

                <img src={location} alt="location" className="object-cover" />

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
                >
                    19 Civic Center, Sector A2, Township,Lahore, Pakistan

                </CustomTypography>

            </Box>

            <Box className="pt-6 flex flex-row  gap-4">

                <img src={mobile} alt="location"className="object-cover"  />

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
                >
                    0308-144-8464

                </CustomTypography>

            </Box>



            <Box className="pt-6 flex flex-row  gap-4">

                <img src={phone} alt="location" />

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
                >
                    042-35841221-2 ext 305

                </CustomTypography>

            </Box>



            <Box className="pt-6 flex flex-row  gap-4">

                <img src={mail} alt="location" />

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-left'
                >
                    ksp@akhuwat.org.pk

                </CustomTypography>

            </Box>





        </Box>
    )
}

export default PrivacyPolicy