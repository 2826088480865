import { Box } from "@material-ui/core";
import React, { useState } from "react";
import FounderImage from "../pictures/leadership-pic.png";
import FounderPub from "../pictures/leadership-pub.png";
import LeaderCeo from "../pictures/leader-ceo.png";
import LeaderD1 from "../pictures/leader-Kausar.png";
import LeaderD2 from "../pictures/leader-saleem.png";
import LeaderD3 from "../pictures/leader-ihsan.png";
import LeaderD4 from "../pictures/leader-khawar.png";
import LeaderD5 from "../pictures/leader-rauf.png";
import LeaderD6 from "../pictures/leader-nazir.png";
import LeaderD7 from "../pictures/leader-izhar.png";
import CustomTypography from "../CustomTypography";
import FounderMobile from "../pictures/leader-mobile_img.png";
// Leadership page...
const Leadership = () => {
    // State to handle the active tab
    const [activeTab, setActiveTab] = useState("Board of Directors");

    return (
        <Box >
            <Box className="lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 md:px-10 px-4 bg-white
            max-w-screen-2xl mx-auto">
                <CustomTypography as="h2" variant="h21" color="text-customBlack" weightFont="font-bold"
                    alignment="text-center"
                >
                    Our Leadership
                </CustomTypography>

                {/* Tabs section  */}
                <Box className="lg:pt-90px pt-10">
                    <Box className="grid grid-cols-2  ">
                        {/* Tab 1: Board of Directors */}
                        <Box
                            className={`pb-[10px] border-b-[3px] cursor-pointer ${activeTab === "Board of Directors" ? "border-topGreen" : "border-MediumGray"}`}
                            onClick={() => setActiveTab("Board of Directors")}
                        >
                            <h6 className={`font-manrope lg:font-semibold lg:text-[23px] lg:leading-[34.5px] text-center text-[16px] leading-[20px] ${activeTab === "Board of Directors" ? "text-topGreen" : "text-MediumGray"}`}>
                                Board of Directors
                            </h6>
                        </Box>

                        {/* Tab 2: International Chapters */}
                        <Box
                            className={`pb-[10px] border-b-[3px] cursor-pointer ${activeTab === "International Chapters" ? "border-topGreen" : "border-MediumGray"}`}
                            onClick={() => setActiveTab("International Chapters")}
                        >
                            <h6 className={`font-manrope lg:font-semibold lg:text-[23px] lg:leading-[34.5px] text-center text-[16px] leading-[20px] ${activeTab === "International Chapters" ? "text-topGreen" : "text-MediumGray"}`}>
                                International Chapters
                            </h6>
                        </Box>
                    </Box>

                    {/* Conditional rendering based on active tab */}
                    <Box className="lg:pt-90px pt-10 lg:pb-12 pb-6 border-b-borderGray border-b-[1px]">
                        {activeTab === "Board of Directors" && (
                            <Box className="grid lg:grid-cols-[230px_auto] grid-cols-1 lg:gap-[18px] gap-10">

                                <Box className="flex flex-col lg:justify-start justify-center ">
                                    <Box>
                                        <img src={FounderImage} alt="founder" className="h-[229px] lg:h-[215px] lg:block md:hidden w-full object-contain w-full" />
                                        <img src={FounderMobile} alt="founder" className="h-[229px]  md:block lg:hidden hidden  w-full object-contain w-full" />
                                    </Box>
                                    <Box>

                                        <CustomTypography className="pt-4"
                                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                            alignment="lg:text-left  text-center"
                                        >
                                            Dr. Muhammad Amjad Saqib
                                        </CustomTypography>

                                        <CustomTypography className="pt-[2px]"
                                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                            alignment="lg:text-left  text-center"
                                        >
                                            Founder and Chairman
                                        </CustomTypography>

                                    </Box>

                                </Box>

                                <Box className="lg:py-8 lg:px-8 py-7 px-4 bg-topAlmond rounded-[8px] grid
                               lg:grid-cols-[418px_auto] grid-cols-1 lg:gap-8 gap-6">
                                    <Box>

                                        <CustomTypography className="pt-5 " as="p" variant="p2" color="text-customBlack"
                                            weightFont="font-semibold" alignment="lg:text-left md:text-left text-center">
                                            Meet our founder
                                        </CustomTypography>

                                        <CustomTypography className="pt-2 "
                                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal'
                                            alignment="lg:text-left md:text-left text-center"
                                        >
                                            Dr. Muhammad Amjad Saqib is a former public servant, philanthropist, and founder
                                            of Akhuwat. His expertise includes poverty alleviation initiatives, social
                                            development, and educational reform.
                                        </CustomTypography>

                                    </Box>

                                    <Box className="flex justify-center">
                                        <img src={FounderPub} alt="pub" className="object-cover lg:w-auto
                                        md:w-[400px] w-auto"/>
                                    </Box>

                                </Box>
                            </Box>
                        )}
                        {activeTab === "International Chapters" && (
                            <Box className="grid lg:grid-cols-[230px_auto] grid-cols-1 lg:gap-[18px] gap-10">

                                <Box className="flex flex-col lg:justify-start justify-center ">
                                    <Box>
                                        <img src={FounderImage} alt="founder" className="h-auto lg:h-[215px] w-full object-contain" />

                                    </Box>
                                    <Box>


                                        <h5 className="font-manrope text-customBlack font-bold text-[20px]
                                               text-[18px] leading-[27px] lg:text-left text-center pt-4">2.Dr. Muhammad Amjad Saqib</h5>

                                        <h5 className="font-manrope text-customBlack font-normal text-[20px]
                                               text-[18px] leading-[27px] lg:text-left text-center pt-[2px]">Founder and Chairman</h5>

                                    </Box>

                                </Box>

                                <Box className="lg:py-8 lg:px-8 py-7 px-4 bg-topAlmond rounded-[8px] grid
                                                     lg:grid-cols-[418px_auto] grid-cols-1 lg:gap-8 gap-6">
                                    <Box>
                                        <h5 className="font-manrope text-customBlack font-bold text-[20px]
                                               lg:font-semibold lg:text-[23px] lg:leading-[34.5px] leading-[27.32px]
                                         lg:text-left text-center">Meet our founder</h5>

                                        <p className="font-manrope text-customBlack font-normal lg:text-[18px] lg:leading-[27px]
                                        text-[16px] leading-[21.86px] lg:text-left text-center pt-2 ">
                                            Dr. Muhammad Amjad Saqib is a former public servant, philanthropist, and founder
                                            of Akhuwat. His expertise includes poverty alleviation initiatives, social
                                            development, and educational reform.</p>
                                    </Box>

                                    <Box className="flex justify-center">
                                        <img src={FounderPub} alt="pub" />
                                    </Box>

                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>


                {/* Leaders section */}

                <Box className="lg:pt-12 pt-6 grid lg:grid-cols-4 grid-cols-2 gap-[18px]">
                    <Box className="w-auto">
                        <img src={LeaderCeo} alt="CEO" className="w-[300px]" />


                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                            Dr. Kamran Shams
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                            CEO
                        </CustomTypography>
                    </Box>

                    <Box className="w-auto">
                        <img src={LeaderD1} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                           Prof. Tasneem Kausar
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                            Director
                        </CustomTypography>
                    </Box>



                    <Box>
                        <img src={LeaderD2} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                           M. Saleem Ahmad Ranjha
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                            Director
                        </CustomTypography>

                      
                    </Box>


                    <Box>
                        <img src={LeaderD3} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                           Prof. Humayun Ihsan
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                            Director
                        </CustomTypography>
                    </Box>

                </Box>

                {/* Leaders */}


                <Box className="lg:pt-12 pt-6 grid lg:grid-cols-4 grid-cols-2 gap-[18px] ">
                    <Box>
                        <img src={LeaderD4} alt="D6" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                         Khawar Rafiq Sheikh
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                            CEO
                        </CustomTypography>
                    </Box>

                    <Box>
                        <img src={LeaderD5} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                        Mr. Abdur Rauf Khan
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                           Director
                        </CustomTypography>

                    </Box>



                    <Box>
                        <img src={LeaderD6} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                        Nazir Ahmed Tunio
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                           Director
                        </CustomTypography>

                    </Box>


                    <Box>
                        <img src={LeaderD7} alt="CEO" className="w-[300px]" />

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'>
                       Dr. Izhar ul Haq Hashmi
                        </CustomTypography>

                        <CustomTypography className="pt-[2px]"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'>
                           Director
                        </CustomTypography>

                      
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};

export default Leadership;
