import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AimStories = ({ gridImage, vectorImage, bottomHead, para }) => {

    return (
        <Box className='flex lg:flex-row md:flex-row flex-col rounded-[28px] overflow-hidden'>
            <Box className='lg:w-[474px] md:w-[344px] w-auto flex-shrink-0 lg:h-auto md:h-auto h-[324px]'>
                <img src={gridImage} alt="stories" className='w-full h-full object-cover' />
            </Box>

            <Box className='flex-grow lg:py-16 py-5 lg:px-16 px-4 flex flex-col justify-between bg-customGray'>
                <Box className='w-6 h-[18px] flex justify-start mx-0'>
                    <img className='flex' src={vectorImage} alt="comaa" />
                </Box>
                <Box className='lg:pt-10 pt-5'>

                    <CustomTypography className='lg:pt-10 pt-5'
                        as='p' variant='p2' color='text-blackColor' weightFont='font-normal'
                        alignment='text-left' >
                        {para}

                    </CustomTypography>

                    <CustomTypography className="lg:pt-10 pt-5"
                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                        alignment='text-left'
                    >   {bottomHead}
                    </CustomTypography>

                </Box>

             {/**
              * 
              * <Box className='lg:flex justify-end hidden  '>

               < AddCircleIcon className='w-9 h-9 bg-customGreen rounded-full text-white' />

                </Box>
              * 
              */}   
            </Box>
        </Box>
    )
}

export default AimStories;
