import React from 'react';
import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AimBussinesCard = ({ iconPic, head, CardImg }) => {
    return (
        <Box className='lg:h-[680px] md:h-[512px] h-[446px] border rounded-[24px] lg:p-10 p-6 md:p-8'
            style={{
                backgroundImage: `
                linear-gradient(0deg, rgba(0, 0, 0, 0) 49.28%, rgba(0, 0, 0, 0.66) 104.09%),
linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                url(${CardImg})
            `,
                backgroundSize: 'cover', // Ensures the image covers the entire box
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents repetition of the image
            }}
        >

            <Box className='w-[72px] h-[72px] flex justify-start items-center'>
                <img className='max-w-full max-h-full' src={iconPic} alt="business" />
            </Box>

            <CustomTypography className="pt-4"
                as='p' variant='p1' color='text-white' weightFont='font-semibold'

            >
                {head}

            </CustomTypography>

        </Box>
    );
}

export default AimBussinesCard;
