import React from 'react';
import { Box} from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from './pictures/frame-logo.png'; // Adjust the path to your logo
import xIcon from './pictures/footer-x.svg';
import fbIcon from './pictures/footer-fb.svg';
import xInsta from './pictures/footer-insta.svg';
import xYoutube from './pictures/footer-youtube.svg';
import LinkdnIcon from './pictures/footer-link.svg';
import CustomTypography from './CustomTypography';





const Footer = () => {

  return (

    <Box className="bg-primaryLight lg:pt-120px lg:pb-10  lg:px-16 md:pt-10 md:pb-90px pt-10 pb-10 px-4">
      <Box className='max-w-screen-2xl mx-auto'>
        {/** top section*/}
        <Box className="grid lg:grid-cols-[422px_1fr] grid-cols-1 gap-5">

          <Box className=' flex lg:flex-col md:flex-row flex-col md:gap-5 lg:gap-9 gap-9 md:justify-between'>

            <Box className='flex lg:justify-start md:justify-start justify-center items-center'>
              <img src={logo} alt="footer logo" className=' object-cover' />
            </Box>

            <CustomTypography className='lg:block md:hidden block' as='p' variant='p2' color="text-SlateGray" weightFont='font-normal' >
              Receive Our Newsletter & Stay Updated
            </CustomTypography>

            <Box>
              <form className="flex lg:flex-col md:flex-row flex-col gap-5 md:pt-80px lg:pt-0 pt-0 ">
                {/* Label and Input */}
                <Box>

                  <CustomTypography className='lg:hidden md:block hidden' as='p' variant='p2' color="text-SlateGray" weightFont='font-normal' >
                    Receive Our Newsletter & Stay Updated
                  </CustomTypography>

                  <label className='pt-5' >
                    <CustomTypography as='p' variant='p2' color="text-blackColor" weightFont='font-normal' >
                      Email
                    </CustomTypography>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="w-full mt-5 border-b border-customBlack outline-none bg-transparent"
                    />
                  </label>
                </Box>

                {/* Subscribe Button */}
                <Box className='flex lg:justify-start md:justify-end justify-center items-center lg:pt-6 md:pt-60px pt-6'>
                  <button
                    type="submit"
                    className="bg-customBlack font-manrope  text-white py-3 px-6 rounded-full font-semibold text-[14px] leading-[20px] hover:bg-white transition-colors
                  hover:text-customBlack"
                  >
                    Subscribe
                  </button>
                </Box>
              </form>
            </Box>

          </Box>


          <Box className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-5'>
            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-4">
              <Link to='/about'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'>
                  About

                </CustomTypography>

              </Link>

              <Link to='/media'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                 alignment='lg:text-left md:text-left text-center' >
                  Media


                </CustomTypography>

              </Link>


              <Link to='/program'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Programs


                </CustomTypography>

              </Link>

              <Link to='/involved' onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Get Involved

                </CustomTypography>

              </Link>

            </Box>

            {/* 2nd column */}

            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-4">
              <Link to='/aim'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Akhuwat Islamic Microfinance
                </CustomTypography>

              </Link>

              <Link to='/aes'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                 alignment='lg:text-left md:text-left text-center' >
                  Akhuwat Education Services
                </CustomTypography>

              </Link>


              <Link to='/programs/aksp'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                 alignment='lg:text-left md:text-left text-center' >
                  Akhuwat Khwajasira Support Program
                </CustomTypography>

              </Link>

              <Link to='/programs/ahs'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Akhuwat Health Services
                </CustomTypography>

              </Link>

              <Link to='/programs/acb'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Akhuwat Clothes Bank
                </CustomTypography>

              </Link>


              <Link to='/programs/community'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Akhuwat Community Development Program
                </CustomTypography>

              </Link>


              <Link to='/programs/disaster'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                 alignment='lg:text-left md:text-left text-center' >
                  Akhuwat Relief & Disaster Management Program
                </CustomTypography>

              </Link>

            </Box>


            {/* column 3 join us */}


            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-5">
              <Link to='/join'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Join Us


                </CustomTypography>

              </Link>

              <Link to='/about'  onClick={() => window.scrollTo(0, 0)}>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                 alignment='lg:text-left md:text-left text-center'  >
                  Ways to Support
                </CustomTypography>

              </Link>


              <Link to='/about'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                 alignment='lg:text-left md:text-left text-center' >
                  Shop at Our Store
                </CustomTypography>

              </Link>


            </Box>


          </Box>

        </Box>


        {/* 2nd grid for center sectoin */}

        <Box className="grid lg:grid-cols-[422px_1fr] grid-cols-1 gap-5">

          <Box>

          </Box>


          <Box className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-5'>


            {/* column  address */}


            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-4">

              <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-semibold'
                alignment='lg:text-left md:text-left text-center' >
                Address
              </CustomTypography>


              <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
                19 Civic Center, Minhaj Ul Quran University Rd، Sector A-II Township Commercial Area
                Lahore, Punjab
              </CustomTypography>
            </Box>


            {/* column  Emails */}


            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-3">

              <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-semibold'
                alignment='lg:text-left md:text-left text-center' >
                Email
              </CustomTypography>


              <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
               For queries:
              </CustomTypography>
               
               <a href="mailto:info@akhuwat.org.pk"  className='mt-[-8px]'>
               <CustomTypography as='p' variant='p3' color="text-customGreen" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
              info@akhuwat.org.pk 
              </CustomTypography>
               </a>


               <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
               For complaints:
              </CustomTypography>
               
               <a href="mailto:complaints@akhuwat.org.pk" className='mt-[-8px]' >
               <CustomTypography as='p' variant='p3' color="text-customGreen" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
              complaints@akhuwat.org.pk
              </CustomTypography>
               </a>

            </Box>


            {/* column  Contact Number */}


            <Box className="pt-4 border-t-2 border-t-topGreen flex flex-col gap-4">

              <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-semibold'
                alignment='lg:text-left md:text-left text-center' >
                Contact Number
              </CustomTypography>

               
               <a href="callto:042-111-448-464" >
               <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                alignment='lg:text-left md:text-left text-center' >
            042-111-448-464
              </CustomTypography>
               </a>
            </Box>

          </Box>

        </Box>







        <div className="lg:h-90px h-10 border-b border-topGreen"></div>

           <Box className='flex lg:flex-row flex-col grid-cols-1 gap-5 justify-between lg:pt-10 pt-5'>
               {/** social icons */}
               <Box className='flex flex-row gap-3 lg:justify-start justify-center'>
                <a href='#fb'>
                 <img src={fbIcon}  alt='fb icon' className='object-cover'/>
                </a>

                <a href='#fb'>
                 <img src={xInsta}  alt='fb icon' className='object-cover'/>
                </a>

                <a href='#fb'>
                 <img src={xIcon}  alt='x icon' className='object-cover'/>
                </a>

                <a href='#fb'>
                 <img src={LinkdnIcon}  alt='linkd icon' className='object-cover'/>
                </a>

                <a href='#fb'>
                 <img src={xYoutube}  alt='linkd icon' className='object-cover'/>
                </a>

               </Box>

               {/* privacy policy */}

               <Box className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-3 lg:justify-start justify-center'>
               <Link to='/about'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal'
                alignment='text-center' >
                Privacy Policy
                </CustomTypography>

              </Link>


              <Link to='/about'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                alignment='text-center'>
                Terms of Service
                </CustomTypography>

              </Link>



              <Link to='/about'>
                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                alignment='text-center'>
                Cookies Settings
                </CustomTypography>

              </Link>

                </Box>

                <Box>

                <CustomTypography as='p' variant='p3' color="text-customBlack" weightFont='font-normal' 
                alignment='text-center'>
               © 2024 Akhuwat Foundation. All rights reserved.
                </CustomTypography>

                </Box>

           </Box>

      </Box>
    </Box>
  );
};

export default Footer;
