import React from 'react';
import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AkspBusinessCard = ({ iconPic, head }) => {
    return (
        <Box className='lg:min-h-auto  min-h-[270px] rounded-[20px] lg:p-8 p-5 
        bg-PowderColor' >

            <Box className='w-[72px] h-[72px] flex lg:justify-start justify-center items-center'>
                <img className='h-[56px] w-[56px]' src={iconPic} alt="business" />
            </Box>

            <CustomTypography className="pt-8"
                as='p' variant='p2' color='text-customBlack' weightFont='font-normal'

            >
                {head}

            </CustomTypography>

        </Box>
    );
}

export default AkspBusinessCard;
