import { Box } from "@material-ui/core";
import React, { useState } from "react";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import VideoPlay from "../VideoPlay";
import Banner from "../pictures/Active_banner.png";
import Story1 from "../pictures/kasur_story1.png";
import heroVideo from '../pictures/hero_video.mp4';
import AcbFaq from "../ACB_AHS/AcbFaq";
import Program1 from "../pictures/kasur_program1.png";
import AhsStories from "../ACB_AHS/AhsStories";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KasurTestimonial from "./KasurTestimonial";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

//Akhuwat college kasur page....

const AkuwatKasur = () => {
    const totalSlides = 4, totalSlidesStories = 4;

    const [currentSlideStories, setCurrentSlidestories] = useState(0);//stories

    const handleNextStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrevStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };

    const handleChangeSlideStories = (index) => {
        setCurrentSlidestories(index); // Update current slide index when a button is clicked
    };


    return (

        <Box>

            {/* banner design */}


            <Box>

                <VideoPlay thumbnailPicture={Banner} videoSrc={heroVideo}
                    roundedGrid="no" />


                {/* below banner section */}

                <Box className={` h-auto  bg-white lg:py-90px py-52px lg:px-120px px-4 ]`}>
                    <Box className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[0px] gap-5'>
                        <Box className='lg:w-[400px]'>

                            <CustomTypography
                                as='h2' variant='h21' color='text-MidnightTest' weightFont='font-bold' alignment='lg:text-left md:text-left text-center'
                            >
                                Akhuwat College, Kasur
                            </CustomTypography>
                        </Box>
                        <Box >


                            <CustomTypography
                                as='p' variant='p2' color='text-topGray' weightFont='font-normal' alignment='lg:text-left md:text-left text-center'
                            >
                                Akhuwat College, Kasur (ACK) is a fee-free residential college for the high
                                performing students and guarantees them various facilities, including accommodation,
                                meals, and a dedicated medical facility
                            </CustomTypography>
                            {/* showing button if required */}


                            <Box className='flex lg:justify-start md:justify-start justify-center lg:pt-60px pt-7'>
                                <CustomButton btnText='View open admissions' btnURL='/join' btnColor='white' btnBackground='customBlack' />
                            </Box>


                        </Box>
                    </Box>
                </Box>
            </Box>


            {/* Programs offered */}

            <Box className="bg-MintCream lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-[18px]">
                <CustomTypography as="h2" variant="h21" color="text-customGreen " weightFont="font-bold"
                    alignment="lg:text-left md:text-left text-center"
                >
                    Programs offered
                </CustomTypography>


                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-10 gap-6
                lg:pt-80px md:pt-80px pt-6">

                    <Box className="flex justify-center">
                        <img src={Program1} alt="program1" className="lg:w-auto md:w-[680px] w-auto" />

                    </Box>

                    <Box>

                        <Box className="flex gap-[10px] py-4 border-b border-b-SlateGray">

                            <CustomTypography as="p" variant="p2" color="text-SlateGray"
                                weightFont="font-semibold" alignment="text-center">
                                Pre-Medical (2 Years) →
                            </CustomTypography>

                        </Box>

                        <Box className="flex gap-[10px] py-4 border-b border-b-SlateGray">

                            <CustomTypography as="p" variant="p2" color="text-SlateGray"
                                weightFont="font-semibold" alignment="text-center">
                                Pre-Engineering (2 Years) →
                            </CustomTypography>

                        </Box>

                        <Box className="flex gap-[10px] py-4 border-b border-b-SlateGray">

                            <CustomTypography as="p" variant="p2" color="text-SlateGray"
                                weightFont="font-semibold" alignment="text-center">
                                ICS (2 Years)  →
                            </CustomTypography>

                        </Box>

                        <Box className="flex gap-[10px] py-4 border-b border-b-SlateGray">

                            <CustomTypography as="p" variant="p2" color="text-SlateGray"
                                weightFont="font-semibold" alignment="text-center">
                                BS Economics (4 Years) →
                            </CustomTypography>

                        </Box>


                        <Box className="flex gap-[10px] py-4 border-b border-b-SlateGray">

                            <CustomTypography as="p" variant="p2" color="text-SlateGray"
                                weightFont="font-semibold" alignment="text-center">
                                BS English (4 Years) →
                            </CustomTypography>

                        </Box>

                    </Box>



                </Box>

            </Box>


            {/** admission section */}

            <Box className="bg-white lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-[18px] ">

                <CustomTypography
                    as='h3' variant='h3' color='text-customGreen' weightFont='font-semibold'>
                    Akhuwat College Kasur provides quality education to deserving students for two
                    main reasons: first, it ensures that students from financially challenged
                    backgrounds can access higher education without the burden of fees. Second, it
                    aims to empower these students to become future leaders who contribute
                    positively to their communities and society at large.
                </CustomTypography>

                <Box className="grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 lg:gap-10
                        md:gap-10 gap-5 lg:pt-80px md:pt-52px pt-7 max-w-[900px]">

                    <Box className="flex justify-start items-center">

                        <CustomButton btnText="Online admissions" btnColor="white"
                            btnBackground="customBlack" btnURL="/aes" />

                    </Box>

                    <Box className="flex justify-start items-center">

                        <CustomButton btnText="Online admissions" btnColor="white"
                            btnBackground="customBlack" btnURL="/aes" />

                    </Box>


                    <Box className="flex justify-start items-center">

                        <CustomButton btnText="Online admissions" btnColor="white"
                            btnBackground="customBlack" btnURL="/aes" />

                    </Box>

                </Box>
            </Box>


{/* Campus Section */}
<Box>
    <Carousel
       
        selectedItem={currentSlideStories}
        onChange={(index) => setCurrentSlidestories(index)}
        showThumbs={false}
        showStatus={false}
        interval={5000}
        showIndicators={true}
        autoPlay
        infiniteLoop
        emulateTouch
        swipeable
        width="100%"
        renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
                <button
                    type="button"
                    onClick={onClickHandler}
                    className="absolute top-1/2 left-6 transform -translate-y-1/2 text-white bg-black bg-opacity-70 rounded-full p-3 shadow-lg"
                    style={{ zIndex: 10 }} // Ensures the arrow button is on top of other elements
                >
                    <ArrowCircleLeftIcon fontSize="large" />
                </button>
            )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
                <button
                    type="button"
                    onClick={onClickHandler}
                    className="absolute top-1/2 right-6 transform -translate-y-1/2 text-white bg-black bg-opacity-70 rounded-full p-3 shadow-lg"
                    style={{ zIndex: 10 }}
                >
                    <ArrowCircleRightIcon fontSize="large" />
                </button>
            )
        }
    >
        <KasurTestimonial />
        <KasurTestimonial />
    </Carousel>
</Box>





            {/* Stories section */}

            <Box className="bg-white lg:p-[128px] md:p-10  p-4 ">

                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1'>
                    <Box className='flex lg:justify-start md:justify-start justify-center items-center'>

                        <CustomTypography as="h2" variant="h2" color="text-AesHead" weightFont="font-bold"
                            alignment="lg:text-left md:text-left text-center">
                            Our Stories
                        </CustomTypography>
                    </Box>


                    <Box className='col-span-1 lg:flex md:flex hidden items-center justify-end '>
                        <Box
                            onClick={handlePrevStories}
                            className={`cursor-pointer ${currentSlideStories === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentSlideStories === 0 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowLeftIcon className={` ${currentSlideStories === 0 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                        <Box
                            onClick={handleNextStories}
                            className={`ml-4 cursor-pointer ${currentSlideStories === totalSlidesStories - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                            style={{ borderColor: totalSlidesStories === totalSlidesStories - 1 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowRightIcon className={`${currentSlideStories === totalSlidesStories - 1 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className='lg:pt-12 pt-8  '>

                    <Carousel className='md:hidden hidden lg:block'
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >

                        {/* Different view for mobile and desktop */}

                        <AhsStories topHead="“This institute has made me believe that education is for everyone.”" belowHead="Sanam"
                            gridImage={Story1}
                            btnCondt="yes"
                            btnText='Akhuwat College Kasur'
                            textColor='text-white'
                            gridColor='AesHead'
                            btnColor='white' />



                    </Carousel>


                    {/* For mobile */}

                    <Carousel className='lg:hidden  '
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >



                        <Box className='rounded-[30px] overflow-hidden'>
                            <AhsStories gridImage={Story1} />
                            <AhsStories topHead="I am very grateful to the brotherhood who held our hands 
        when there was no other support." belowHead="Sanam"
                                btnCondt="yes"
                                btnText='Disaster Response'
                                textColor='text-customBlack'
                                gridColor='SoftVanila'
                                btnColor='customBlack'

                            />

                        </Box>

                    </Carousel>

                    {/* Navigation Buttons */}
                    <Box className="lg:hidden md:hidden flex justify-center pt-8">
                        <button
                            onClick={() => handleChangeSlideStories(0)}
                            className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideStories === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(1)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full focus:outline-none  ${currentSlideStories === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(2)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full   ${setCurrentSlidestories === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>


                    </Box>

                </Box>





            </Box>




            {/* Why to support ?? */}

            <Box className="bg-white lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-[18px] ">

                <CustomTypography
                    as="h2"
                    variant="h2"
                    color="text-customBlack"
                >
                    Why Support  Akhuwat College, Kasur?
                </CustomTypography>

                <Box className="lg:pt-[96px] pt-10">
                    <AcbFaq FaqDisaster="college" />
                </Box>

            </Box>



            {/* Donate section */}

            <Box className="lg:mt-10 md:mt-10 mt-6 bg-customGreen lg:py-80px lg:px-80px  md:py-60px md:px-10 py-6 px-4">


                <CustomTypography
                    as='p' variant='p3' color='text-white' weightFont='font-semibold'
                    alignment="text-center"
                >
                    Akhuwat Education

                </CustomTypography>

                <CustomTypography className="pt-3" as="h2" variant="h2" color="text-MintGreen " weightFont="font-bold"
                    alignment="text-center"
                >
                    Support Akhuwat College Kasur
                </CustomTypography>


                <CustomTypography className="pt-5 " as="p" variant="p2" color="text-white"
                    weightFont="font-semibold" alignment="text-center">
                    Every one of our students has their own story of overcoming hardship to attain a basic
                    education. Can you help a worthy student from a family who cannot afford to educate
                    him/her? By donating to Akhuwat College, you’ll be giving a young person the
                    opportunity to pursue their studies.

                </CustomTypography>


                <Box className="pt-7 flex justify-center">
                    <CustomButton btnText="Donate" btnColor="customBlack" btnBackground="white" />
                </Box>

            </Box>

        </Box>
    )

}

export default AkuwatKasur