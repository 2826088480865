import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomSlider = () => {
  const sliderSettings = {
    infinite: false, // No infinite loop
    slidesToShow: Math.floor(window.innerWidth / 500), // Dynamically calculate visible slides
    slidesToScroll: 1, // Scroll one slide at a time
    speed: 500,
    arrows: true,
    prevArrow: <CustomArrow direction="left" />,
    nextArrow: <CustomArrow direction="right" />,
    responsive: [
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: Math.floor(window.innerWidth / 500),
        },
      },
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slides = [
    { id: 1, title: "Akhuwat Education Services (AES)", content: "Provides fee-free, quality education to deserving students across Pakistan." },
    { id: 2, title: "Akhuwat Cloth Bank (ACB)", content: "Collects donated clothing and household items and distributes them with dignity." },
    { id: 3, title: "Akhuwat Khwajasira Support Program", content: "Facilitates transformation opportunities for marginalized communities." },
    { id: 4, title: "Akhuwat Microfinance", content: "Interest-free microcredit loans to deserving individuals to expand their businesses." },
  ];

  return (
    <div className="relative w-full bg-LightBrown">
      <Slider {...sliderSettings}>
        {slides.map((slide) => (
          <div key={slide.id} className="px-4">
            <div className="w-[500px] bg-white rounded-lg shadow-md p-6 h-auto">
              <h3 className="text-lg font-bold">{slide.title}</h3>
              <p className="text-sm text-gray-700">{slide.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Custom Arrow Component
const CustomArrow = ({ direction, onClick }) => {
  return (
    <button
      className={`absolute ${direction === "left" ? "left-4" : "right-4"} bottom-4 z-10 bg-green-500 text-white p-2 rounded-full shadow-md`}
      onClick={onClick}
    >
      {direction === "left" ? "←" : "→"}
    </button>
  );
};

export default CustomSlider;
