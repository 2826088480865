import React from 'react';
import { Box } from "@material-ui/core";
import CustomTypography from '../CustomTypography';


const AcbDonate = ({ cardNo, cardTitle, cardDesc }) => {

    //donate section carasouel elements....

    return (
        <Box className='py-6  w-auto lg:px-6 md:px-6 md:border-r lg:border-r border-r-[#CCCCCC] '>

            <Box className='flex items-center lg:justify-start justify-center'>

                <CustomTypography as="p" variant="p2" color="text-topGreen"
                    weightFont="font-semibold" alignment="text-center">
                    {cardNo}
                </CustomTypography>

                <CustomTypography className='pl-2' as="p" variant="p2" color="text-customBlack"
                    weightFont="font-semibold" alignment="lg:text-left md:text-left text-center">
                    {cardTitle}
                </CustomTypography>

            </Box>


            <CustomTypography className='pl-2 pt-6' as="p" variant="p3" color="text-SlateGray"
                weightFont="font-normal" alignment="lg:text-left md:text-left text-center">
                {cardDesc}
            </CustomTypography>



        </Box>
    )
}

export default AcbDonate