import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomTypography from "../CustomTypography";
import { deleteRole, getAllRoles } from "../Actions/userAction";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Sidebar } from "./Sidebar"; // Assume Sidebar component exists

const AllRoles = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const config = {
        headers: { "Content-Type": "application/json" },
        withCredentials: true, // Include cookies
    };

    const dispatch = useDispatch();
    const { roles } = useSelector((state) => state.adminRoles) || {};

    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    const handleDeleteRole = async (id) => {
        try {
            await axios.delete(`${API_BASE_URL}/api/v1/admin/roles/${id}`,config);
            Swal.fire({
                icon: "success",
                title: "Deleted",
                text: "Role deleted successfully!",
            });
            dispatch(getAllRoles());
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || 
                "Failed to delete role.Please ensure no permissions exist and role isn't assigned to User",
            });
        }
    };

    return (
        <Box sx={{ display: "flex", backgroundColor: "white", minHeight: "100vh" }}>
            {/* Sidebar */}
            <Sidebar />

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, padding: "24px" }}>
                <CustomTypography as="h2" variant="h2" alignment="text-center" color="text-customBlack">
                    All Roles
                </CustomTypography>

                <TableContainer component={Paper} className="p-4 mt-10p" sx={{ boxShadow: 3, marginTop: "32px" }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "red" }}>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Role</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Description</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Status</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Update</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles && roles.length > 0 ? (
                                roles.map((role) => (
                                    <TableRow key={role.roleid} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{role.role_name}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{role.role_description}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{role.status}</TableCell>
                                        <TableCell style={{ padding: "16px", textAlign: "center" }}>
                                            {/* Update action column */}
                                            <Link color="primary" to={`/admin/roles/${role.roleid}`} style={{ marginRight: "16px" }}>
                                                <EditIcon />
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{ padding: "16px", textAlign: "center" }}>
                                            {/* Delete action column */}
                                            <IconButton color="secondary" onClick={() => handleDeleteRole(role.roleid)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No roles available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default AllRoles;
