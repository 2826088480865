import React from 'react';
import { Box } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import CustomTypography from '../CustomTypography';

const AkSchool = ({ collegeHead, collegePara, backColor, isMobile, cardRadius, cardHeight
    , headColor
}) => {
    return (

        <Box className={`lg:p-10 p-5 flex flex-col justify-between
                     bg-${backColor}`}
            style={{

                height: isMobile ? 'auto' : cardHeight,
                borderBottomRightRadius: '40px',
                borderBottomLeftRadius: isMobile || cardRadius ? '40px' : '0px',
                borderTopRightRadius: !isMobile || cardRadius ? '40px' : '0px',
                borderTopLeftRadius: cardRadius ? '40px' : '0px',

            }}>

            <Box>


                <CustomTypography
                    as='h2' variant='h21' color={headColor} weightFont='font-bold' alignment="lg:text-left md:text-left text-center"
                >
                    {collegeHead}
                </CustomTypography>

                <CustomTypography className='lg:pt-6 pt-5'
                    as='p' variant='p3' color='text-white' weightFont='font-normal'
                    alignment='lg:text-left md:text-left text-center'

                >
                    {collegePara}
                </CustomTypography>
            </Box>

            <Box className='lg:pt-6 pt-5 flex justify-center lg:justify-start md:justify-start'>
                <CustomButton btnText='Learn more' btnURL='/lear' btnColor='customBlack' btnBackground='white' />
            </Box>
        </Box>


    )
}

export default AkSchool;
