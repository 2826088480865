import React, { useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import BannerDesign from '../AES_AIM/BannerDesign';
import AcbBanner from '../pictures/acb-banner.png';
import AcbUnion from '../pictures/acb-union-pattern.png';
import AcbImpact from '../pictures/acb-impact-pattern.png';
import AcCompaign from '../pictures/ahs-compaign-pic.png';
import AcbContribute from '../pictures/acb-contribute.png';
import AcbClothes from '../pictures/acb-clothes-icon.png';
import AcbShoes from '../pictures/acb-shoes-icon.png';
import AcbWed from '../pictures/acb-wed-icon.png';
import AcbToys from '../pictures/acb-toys-icon.png';
import CountUp from 'react-countup';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import DonateNowSetion from '../ReusableComponents/DonateNowSection';
import AcbDonate from './AcbDonate';
import AcbClothess from './AcbClothese';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AcbProcess from '../pictures/acb-process-pic.png';
import AcbFaq from './AcbFaq';
import CustomTypography from '../CustomTypography';
import CustomCarousel from '../CustomCarasouel';

const Acb = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentSlide1, setCurrentSlide1] = useState(0); //donate cards
    const [currentSlideProcess, setCurrentSlideProcess] = useState(0); //process cards
    let totalSlides = 3;
    if (isMobile) {
        totalSlides = 4;
    }
    const autoPlayInterval = 5000;


    const handleNext = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };


    //handle change for process slider..
    const handleChangeSlideProcess = (index) => {
        setCurrentSlideProcess(index); // Update current slide index when a button is clicked
    };

    const slides = [
        <AcbDonate
            cardNo="01."
            cardTitle="Generosity Brings Joy "
            cardDesc="When you give your old and spare clothing, shoes, blankets, and other household accessories, you experience joy and feel optimistic."
        />,
        <AcbDonate
            cardNo="02." cardTitle="We Make it Convenient "
            cardDesc="Though you can deliver reusable items to us, you can also utilize our doorstep pickup and get your in-kind donations reach the right people."

        />,
        <AcbDonate
            cardNo="03." cardTitle="We Donate with Dignity "
            cardDesc="At Akhuwat, we don’t consider our donations as charity. We want to share these gifts with our brothers and sisters because we want to clothe them with dignity. "
        />,
        <AcbDonate
            cardNo="04." cardTitle="Cornea Transplant Project"
            cardDesc="At Akhuwat, we don’t consider our donations as charity. We want to share these gifts with our brothers and sisters because we want to clothe them with dignity. "
        />
    ];


    // slides for process section ...

    const processSlides = [
        {
            processImg: AcbProcess, processText: 'Collect clothes, shoes, & other accessories from donors.'
        },
        {
            processImg: AcbProcess, processText: '2.Collect clothes, shoes, & other accessories from donors.'
        },
        {
            processImg: AcbProcess, processText: 'Collect clothes, shoes, & other accessories from donors.'
        },
        {
            processImg: AcbProcess, processText: '2.Collect clothes, shoes, & other accessories from donors.'
        },
    ]

    // Auto play functionality
    useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, autoPlayInterval);

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, [currentSlide1]);
    // Akhuwat clothers bank page ....

    return (
        <Box >
            {/* Banner section... */}

            <Box>

                <BannerDesign imageURL={AcbBanner} headPart1={'Akhuwat Clothes'} headPart2={' Bank (ACB)'}
                    belowHead={'Akhuwat Clothes Bank (ACB)'}
                    belowPara1="Akhuwat Clothes Bank (ACB)  caters to the underprivileged families across 
                    Pakistan through the dedicated collection, refurbishment, and distribution of clothes and
                     household accessories as a gift. It operates consistently throughout the year, providing
                      essential clothes and household accessories, while amplifying its endeavors during 
                      crucial times like natural calamities.  "

                    belowheadColor="text-BrightOrange"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={AcbUnion}
                    donateBtn="yes"

                />

            </Box>


            {/* Impact numbers */}


            <Box className="lg:pt-90px md:pt-11 md:pb-16 lg:pb-140px pt-60px pb-60px lg:px-120px px-6  bg-customGreen"
                style={{
                    backgroundImage: !isMobile ? `url(${AcbImpact})` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right',

                }}>
                <Box className='max-w-screen-2xl mx-auto'>
                    <Box>
                        <CustomTypography as="h2" variant="h2" color="text-white " weightFont="font-bold">
                            Our Impact in Numbers
                        </CustomTypography>
                    </Box>

                    <Box className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-80px gap-8 
                lg:pt-[130px] pt-12">

                        {/* 1st Column: Left Aligned */}
                        <Box >
                            <Box >
                                <h1>
                                    <CountUp className="font-bebs text-BrightOrange lg:text-[120px] text-[80px] lg:leading-[120px] 
                                leading-[80px] font-normal mb-[15px]" end={5.5} decimals={1} suffix="M" />

                                </h1>
                            </Box>
                            <CustomTypography as="p" variant="p3" color="text-BrightOrange" weightFont="font-semibold">
                                Total Collection of Items
                            </CustomTypography>
                        </Box>

                        {/* 2nd Column: Center Aligned */}
                        <Box>
                            <Box >
                                <h1>
                                    <CountUp className="font-bebs text-BrightOrange lg:text-[120px] text-[80px]
                                 lg:leading-[120px] leading-[80px] font-normal mb-[12px] " end={833}
                                        decimals={0} suffix="K" />
                                </h1>
                            </Box>
                            <Box className="interest-text2">


                                <CustomTypography as="p" variant="p3" color="text-BrightOrange" weightFont="font-semibold">
                                    Benefited Families
                                </CustomTypography>
                            </Box>
                        </Box>

                        {/* 3rd Column: Right Aligned */}
                        <Box >
                            <Box>
                                <h1>
                                    <CountUp className="font-bebs text-BrightOrange lg:text-[120px] text-[80px] 
                                lg:leading-[120px] leading-[80px] font-normal mb-[12px] 
                               " end={10} decimals={0} suffix="K" />
                                </h1>
                            </Box>
                            <Box>

                                <CustomTypography as="p" variant="p3" color="text-BrightOrange" weightFont="font-semibold">
                                    Wedding Gifts
                                </CustomTypography>
                            </Box>
                        </Box>

                    </Box>
                </Box>

            </Box>

            {/* Clothes distribution .... */}

            <Box className='bg-white lg:py-140px lg:px-120px py-52px px-4 md:py-[72px] md:px-16'>
                <Box className='max-w-screen-2xl mx-auto'>
                    <CustomTypography
                        as='h2' variant='h2' color='text-topGreen' weightFont='font-bold'
                        alignment='text-center'
                    >
                        Process of Clothes Distribution
                    </CustomTypography>


                    <Box className='grid grid-cols-4 gap-2 lg:gap-5 lg:pt-80px md:pt-80px pt-10 w-full'>
                        {/* First Slide */}
                        <Box className={`flex lg:flex-row lg:gap-2 flex-col lg:items-start items-center relative`}>
                            {/* Circle Number */}
                            <Box className={`w-[30px] h-[30px] rounded-full flex items-center justify-center ${currentSlideProcess === 0 ? 'bg-topGreen' : 'bg-SilverGray'}`}>
                                <p className='text-white font-manrope font-semibold text-[18px] leading-[24.59px]'>1</p>
                            </Box>

                            {/* Desktop border (on top) */}
                            <Box className={`absolute top-[-20px] left-0 h-4 w-full ${currentSlideProcess === 0 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} hidden lg:block`} />

                            {/* Mobile/Tablet border (between 1 and 2) */}
                            <Box className={`absolute left-[50%] top-[15px]   h-4 w-[calc(100%+15px)] ${currentSlideProcess === 0 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} lg:hidden`} />

                            {/* Heading */}
                            <h6 className={`font-manrope font-bold text-center ${currentSlideProcess === 0 ? 'text-topGreen' : 'text-SilverGray'} text-[14px] lg:text-[23px] leading-[27px] lg:leading-[34.5px] lg:mt-0 mt-2`}>
                                COLLECTION
                            </h6>
                        </Box>

                        {/* Second Slide */}
                        <Box className={`flex lg:flex-row lg:gap-2 flex-col lg:items-start items-center relative`}>
                            {/* Circle Number */}
                            <Box className={`w-[30px] h-[30px] rounded-full flex items-center justify-center ${currentSlideProcess === 1 ? 'bg-topGreen' : 'bg-SilverGray'}`}>
                                <p className='text-white font-manrope font-semibold text-[18px] leading-[24.59px]'>2</p>
                            </Box>

                            {/* Desktop border (on top) */}
                            <Box className={`absolute top-[-20px] left-0 h-4 w-full ${currentSlideProcess === 1 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} hidden lg:block`} />

                            {/* Mobile/Tablet border (between 2 and 3) */}
                            <Box className={`absolute left-[50%] top-[15px] h-4 w-[calc(100%+15px)] ${currentSlideProcess === 1 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} lg:hidden`} />

                            {/* Heading */}
                            <h6 className={`font-manrope font-bold text-center ${currentSlideProcess === 1 ? 'text-topGreen' : 'text-SilverGray'} text-[14px] lg:text-[23px] leading-[27px] lg:leading-[34.5px] lg:mt-0 mt-2`}>
                                SORTING
                            </h6>
                        </Box>

                        {/* Third Slide */}
                        <Box className={`flex lg:flex-row lg:gap-2 flex-col lg:items-start items-center relative`}>
                            {/* Circle Number */}
                            <Box className={`w-[30px] h-[30px] rounded-full flex items-center justify-center ${currentSlideProcess === 2 ? 'bg-topGreen' : 'bg-SilverGray'}`}>
                                <p className='text-white font-manrope font-semibold text-[18px] leading-[24.59px]'>3</p>
                            </Box>

                            {/* Desktop border (on top) */}
                            <Box className={`absolute top-[-20px] left-0 h-4 w-full ${currentSlideProcess === 2 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} hidden lg:block`} />

                            {/* Mobile/Tablet border (between 3 and 4) */}
                            <Box className={`absolute left-[50%] top-[15px] h-4 w-[calc(100%+15px)] ${currentSlideProcess === 2 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} lg:hidden`} />

                            {/* Heading */}
                            <h6 className={`font-manrope font-bold text-center ${currentSlideProcess === 2 ? 'text-topGreen' : 'text-SilverGray'} text-[14px] lg:text-[23px] leading-[27px] lg:leading-[34.5px] lg:mt-0 mt-2`}>
                                PREPARING
                            </h6>
                        </Box>

                        {/* Fourth Slide */}
                        <Box className={`flex lg:flex-row lg:gap-2 flex-col lg:items-start items-center relative`}>
                            {/* Circle Number */}
                            <Box className={`w-[30px] h-[30px] rounded-full flex items-center justify-center ${currentSlideProcess === 3 ? 'bg-topGreen' : 'bg-SilverGray'}`}>
                                <p className='text-white font-manrope font-semibold text-[18px] leading-[24.59px]'>4</p>
                            </Box>

                            {/* Desktop border (on top) */}
                            <Box className={`absolute top-[-20px] left-0 h-4 w-full ${currentSlideProcess === 3 ? 'border-t border-topGreen' : 'border-t border-SilverGray'} hidden lg:block`} />

                            {/* No border for mobile/tablet after 4 */}
                            <Box className={`absolute left-[50%] top-[15px] h-4 w-[calc(100%+15px)] hidden lg:hidden`} />

                            {/* Heading */}
                            <h6 className={`font-manrope font-bold text-center ${currentSlideProcess === 3 ? 'text-topGreen' : 'text-SilverGray'} text-[14px] lg:text-[23px] leading-[27px] lg:leading-[34.5px] lg:mt-0 mt-2`}>
                                DISTRIBUTION
                            </h6>
                        </Box>
                    </Box>



                    <Box className='lg:pt-80px pt-6'>
                        <Carousel
                            selectedItem={currentSlideProcess}
                            onChange={(index) => setCurrentSlideProcess(index)}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            interval={5000}
                            showIndicators={false}
                            autoPlay
                            infiniteLoop
                            emulateTouch // Ensures smooth touch behavior
                            swipeable // Allows swipe gestures on mobile devices
                            width="100%"
                        >


                            {processSlides.map((slide, index) => (
                                <Box className='mr-2 ml-2'
                                    key={index}
                                >
                                    <AcbClothess
                                        processImg={slide.processImg}
                                        ProcessText={slide.processText}

                                    />
                                </Box>
                            ))}

                        </Carousel>


                        {/* Navigation Buttons */}
                        <Box className="lg:hidden md:hidden flex justify-center pt-10">
                            <button
                                onClick={() => handleChangeSlideProcess(0)}
                                className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideProcess === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>
                            <button
                                onClick={() => handleChangeSlideProcess(1)}
                                className={`ml-[6px] w-[48px] h-[4px]  rounded-full focus:outline-none  ${currentSlideProcess === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>
                            <button
                                onClick={() => handleChangeSlideProcess(2)}
                                className={`ml-[6px] w-[48px] h-[4px] rounded-full   ${currentSlideProcess === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>
                            <button
                                onClick={() => handleChangeSlideProcess(3)}
                                className={`ml-[6px] w-[48px] h-[4px] rounded-full   ${currentSlideProcess === 3 ? 'bg-topGreen' : 'bg-topAlmond'
                                    }`}
                            ></button>

                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Spare and share section  */}

            <Box className='bg-customGray lg:py-120px lg:px-120px md:py-18 md:px-16 py-60px px-4'>
                <Box className='grid lg:grid-cols-2 grid-cols-1 lg:gap-60px md:gap-60px gap-6
                max-w-screen-2xl mx-auto'>
                    <Box className='lg:h-[515px] flex flex-col justify-between'>



                        <CustomTypography as="h2" variant="h2" color="text-topGreen" weightFont="font-semibold"
                            alignment='lg:text-left text-center'>
                            Spare & Share
                        </CustomTypography>


                        <CustomTypography className='lg:pt-0 pt-6 ' as="p" variant="p2" color="text-customBlack" weightFont="font-normal"
                            alignment='lg:text-left text-center'>
                            Your spare clothes, shoes, and other items can make a big difference! Donate
                            them to help those in need and spread kindness in your community.
                        </CustomTypography>
                    </Box>


                    <Box className='lg:h-[515px] h-auto bg-customGreen lg:rounded-[36.28px] lg:w-[520px] w-auto
    rounded-[16px] py-6 px-6 lg:py-12 lg:px-0 lg:flex lg:flex-col lg:items-center'> {/* Added lg:items-center */}

                        {/* Clothes */}
                        <Box className='flex items-center  lg:w-[362.79px] lg:h-[78.76px] bg-white
        rounded-full h-[48.73px] w-auto gap-3'>
                            <Box className='bg-topYellow lg:w-[77.4px] w-[48px] lg:h-[78.76px] 
            lg:rounded-[70px] h-[48.73px] rounded-[43.64px] flex items-center lg:p-3 p-[6.5px]'>
                                <img src={AcbClothes} alt="clothes" className='lg:w-10 lg:h-10 w-6 h-6' />
                            </Box>
                            <h6 className='font-manrope font-semibold lg:text-[23px] lg:leading-[34.5px] text-customBlack
            text-[16px] leading-[21.86px]'>Clothes</h6>
                        </Box>

                        {/* Shoes */}
                        <Box className='lg:mt-6 mt-5 flex items-center  lg:w-[362.79px] lg:h-[78.76px] bg-white
                        rounded-full h-[48.73px] w-auto gap-3'>
                            <Box className='bg-topYellow lg:w-[77.4px] w-[48px] lg:h-[78.76px] 
                         lg:rounded-[70px] h-[48.73px] rounded-[43.64px] flex items-center lg:p-3 p-[6.5px]'>
                                <img src={AcbShoes} alt="shoes" className='lg:w-10 lg:h-10 w-6 h-6' />
                            </Box>
                            <h6 className='font-manrope font-semibold lg:text-[23px] lg:leading-[34.5px] text-customBlack
                        text-[16px] leading-[21.86px]'>Shoes</h6>
                        </Box>

                        {/* Shoes */}
                        <Box className='lg:mt-6 mt-5 flex items-center  lg:w-[362.79px] lg:h-[78.76px] bg-white
                        rounded-full h-[48.73px] w-auto gap-3'>
                            <Box className='bg-topYellow lg:w-[77.4px] w-[48px] lg:h-[78.76px] 
                         lg:rounded-[70px] h-[48.73px] rounded-[43.64px] flex items-center lg:p-3 p-[6.5px]'>
                                <img src={AcbWed} alt="shoes" className='lg:w-10 lg:h-10 w-6 h-6' />
                            </Box>
                            <h6 className='font-manrope font-semibold lg:text-[23px] lg:leading-[34.5px] text-customBlack
                        text-[16px] leading-[21.86px]'>Wedding Outfits </h6>
                        </Box>


                        {/* Shoes */}
                        <Box className='lg:mt-6 mt-5 flex items-center  lg:w-[362.79px] lg:h-[78.76px] bg-white
                      rounded-full h-[48.73px] w-auto gap-3'>
                            <Box className='bg-topYellow lg:w-[77.4px] w-[48px] lg:h-[78.76px] 
                       lg:rounded-[70px] h-[48.73px] rounded-[43.64px] flex items-center lg:p-3 p-[6.5px]'>
                                <img src={AcbToys} alt="shoes" className='lg:w-10 lg:h-10 w-6 h-6' />
                            </Box>
                            <h6 className='font-manrope font-semibold lg:text-[23px] lg:leading-[34.5px] text-customBlack
                    text-[16px] leading-[21.86px]'>Toys & Baby Items  </h6>
                        </Box>

                    </Box>

                </Box>



            </Box>


            {/* Donate section  */}
            <Box>
                <Box className='lg:py-140px lg:px-120px py-60px px-4 md:py-[72px] md:px-16'>
                    <Box className='max-w-screen-2xl mx-auto'>
                        <CustomTypography as="h2" variant="h2" color="text-topGreen " weightFont="font-bold"
                            alignment=" text-center"
                        >
                            Why Donate to Akhuwat Clothes Bank?
                        </CustomTypography>



                    </Box>
                </Box>

                {/* Carousel */}
                <Box className="lg:pb-120px pb-60px md:pb-[72px]  ">

                    <CustomCarousel slides={slides} slideWidth={{
                        lg: 320,
                        md: 320,
                        sm: 310
                    }} gap={40}
                        ArrowPosition='bottom'
                        ArrowActiveColor='#329A51'
                        ArrowInActiveColor='#A6A6A6'

                    />

                </Box>
            </Box>


            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] md:h-[500px] h-[421px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${AcbBanner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='flex flex-col lg:justify-center md:justify-center justify-end h-full 
                lg:px-[128px] md:px-16 px-4 md:py-0 lg:py-0  lg:w-[900px] md:w-[500px] w-auto  py-6  
                max-w-screen-2xl'>

                    <p className='font-manrope text-white font-extrabold text-[14px] 
                    lg:leading-[14px] lg:text-left md:text-left text-center '>
                        Akhuwat Clothes Bank</p>

                    <CustomTypography className='pt-2' as="h2" variant="h21" color="text-white " weightFont="font-bold"
                        alignment="lg:text-left md:text-left text-center"
                    >
                        Clothing the Needy with Care and Compassion
                    </CustomTypography>

                    <Box className='pt-6 flex lg:justify-start md:justify-start justify-center'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>



            {/* Our Compaign section  */}

            <Box className='bg-LightApricot lg:py-140px lg:px-120px py-52px px-4  md:px-18 md:py-16'>
                <Box className='grid lg:grid-cols-2 grid-cols-1 gap-10 max-w-screen-2xl mx-auto'>
                    <Box className='lg:hidden flex justify-center '>
                        <img src={AcCompaign} alt='compaign' />
                    </Box>

                    <Box>


                        <CustomTypography as="h2" variant="h2" color="text-topGreen " weightFont="font-bold"
                            alignment="lg:text-left text-center"
                        >
                            Our Campaigns
                        </CustomTypography>

                        <CustomTypography className="pt-4"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold' alignment='lg:text-left text-center'
                        >
                            Each year, Akhuwat Clothes Bank launches dedicated campaigns to collect and
                            distribute clothing to those in need. Our campaigns mobilize volunteers and
                            donors across the country, creating a network of compassion and support.

                        </CustomTypography>


                        <h6 className='pt-4  font-manrope lg:font-semibold lg:text-[28px] lg:leading-[42px] 
                         text-AesHead text-[16px] leading-[21.86px] font-normal lg:text-left text-center' >
                            Join us in our mission!</h6>
                        <Box className='flex lg:justify-start justify-center lg:pt-8 pt-5'>
                            <CustomButton btnText='View all' btnColor='white' btnBackground='customBlack' />
                        </Box>

                    </Box>


                    <Box className='lg:flex hidden'>
                        <img src={AcCompaign} alt='compaign' />
                    </Box>

                </Box>

            </Box>

            {/* Faq section */}

            <Box className='lg:py-120px lg:px-120px py-60px md:px-10 px-4 bg-white'>
                <AcbFaq />
            </Box>


            {/* Contribute to work section ... */}
            <Box className='bg-white lg:py-120px lg:px-120px  py-52px px-4 md:py-18 md:px-16'>
                <Box className='grid lg:grid-cols-2  grid-cols-1 max-w-screen-2xl mx-auto'>

                    <img src={AcbContribute} alt="contribute" />
                    <Box className='lg:py-10 lg:px-90px py-4 px-4'>

                        <CustomTypography as="h3" variant="h3" color="text-customBlack " weightFont="font-normal"
                            alignment="lg:text-left text-center"
                        >
                            Choose one of the various ways through which you can contribute to our work and
                            help us support communities in Pakistan.
                        </CustomTypography>

                        <Box className='lg:pt-[50px] pt-5 flex lg:justify-start justify-center'>
                            <CustomButton btnText='Donate' btnColor='white' btnBackground='customBlack' />
                        </Box>



                    </Box>

                </Box>
            </Box>

            {/* Donaate now section  */}



            <DonateNowSetion Head6="Akhuwat  Clothes Bank"
                Head2="Help Someone. Donate Now." HeadColor2="text-white" HeadColor6="text-MintGreen"
                backColor="customGreen" />


        </Box>
    )

}

export default Acb