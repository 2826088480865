import { Box } from "@material-ui/core";
import React from "react";
import JoinBanner from '../pictures/Join_us_banner.png'
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import Akhuwat1 from '../pictures/Join_us_ak1.png'
import Akhuwat2 from '../pictures/Join_us-ak2.png'
import Akhuwat3 from '../pictures/Join_us_ak3.png'
import Founder from '../pictures/Join_us_founder.png'
import Volunteer from '../pictures/Join_us_volunteer.png'
import Located from '../pictures/Join_us_located.png'

const JoinUs = () => {

    return (

        <Box >

            <Box
                className="lg:min-h-[905px] h-[271px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `
                        radial-gradient(58.96% 94.34% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 70%),
                        linear-gradient(180deg, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.6) 100%),
                        url(${JoinBanner})
                    `
                }}
            >
                <Box className="flex items-center justify-center w-full h-full absolute inset-0 text-center">


                    <CustomTypography
                        as='h1' variant='h1' color='text-white' weightFont='font-bold' alignment='text-center'
                    >
                        Join Us
                    </CustomTypography>
                </Box>
            </Box>


            {/* Why akhwat section */}
            <Box className="bg-customGreen">

         

            <Box className="max-w-screen-2xl mx-auto lg:py-120px lg:px-120px   md:py-60px  py-6 px-4">

                <Box className="flex lg:flex-row md:flex-row flex-col justify-between">


                    <CustomTypography
                        as='h2' variant='h21' color='text-white' weightFont='font-bold'
                        alignment='lg:text-left md:text-left text-center'
                    >
                        Why Akhuwat?
                    </CustomTypography>


                    <Box className="flex lg:jusitfy-end md:justify-end justify-center lg:pt-0 md:pt-0 pt-5">
                        <CustomButton btnText="View open roles" btnColor="customBlack" btnBackground="white" />
                    </Box>


                </Box>



                <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-10">

                    <Box>
                        <img src={Akhuwat1} alt="ak1" />
                        <Box>
                            <CustomTypography className="pt-5"
                                as='p' variant='p1' color='text-white' weightFont='font-semibold'
                                alignment='text-left'
                            >
                                Meaningful Impact
                            </CustomTypography>

                            <CustomTypography className="pt-4"
                                as='p' variant='p3' color='text-customGray' weightFont='font-normal'
                                alignment='text-left'
                            >
                                You can contribute directly to poverty alleviation and social equity by being
                                part of an organization that transforms lives and communities across Pakistan.

                            </CustomTypography>
                        </Box>
                    </Box>


                    {/* card2 */}

                    <Box>
                        <img src={Akhuwat2} alt="ak1" />
                        <Box>
                            <CustomTypography className="pt-5"
                                as='p' variant='p1' color='text-white' weightFont='font-semibold'
                                alignment='text-left'
                            >
                                Growth Opportunities
                            </CustomTypography>

                            <CustomTypography className="pt-4"
                                as='p' variant='p3' color='text-customGray' weightFont='font-normal'
                                alignment='text-left'
                            >
                                You can gain valuable experience and skills while working in diverse fields,
                                such as education, microfinance, and community development.

                            </CustomTypography>
                        </Box>
                    </Box>

                    {/* card3 */}


                    <Box className="md:hidden lg:block block">
                        <img src={Akhuwat3} alt="ak1" />
                        <Box>
                            <CustomTypography className="pt-5"
                                as='p' variant='p1' color='text-white' weightFont='font-semibold'
                                alignment='text-left'
                            >
                                Inspirational Environment
                            </CustomTypography>

                            <CustomTypography className="pt-4"
                                as='p' variant='p3' color='text-customGray' weightFont='font-normal'
                                alignment='text-left'
                            >
                                You can work alongside passionate individuals committed to the principles of compassion,
                                solidarity, and service and thus, facilitate personal and professional growth.

                            </CustomTypography>
                        </Box>
                    </Box>


                    {/* card3  for tablet*/}




                    <Box className="lg:hidden md:block hidden">
                        <Box className="col-span-1">
                            <CustomTypography
                                as='p' variant='p1' color='text-white' weightFont='font-semibold'
                                alignment='text-left'
                            >
                                Inspirational Environment
                            </CustomTypography>

                            <CustomTypography
                                as='p' variant='p3' color='text-customGray' weightFont='font-normal'
                                alignment='text-left'
                            >
                                You can work alongside passionate individuals committed to the principles of compassion,
                                solidarity, and service and thus, facilitate personal and professional growth.

                            </CustomTypography>
                        </Box>
                    </Box>

                    <Box className="lg:hidden md:block hidden">

                        <img src={Akhuwat3} alt="ak1" />
                    </Box>



                </Box>
                 </Box>
            </Box>


            {/* Where are located */}

            <Box className="bg-primaryLight ">

            <Box className=" max-w-screen-2xl mx-auto lg:py-120px lg:px-120px  md:py-60px py-6 px-4">


                <CustomTypography as="h2" variant="h2" color="text-customGreen"
                    alignment="text-center">
                    Where We Are Located
                </CustomTypography>


                <Box className="grid lg:grid-cols-2 grid-cols-1 gap-10 pt-10">
                    <Box className="flex justify-center items-center">
                        <img src={Located} alt="located" />
                    </Box>


                    <Box>

                        <CustomTypography className="p-6 border-b border-b-SlateGray"
                            as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-left'
                        >
                            Lahore
                        </CustomTypography>

                        <CustomTypography className="p-6 border-b border-b-SlateGray"
                            as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-left'
                        >
                            Islamabad
                        </CustomTypography>


                        <CustomTypography className="p-6 border-b border-b-SlateGray"
                            as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-left'
                        >
                            Karachi
                        </CustomTypography>



                        <CustomTypography className="p-6 border-b border-b-SlateGray"
                            as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-left'
                        >
                            Faisalabad
                        </CustomTypography>



                        <CustomTypography className="p-6 border-b border-b-SlateGray"
                            as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-left'
                        >
                            +125 Locations
                        </CustomTypography>

                    </Box>

                </Box>
                </Box>
            </Box>






            {/**  Join us sectnio */}

            <Box className="bg-schoolYellow h-auto lg:py-140px md:py-60px md:p-10 py-8 px-4 lg:px-120px">
                <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-2 grid-cols-1 rounded-[20px] overflow-hidden h-auto  lg:h-[620px]">
                    <Box>
                        <img className="lg:h-[620px] md:h-[434px] h-[251px] w-full " src={Founder} alt="founder" />
                    </Box>
                    <Box className="bg-customGray lg:pl-10 pl-5 lg:pr-[60px] pr-5 lg:py-80px py-8 flex flex-col justify-center">


                        <CustomTypography as="h2" variant="h2" color="text-customBlack "
                            alignment="lg:text-left text-center">
                            Join Us
                        </CustomTypography>

                        <Box className="lg:pt-10 pt-5">


                            <CustomTypography as="p" variant="p2" color="text-SlateGray " weightFont="font-normal"
                                alignment="lg:text-left text-center"
                            >
                                Join the world’s best interest-free microfinance organization of its kind and help us work towards
                                poverty alleviation
                            </CustomTypography>
                        </Box>
                        <Box className="flex justify-center lg:justify-start pt-8">
                            <CustomButton btnText="View Jobs" btnBackground="customBlack" btnColor="white"
                                alignment="lg:text-left text-center" />
                        </Box>
                    </Box>
                </Box>
            </Box>



            {/**  Volunteer section */}

            <Box className="bg-MistyLilc h-auto lg:py-140px md:py-60px md:p-10 py-8 px-4 lg:px-120px">
                <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-2 grid-cols-1 rounded-[20px] overflow-hidden h-auto  lg:h-[620px]">

                    <Box className="bg-customGray lg:pl-10 pl-5 lg:pr-[60px] pr-5 lg:py-80px py-8 flex flex-col justify-center">


                        <CustomTypography as="h2" variant="h2" color="text-customBlack "
                            alignment="lg:text-left text-center">
                            Volunteer at Akhuwat
                        </CustomTypography>

                        <Box className="lg:pt-10 pt-5">


                            <CustomTypography as="p" variant="p2" color="text-SlateGray " weightFont="font-normal"
                                alignment="lg:text-left text-center"
                            >
                                Since its inception, Akhuwat has laid emphasis on engaging with the youth.
                                Akhuwat engages with them through the following three programs.
                            </CustomTypography>
                        </Box>
                        <Box className="flex justify-center lg:justify-start pt-8">
                            <CustomButton btnText="View Jobs" btnBackground="customBlack" btnColor="white"
                                alignment="lg:text-left text-center" />
                        </Box>
                    </Box>

                    <Box>
                        <img className="lg:h-[620px] md:h-[434px] h-[251px] w-full " src={Volunteer} alt="founder" />
                    </Box>
                </Box>
            </Box>

        </Box>
    )

}

export default JoinUs;