import { Box } from "@material-ui/core";
import React from "react";
import PriorityBanner from "../pictures/priority-banner.png";
import CustomTypography from "../CustomTypography";

const AkhuwatPriority = () => {


    return (

        <Box className='max-w-screen-2xl mx-auto'>


            <Box
                className="lg:h-[688px] md:h-[426px] h-[245px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${PriorityBanner})
                    `
                }}
            >

            </Box>

            {/* About us section */}

            <Box className="lg:pt-[72px] pt-10  lg:pb-60px md:pb-10 lg:px-[260px] md:px-10 px-4 pb-4 bg-white">

                <CustomTypography
                    as='p' variant='p2' color='text-topGray' weightFont='font-semibold' alignment='text-center'
                >
                    About Us
                </CustomTypography>


                <CustomTypography
                    as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Akhuwat’s Priority
                </CustomTypography>

                <CustomTypography className="pt-10"
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-center'
                >
                    Akhuwat gives interest-free loans, educates deserving students, and supports the underprivileged in our society.
                </CustomTypography>

            </Box>


            <Box className="lg:pt-[72px] pt-10  lg:px-140px md:px-14 px-4  bg-white">

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    Since 2001, Akhuwat has embodied Mawakhat as a vision of development towards a
                    poverty-free society based on the values of solidarity, equity, and compassion.
                    It believes in building cultures and communities of solidarity by fostering relationships
                    of mutual support based on sharing, inclusion, and the spirit of goodness.
                </CustomTypography>


                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    Since 2001, Akhuwat has embodied Mawakhat as a vision of development towards a
                    poverty-free society based on the values of solidarity, equity, and compassion.
                    It believes in building cultures and communities of solidarity by fostering relationships
                    of mutual support based on sharing, inclusion, and the spirit of goodness.
                </CustomTypography>



                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    In the beginning, Akhuwat was primarily focused on its flagship microfinance program
                    <span className="text-customGreen font-semibold "> Akhuwat Islamic Microfinance (AIM)</span> that provides interest-free loans to underprivileged
                    people and helps them improve their businesses and livelihood. AIM is regarded as the
                    world's largest initiative of its kind. Since then, Akhuwat has extended itself into other
                    areas to fight poverty on various fronts.
                </CustomTypography>



                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    <span className="text-AesHead font-semibold"> Akhuwat Education Services (AES) </span> gives hope to young students from the hard-to-reach areas and
                    underprivileged families and provides them fee-free education, so that they can develop their
                    careers and contribute to their communities.
                </CustomTypography>



                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >

                    <span className="text-BrightOrange font-semibold">  Akhuwat Clothing Bank (ACB) </span>
                    provides clothing items to people who can’t afford them and runs campaigns during peak winter and natural disasters.
                </CustomTypography>


                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >

                    Akhuwat also supports Khwajasiras (transgenders) under the
                    <span className="text-DeepTeal font-semibold">  Akhuwat Khwajasira Program (AKSP) </span>
                    and
                    provides them with income opportunities because the pioneers of Akhuwat believe that  each individual
                    human deserves to live with dignity regardless of their sex, gender, caste, and creed.
                </CustomTypography>

            </Box>



            <Box className="lg:pt-[72px] pt-10 lg:px-120px md:px-10 px-4 lg:pb-[72px]  pb-10   bg-white">

                <Box className="lg:py-16 md:py-10 py-6 lg:px-140px md:px-14 px-4 border-[5px] border-topYellow rounded-[40px]">

                    <CustomTypography
                        as='p' variant='p1' color='text-MidnightTest' weightFont='font-semibold' alignment='text-center'
                    >
                        At Akhuwat, financial support, clothes, food, and healthcare are valued as fundamental human rights.
                    </CustomTypography>
                </Box>


                <Box className="lg:pt-[72px] pt-10">

                    <CustomTypography
                        as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                    >
                        Akhuwat was established with the hope that the privileged among us can empower those who are
                        underprivileged and deprived and thus, practice Mawakhat (solidarity among people) to alleviate
                        poverty and overcome financial difficulties of the people in need. As a consequence, Akhuwat
                        listens to those whose call for help and support have been unheard. Akhuwat, as an institution,
                        stands with the underprivileged people regardless of their ethnicity or beliefs because Akhuwat
                        trusts their capacity to change their lives.
                    </CustomTypography>

                </Box>

            </Box>

        </Box>
    )
}

export default AkhuwatPriority;