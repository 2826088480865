import React from 'react';
import { Box } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import CustomTypography from '../CustomTypography';

const FounderArticleSection = ({ head, para, gridImage, btnText, btnURL }) => {


    // Used mobile query for some dynmaic styling 

    return (
        <Box className='grid lg:grid-cols-2 grid-cols-1 overflow-hidden rounded-[20px]'>

            <Box className='h-[189px] md:h-[292px]  lg:hidden'>
                <img src={gridImage} alt="founder image" className='h-full w-full object-cover rounded-b-[20px] md:rounded-r-[20px] md:rounded-b-none' />
            </Box>
            <Box className='lg:p-10 p-4 bg-white flex flex-col justify-center'

            >



                <CustomTypography className='lg:text-left text-center'
                    as='h3' variant='h3' color='text-customBlack' weightFont='font-medium'
                    ali
                >
                      {head}
                </CustomTypography>

                
                <CustomTypography className="pt-4"
                            as='p' variant='p2' color='text-SlateGray' weightFont='font-normal'
                            alignment='lg:text-left text-center'

                        >
                            {para}

                        </CustomTypography>

                <Box className="flex lg:justify-start justify-center lg:pt-10 pt-4">

                    <CustomButton btnText={btnText} btnURL={btnURL} btnColor='white' btnBackground='customBlack' />

                </Box>
            </Box>

            <Box className='lg:block h-full hidden'>
                <img src={gridImage} alt="founder image" className='h-full w-full object-cover rounded-b-[20px] md:rounded-r-[20px] md:rounded-b-none' />
            </Box>
        </Box>
    );
}

export default FounderArticleSection;
