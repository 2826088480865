import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export function MetricCard({ title, value, icon }) {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="subtitle1" fontWeight="medium">
            {title}
          </Typography>
        }
        avatar={icon}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <Typography variant="h5" fontWeight="bold">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}
