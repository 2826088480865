// actions/blogActions.js
import axios from 'axios';
import {
  UPLOAD_BLOG_REQUEST, UPLOAD_BLOG_SUCCESS, UPLOAD_BLOG_FAIL,
  DELETE_BLOG_REQUEST, DELETE_BLOG_SUCCESS, DELETE_BLOG_FAIL,
  GET_BLOGS_REQUEST, GET_BLOGS_SUCCESS, GET_BLOGS_FAIL,
  UPDATE_BLOG_REQUEST, UPDATE_BLOG_SUCCESS, UPDATE_BLOG_FAIL
} from '../Constants/BlogConstants';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);

const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true, // Include cookies
};

export const getBlogs = () => async (dispatch) => {


  try {

    dispatch({
        type: GET_BLOGS_REQUEST
    })

   const {data}=await axios.get(`${API_BASE_URL}/api/v1/admin/blogs`,config)
      
    dispatch({
        type:GET_BLOGS_SUCCESS,
        payload:data
    })
    
} catch (error) {
    dispatch({
        type:GET_BLOGS_FAIL,
        payload:error.response.data.message
    })
}
};

export const uploadBlog = (blogData) => async (dispatch) => {
console.log(blogData.blogType+"This is blog type send from action");
  try {

    dispatch({
        type:UPLOAD_BLOG_REQUEST
    })

    const { data } = await axios.post(`${API_BASE_URL}/api/v1/blogs`, blogData,config);
    
 

    dispatch({
        type:UPLOAD_BLOG_SUCCESS,
        payload:data
    })
    
} catch (error) {
    dispatch({
        type:UPLOAD_BLOG_FAIL,
        payload:error.response.data.message
    })
}
};





export const getBlogById=(id)=>async (dispatch)=>{
    
    try {

       

       const {data}=await axios.get(`${API_BASE_URL}/api/v1/blogs/${id}`,config)
        
     
        
    } catch (error) {
        dispatch({
            payload:error.response.data.message
        })
    }
}
// Define similar functions for deleteBlog and updateBlog.
