import React from 'react';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomTypography from '../CustomTypography';

const AhsStories = ({ topHead, belowHead, gridImage, btnCondt, gridColor, textColor,btnText,btnColor}) => {

    return (
        <Box className='lg:h-[417px] h-[298px] grid lg:grid-cols-3 grid-cols-1 gap-5'>

            {btnCondt && (
                <Box className={`lg:col-span-2 bg-${gridColor} lg:rounded-[30px] lg:py-8 lg:px-8
                 py-6 px-4 flex flex-col md:py-8 md:px-8
                justify-between rounded-b-[30px]`}>
                    {/* Button at the top */}
                    <Box className="flex justify-start items-center">
                        <Link
                            to='/health'
                            className={`h-[46px] text-${btnColor} px-4 py-2 flex items-center justify-between gap-2.5 rounded-full font-manrope text-[18px] font-bold leading-[24.59px] border
                             border-${btnColor}`}
                        >
                           {btnText}
                        </Link>
                    </Box>

                    {/* topHead in the center vertically */}
                    <Box className='flex-grow flex items-center pt-5'>


                        <CustomTypography as='h3' variant='h3' weightFont='font-normal' color={textColor}>
                            {topHead}
                        </CustomTypography>
                    </Box>

                    {/* belowHead at the bottom */}
                    <Box className='flex items-center pt-5'>

                        <CustomTypography as='p' variant='p3' weightFont='font-semibold' color={textColor}>
                            {belowHead}
                        </CustomTypography>
                    </Box>
                </Box>
            )}

            {/* Image on the right side */}
            <Box className='lg:col-span-1 lg:h-[417px] h-[298px] lg:rounded-[30px]' style={{
                backgroundImage: `url(${gridImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }}>
            </Box>
        </Box>
    )
}

export default AhsStories;
