import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import FounderBanner from "../pictures/founder-banner-img.png"
import FounderArticle1 from "../pictures/founder-article1.png"
import FounderArticle2 from "../pictures/founder-article2.png"
import Founderpublication from "../pictures/founder-publication.png"
import FounderIdeas from "../pictures/founder_ideas_pic.png"
import FounderArticleSection from './FounderArticleSection';
import FounderVideos from './FounderVideos';
import FounderVideo1 from "../pictures/founder-video1.png";
import FounderVideo2 from "../pictures/hero_video2.jfif";
import heroVideo from '../pictures/hero_video.mp4';
import CustomButton from '../ReusableComponents/CustomButton';
import CustomTypography from '../CustomTypography';
import CustomCarousel from '../CustomCarasouel';

const AkFounder = () => {

    

    const FounderVideosCards=[
                           <FounderVideos thumbnail={FounderVideo1} heroVideo={heroVideo} videoTitle="Videos"
                            head="How you can generate wealth" para="Dr. Muhammad Amjad Saqib speaks about wealth in this 30 minutes 
                exclusive video."/>,

                        <FounderVideos thumbnail={FounderVideo2} heroVideo={heroVideo} videoTitle="News"
                            head="Akhuwat Microfinance meeting" para="Dr. Muhammad Amjad Saqib speaks about wealth in 
                        this 30 minutes exclusive video."/>,


                        <FounderVideos thumbnail={FounderVideo1} heroVideo={heroVideo} videoTitle="Videos"
                            head="Sitar-e-Imtiaz - Acceptance Speech" para=" Muhammad Amjad Saqib speaks about wealth in this 30 minutes exclusive video." />
    ]

    return (

        <Box>
            <Box className='bg-topAlmond lg:py-[100px] py-10 lg:px-120px md:px-10 px-4'>
                <Box className='grid lg:grid-cols-2  grid-cols-1 gap-10 max-w-screen-2xl mx-auto'>

                    <Box className='flex flex-col justify-center'>


                        <CustomTypography as="h2" variant="h21" color="text-customBlack" weightFont="font-bold"
                            alignment='lg:text-left text-center'>
                            Dr. Muhammad Amjad Saqib
                        </CustomTypography>

                        <CustomTypography className="pt-4" as="p" variant="p2" color="text-SlateGray"
                            weightFont="font-normal" alignment="lg:text-left text-center">
                            A development practitioner, public servant, philanthropist and founder of Akhuwat.
                            His expertise includes poverty alleviation initiatives, social development, governance,
                            and educational reform.
                        </CustomTypography>

                    </Box>

                    <Box className='flex lg:justify-end justify-center '>
                        <img src={FounderBanner} alt="banner" />
                    </Box>

                </Box>

            </Box>

            {/* Message section */}

            <Box className='bg-white lg:px-120px py-6 lg:py-120px md:py-10 md:px-6 px-4 max-w-screen-2xl mx-auto'>

                <CustomTypography
                    as='p' variant='p3' color='text-customBlack' weightFont='font-normal'

                >
                    Dr. Muhammad Amjad Saqib (SI, HI) is the founder and chairperson of Akhuwat, the largest
                    microfinance program in the world. Under his leadership, Akhuwat also launched Pakistan’s
                    first fee-free colleges that are open to students of impoverished families from all over
                    Pakistan.<br></br> <br></br>

                    Due to his remarkable achievements in restoring human dignity to Pakistan’s most vulnerable
                    communities, including poor women and transgenders, Dr. Saqib has received worldwide recognition,
                    including “The Ramon Magsaysay Award 2021”, popularly known as “Asia’s Nobel Prize” by the
                    Government of Philippines, the Islamic Economy Award by His Highness Sheikh Hamdan bin Mohammed
                    bin Rashid Al Maktoum, Crown Prince of Dubai and Thomson Reuters in 2018.
                    <br></br> <br></br>

                    He is also working as the Chairman of Punjab Development Network (PDN), a cluster of more than 160
                    development and charity organizations, working for social and economic development in areas like
                    poverty, education, health, clean drinking water, solid waste management, and sanitation.
                    In addition, he is the Executive Director of Fountain House - a 500-bed indoor & outdoor
                    patient institute of treatment and rehabilitation for people suffering from psychiatric
                    illnesses.
                </CustomTypography>


                {/* Poverty Block */}
                <Box className='lg:pt-80px pt-8'>
                    <Box className=' h-auto border-[5px] lg:py-80px md:py-60px md:px-10 lg:px-16 px-4 py-6 border-topYellow rounded-[40px]
                '>


                        <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                            as='p' variant='p1' color='text-MidnightTest' weightFont='font-semibold'
                            alignment="text-center"
                        >

                            “Poverty is not just about lack of resources; it’s about deprivation – of a social
                            network, justice, and morality. But poverty, in its worst form, is poverty of hope.”
                        </CustomTypography>

                        <CustomTypography className="pt-10"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold'
                            alignment='text-center'

                        >
                            Dr. Muhammad Amjad Saqib
                        </CustomTypography>

                        <CustomTypography className="pt-2"
                            as='p' variant='p3' color='text-Charcol' weightFont='font-normal'
                            alignment='text-center'

                        >
                            Founder, Chairman Akhuwat
                        </CustomTypography>

                    </Box>

                </Box>


                <CustomTypography className='lg:pt-80px pt-8'
                    as='p' variant='p3' color='text-customBlack' weightFont='font-normal'

                >
                    He is also the recipient of the Social Entrepreneur of the Year award for 2018 by the
                    World Economic Forum (WEF) and Schwab Foundation and the Commonwealth’s 31st Point of
                    Light award by Queen Elizabeth II in 2018. He has also been awarded the Lifetime
                    Achievement Award, in 2014 by Abu Dhabi Islamic Bank and Thomson Reuters, for his
                    efforts to promote Islamic finance.<br></br> <br></br>

                    The President of Pakistan bestowed on him Sitara-e-Imtiaz (2011) and Hilal-a-Imtiaz
                    (2023), two of the most coveted civil awards of Pakistan. In recognition of his
                    significant contribution towards the betterment of humanity, Rotary International
                    presented him “Lifetime Achievement Award” in December 2022.
                    <br></br> <br></br>

                    He is the author of ten books and an acclaimed speaker who has spoken at the United
                    Nations (UN), Harvard, Oxford, Cambridge, and many other prestigious institutions
                    throughout the world.
                </CustomTypography>




            </Box>

            {/* Article section... */}
            <Box className='bg-MidnightTest'>

            <Box className='max-w-screen-2xl mx-auto  lg:py-90px py-6 lg:px-120px md:py-[26px] md:px-[38px] px-4'>

                <Box className='grid lg:grid-cols-3 grid-cols-1 border-t border-t-topYellow lg:gap-[100px] 
                gap-5 '>

                    <CustomTypography className='col-span-2 lg:pt-10 pt-2' as="h2" variant="h21" color="text-topYellow" weightFont="font-bold">
                        Articles of Dr. Amjid Saqib
                    </CustomTypography>


                    <CustomTypography className="lg:pt-10 pt-5 " as="p" variant="p2" color="text-white"
                    weightFont="font-normal" >
                     Dr. Amjad Saqib regularly publishes articles in which he shares anecdotes and
                     narrative stories about his travels and Akhuwat's mission.
                </CustomTypography>



                </Box>

                <Box className='lg:pt-120px pt-8'>
                    <FounderArticleSection head="Sweden" para="In this article, Dr Amjad shares his 
                    experience of visiting Sweden and spreading the vision of Akhuwat College."
                        gridImage={FounderArticle1}
                        btnText="Read more"
                        btnURL="/founder"
                    />
                </Box>

                <Box className='lg:pt-120px pt-8'>
                    <FounderArticleSection head="Los Angeles" para=" Dr. Amjad shares his experience of 
                    visiting Los Angeles and spending an evening with friends of Akhuwat."
                        gridImage={FounderArticle2}
                        btnText="Read more"
                        btnURL="/founder"
                    />
                </Box>
            </Box>
            </Box>

            {/* Publication section */}

            <Box className='bg-white lg:py-140px py-6 md:py-[26px] md:px-[38px] lg:px-140px px-4  max-w-screen-2xl mx-auto'>

                <FounderArticleSection head="Publications"
                    para=" Dr. Muhammad Amjid Saqib is author of [X books] . 
            He has received over 240 reviews on Goodreads. "  gridImage={Founderpublication}

                    gridBackColor="white"
                    btnText="View books"
                    btnURL="/ahs"
                />

            </Box>



            {/* Idea section... */}

            <Box className='bg-SoftVanila'>

            <Box className=' py-10   lg:px-120px px-4 max-w-screen-2xl mx-auto'>

                <Box className=' border-t border-t-blackColor  '>

                <CustomTypography className='lg:w-[480px] w-auto pt-10' as="h2" variant="h2" color="text-customBlacks" weightFont="font-bold">
                Recent insights, ideas, news and impact
          </CustomTypography>

                </Box>

                <Box className='py-8 lg:py-90px grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 
                gap-10'>
                    <Box className='lg:col-span-2 md:col-span-1'>
                        <img src={FounderIdeas} alt="ideas" className='lg:h-[408px] h-[269px]
                        lg:w-[786px] w-auto'/>
                    </Box>

                    <Box className='lg:col-span-1'>

                    <CustomTypography as="h3" variant="h3" color="text-blackColor" weightFont="font-semibold">
                    Interview with University of Cambridge
          </CustomTypography>

          <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-customBlack' weightFont='font-normal'

                        >
                                   Dr. Muhammad Amjad Speaks about Microfinance in Pakistan in an
                                   interview at Cambridge University (United Kingdom).

                        </CustomTypography>
                      

                        <Box className="flex  pt-10 justify-start">
                            <CustomButton btnText='Read more' btnColor='white' btnBackground='customBlack' />
                        </Box>
                    </Box>
                </Box>






                {/* Founder video */}

                <Box className='lg:py-90px py-8'>
                    <Box className='lg:grid grid-cols-3 hidden gap-10'>
                     
                     {FounderVideosCards}
                    </Box>

                    <Box className=' lg:hidden'>


                    
                    <CustomCarousel slides={FounderVideosCards} slideWidth={{
                        lg: 386,
                        md: 346,
                        sm: 310
                    }} gap={20}



                    />

                    </Box>

                </Box>

              </Box>
            </Box>





            {/* Support section */}
                <Box className='bg-customGreen'>
            <Box
                className='h-auto lg:py-[128px] md:py-16 md:px-10 py-8 lg:px-[120px] px-4  relative flex 
             flex-col max-w-screen-2xl mx-auto'
            >

                {/* Content */}
                <Box className="relative z-10">
                    <CustomTypography as="h2" variant="h2" color="text-white" weightFont="font-bold">
                        Dr. Muhammad Amjad Saqib
                    </CustomTypography>


                    <CustomTypography className="pt-5 " as="p" variant="p2" color="text-white"
                        weightFont="font-semibold" >
                        For any media related inquiries, please get in touch.
                    </CustomTypography>

                    <Box className="pt-7 flex justify-start">
                        <CustomButton btnText='Contact us' btnColor='customBlack' btnBackground='white' />
                    </Box>
                </Box>
            </Box>
           </Box>
        </Box>
    )
}


export default AkFounder