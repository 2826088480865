import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FounderIconArrow from "../pictures/founder-arrow-icon.png";
import { Link } from 'react-router-dom';
import CustomTypography from '../CustomTypography';

const FounderVideos = ({thumbnail,heroVideo,videoTitle, head,para,titleColor="customBlack", 
    headColor="text-customBlack",paraColor="text-customBlack"}) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayVideo = () => {
        setIsPlaying(true);
    };

    return (
        <Box>

            <Box className="relative w-full ">
                {!isPlaying &&heroVideo && (
                    <Box className="relative w-full h-full cursor-pointer" onClick={handlePlayVideo}>
                        <img src={thumbnail} alt="thumbnail" className="w-full h-full object-cover rounded-[20px]" />
                        <Box className="absolute inset-0 flex items-center justify-center">
                            <Box className="bg-black bg-opacity-50 p-4 rounded-full">
                                <PlayArrowIcon className="text-white text-[64px]" />
                            </Box>
                        </Box>
                    </Box>
                )}
                {isPlaying && heroVideo && (
                    <Box className="relative w-full h-[250px]">
                        <video autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover z-0 rounded-[20px]">
                            <source src={heroVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <Box className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-[14px] px-2 py-1 rounded">
                            0:30 / 30:00
                        </Box>
                    </Box>
                )}

                {!heroVideo &&thumbnail &&(
                                        <Box className="relative w-full h-full  ">
                                        <img src={thumbnail} alt="thumbnail" className="w-full h-[205px] object-cover rounded-[20px]" />
                                        <Box className="absolute inset-0 flex items-center justify-center">
                                        
                                        </Box>
                                    </Box>

                )}
       
            </Box>

            <h3 className={`text-left pt-7  font-manrope font-normal  text-${titleColor} 
            text-[22px]  leading-[31.5px]  
            `}>{videoTitle}</h3>

            

            <Box className="lg:pt-[22px] pt-2">
             

                <CustomTypography 
                        as='p' variant='p1' color={headColor} weightFont='font-semibold' 
                    >
                          {head}
                    </CustomTypography>

                <CustomTypography  className='pt-3'
                        as='p' variant='p2' color={paraColor} weightFont='font-normal' 
                    >
                            {para}
                    </CustomTypography>

                    <Box className=" flex justify-start pt-3 w-auto">
                                <Link
                                    to="/about"
                                    className="flex flex-row items-center justify-center "
                                >

                                    <CustomTypography
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                    >
                                        Read more

                                    </CustomTypography>

                                    <img className='ml-2 w-6 h-6 object-cover' src={FounderIconArrow} alt="arrow " />
                                </Link>
                            </Box>
            </Box>

        </Box>
    );
}

export default FounderVideos;
