import React, { useState } from 'react';
import Box from '@mui/material/Box';

import patternRight from '../pictures/about-values-pattern.png'
import patternLeft from '../pictures/about-pattern-left.png'
const FlipCard = ({ cardNumber, frontImage, backHead, backPara }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => setIsFlipped(true);
    const handleMouseLeave = () => setIsFlipped(false);




    return (

        <Box
            className="relative group h-[247px] lg:h-[450px] "
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{

                perspective: '1000px',
            }}

        >
            <Box
                className="absolute w-full h-full transition-transform duration-500"
                sx={{
                    transformStyle: 'preserve-3d',
                    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
                }}
            >
                <Box
                    className="bg-customGreen absolute w-full h-full backface-hidden p-3 lg:p-6 rounded-[8.79px] lg:rounded-[16px] "
                    sx={{

                        backgroundImage: `url(${patternRight}),url(${patternLeft})` ,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom righ, bottom left',
                        backgroundSize: 'cover',

                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        backfaceVisibility: 'hidden',
                    }}
                >
                    <h1 className='font-manrope text-white font-medium text-[17.57px] lg:text-[32px] leading-[15.38px] lg:leading-[28px] self-start'>{cardNumber}</h1>
                    <Box className='w-[212.63px] lg:w-full flex justify-center items-center flex-grow mx-auto'>
                        <img className='h-[91.19px] lg:h-[166px] ' src={frontImage} alt="" />
                    </Box>

                </Box>


                <Box
                    className="absolute w-full h-full backface-hidden"
                    sx={{
                        backgroundColor: '#0F6742',
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        transform: 'rotateY(180deg)',
                        backfaceVisibility: 'hidden',
                        borderRadius: '16px',
                        padding: '24px'
                    }}
                >
                    <h1 className='font-manrope text-white font-medium text-[17.57px]   lg:text-[32px] leading-[15.38px] lg:leading-[28px] self-start'>{cardNumber}</h1>

                    <Box className='flex flex-col justify-center flex-grow'>
                        <h1 className='font-manrope text-white font-medium text-[17.57px] lg:text-[32px] leading-[15.38px] lg:leading-[28px]'>{backHead}</h1>
                        <p className='lg:pt-5 pt-2 font-manrope font-normal text-[14px] leading-[15.38px] lg:text-[18px] lg:leading-[28px]'>
                            {backPara}
                        </p>
                    </Box>
                </Box>

            </Box>
        </Box>




    );
};
export default FlipCard