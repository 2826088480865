import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, showAdminUsers } from "../Actions/userAction";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomTypography from "../CustomTypography";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Sidebar } from "./Sidebar"; // Import your Sidebar component

const AllUsers = () => {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.adminUsers) || {};

    useEffect(() => {
        dispatch(showAdminUsers());
    }, [dispatch]);

    const handleDeleteUser = (id) => {
        dispatch(deleteUser(id)).then(() => {
            Swal.fire({
                icon: "success",
                title: "Deleted",
                text: "User deleted successfully!",
            });
            dispatch(showAdminUsers());
        });
    };

    return (
        <Box display="flex" className="bg-white min-h-screen">
            {/* Sidebar */}
            <Box width="250px" className="bg-gray-100 shadow-lg">
                <Sidebar /> {/* Ensure this Sidebar component is styled as per your design */}
            </Box>

            {/* Main Content */}
            <Box flex={1} className="lg:py-10 lg:px-120px md:py-10 md:px-16 py-6 px-4">
                <CustomTypography as="h2" variant="h2" alignment="text-center" color="text-customBlack">
                    All Users
                </CustomTypography>
                <TableContainer component={Paper} className="p-4 mt-10" sx={{ boxShadow: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "red" }}>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>User Name</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Email</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Role</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>Phone</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold" }}>DOB</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Update</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.length > 0 ? (
                                users.map((user) => (
                                    <TableRow
                                        key={user.userid}
                                        sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                                    >
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{user.user_name}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{user.email}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{user.role_name}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{user.phone}</TableCell>
                                        <TableCell style={{ padding: "16px", fontSize: "14px" }}>{user.dob}</TableCell>
                                        <TableCell style={{ padding: "16px", textAlign: "center" }}>
                                            {/* Update action column */}
                                            <Link color="primary" to={`/admin/users/update/${user.userid}`} style={{ marginRight: "16px" }}>
                                                <EditIcon />
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{ padding: "16px", textAlign: "center" }}>
                                            {/* Delete action column */}
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteUser(user.userid)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No Users
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default AllUsers;
