import React from 'react';
import Box from '@mui/material/Box';
import CustomTypography from '../CustomTypography';


const AimWork = ({ frontHead, backPara }) => {





    return (

        <Box
            className=" py-52px px-8 bg-white min-h-[250px] rounded-[30px] "

        >

            <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"

            >
                {frontHead}
            </CustomTypography>

            <CustomTypography className='pt-[14px]'
                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

            >
                {backPara}
            </CustomTypography>


        </Box>







    );
};
export default AimWork