import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getJobs } from "../Actions/JobActions";
import CustomTypography from "../CustomTypography";
import clock from "../pictures/position-clock.svg"
import location from "../pictures/position-location.svg"
const OpenPositions = () => {

    const dispatch = useDispatch();
    const { jobs } = useSelector(state => state.getAllJobs) || {};

    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);


    return (
        <Box className="lg:py-120px lg:px-[240px] md:py-[72px] md:px-16 py-6 px-4 bg-white">
            <CustomTypography
                as='h2' variant='h21' color='text-customBlack' weightFont='font-bold'
                alignment="text-center"
            >
                Open Positions
            </CustomTypography>

            <CustomTypography className="pt-10"
                as='p' variant='p2' color='text-SlateGray' weightFont='font-normal'
                alignment="text-center"
            >
                Join the world’s best interest-free microfinance organization of its kind and help
                us work towards poverty alleviation. We handpick some of the best writers,
                designers, content creators, videographers, photographers, and social media
                managers that get the chance to tell our inspiring story and contribute their
                share to the impact Akhuwat has created in the last 23 years.
            </CustomTypography>

            <Box className="pt-10 lg:pt-80px">
                <Box>
                    {jobs && jobs.length > 0 ? (
                        jobs.map(job => (
                            <div key={job.id} className="p-4 mb-4  rounded-[30px] bg-PowderColor
                        lg:py-12 lg:px-12 py-8 md:px-8 px-5 grid lg:grid-cols-3 grid-cols-1 gap-10">
                                <Box className="col-span-2 ">
                                    <CustomTypography
                                        as='p' variant='p1' color='text-customBlack' weightFont='font-semibold'
                                        alignment="lg:text-left text-center"
                                    >
                                        {job.title}
                                    </CustomTypography>

                                    <CustomTypography className="pt-3"
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-normal'
                                        alignment="lg:text-left text-center"
                                    >
                                        {job.description}
                                    </CustomTypography>

                                    <Box className="flex flex-row justify-center gap-8 pt-60px lg:justify-start">
                                        {/* First Box with Icon and Text */}
                                        <Box className="flex items-center gap-2">
                                           <img src={clock} alt="clock"/> 
                                            <span className="font-normal font-manrope text-customBlack">{job.jobType}</span>
                                        </Box>

                                        {/* Second Box with Icon and Text */}
                                        <Box className="flex items-center gap-2">
                                        <img src={location} alt="location"/>  {/* Replace with your desired icon */}
                                            <span className="font-normal font-manrope text-customBlack">{job.jobLocation}</span>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="flex items-center justify-center">
                                  <a className="px-4 py-3 text-white rounded-full bg-customBlack font-manrope" href={job.jobGoogleLink}>Apply now</a>
                                </Box>

                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-600">No blogs available.</p>
                    )}
                </Box>

            </Box>
        </Box>
    )

}

export default OpenPositions;