import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";

//Akhuwat stories testimonials
const ActiveJobCategories = ({ JobImg, jobType1,jobType2,jobType3,jobType4,jobType5,JobTitle }) => {

    return (

        <Box className="rounded-[24px] overflow-hidden">

            <Box >
            <img src={JobImg} alt="story image" />
            </Box>
           

            <Box className="p-6 bg-white ">
                

                <CustomTypography 
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' 
                    
                >
                    {JobTitle}
                </CustomTypography>

                <Box className="pt-6">

              

                <CustomTypography className=" relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' 
                >
                    {jobType1}
                </CustomTypography>

                <CustomTypography className=" relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' 
                >
                    {jobType2}
                </CustomTypography>

                <CustomTypography className=" relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' 
                >
                    {jobType3}
                </CustomTypography>

                <CustomTypography className=" relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' 
                >
                    {jobType4}
                </CustomTypography>

                <CustomTypography className=" relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' 
                >
                    {jobType5}
                </CustomTypography>

                </Box>



            </Box>

        </Box>
    )

}

export default ActiveJobCategories