import {
    ALL_USER_FAIL,
    ALL_USER_REQUEST,
    ALL_USER_SUCCESS,
    NEW_USER_FAIL,
    NEW_USER_REQUEST,
    NEW_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    LOGOUT_USER_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_SUCCESS,
    CLEAR_ERRORS,
    UPDATE_PASSWORD_REQUEST,
    ADMIN_USERS_REQUEST,
    ADMIN_USERS_SUCCESS,
    ADMIN_USERS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    SINGLE_USER_REQUEST,
    SINGLE_USER_SUCCESS,
    SINGLE_USER_FAIL,
    ALL_ROLES_REQUEST,
    ALL_ROLES_SUCCESS,
    ALL_ROLES_FAIL,
    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAIL
}
    from "../Constants/UserConstants";

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);


const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true, // Include cookies
};

// Login Action
/*export const login = (user_name, password) => async (dispatch) => {
    try {
        dispatch({ type: ALL_USER_REQUEST });

        const config = { headers: { "Content-Type": "application/json" } };
        const { data } = await axios.post(`${API_BASE_URL}/api/v1/login`, { user_name, password }, config);
       console.log("This is toke"+data.token)

             // Call loadUser with the user's ID
             if (data.user && data.user.userid) {
                await dispatch(loadUser(data.user.userid)); // Assuming loadUser is a Redux action
            }


        dispatch({ type: ALL_USER_SUCCESS, payload: data.user });
    } catch (error) {
        dispatch({
            type: ALL_USER_FAIL,
            payload: error.response.data.error || "Login failed. Please try again.", // Fallback message
        });
    }
};

*/

export const register = (user_name, email, password, roleid, first_name, last_name, phone, dob) => async (dispatch) => {


    try {
        dispatch({
            type: NEW_USER_REQUEST
        })


        const { data } = await axios.post(`${API_BASE_URL}/api/v1/register`,
            {
                user_name, email, password, roleid, first_name, last_name, phone,
                dob
            }, config)



        dispatch({
            type: NEW_USER_SUCCESS,
            payload: data.user
        })

    } catch (error) {
        dispatch({
            type: NEW_USER_FAIL,
            payload: error.response?.data?.message?.errors?.[0]?.message ||
             'Unique Username and password should be atleast 6 character and valid email'
        })
    }

}




export const updateUser = (user_name, email, roleid, first_name, last_name, phone, dob
    , id) => async (dispatch) => {


        try {

            dispatch({
                type: NEW_USER_REQUEST
            })


            const { data } = await axios.put(`${API_BASE_URL}/api/v1/admin/user/${id}`,
                { user_name, email, roleid, first_name, last_name, phone, dob }, config)



            dispatch({
                type: NEW_USER_SUCCESS,
                payload: data.user
            })

        } catch (error) {
            dispatch({
                type: NEW_USER_FAIL,
                payload: error.response.data.error || 'Wrong information||Duplicate Email'
            })
        }

    }






//loadUser

export const loadUser = () => async (dispatch) => {


    try {

        dispatch({
            type: LOAD_USER_REQUEST
        })

        const { data } = await axios.get(`${API_BASE_URL}/api/v1/me`, config)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data.user
        })

    } catch (error) {



        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.error
        })

    }

}

//End session of user


export const logoutUser = () => async (dispatch) => {


    try {




        await axios.get(`${API_BASE_URL}/api/v1/logout`, config)



        dispatch({
            type: LOGOUT_USER_SUCCESS
        })

    } catch (error) {



        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.error
        })

    }

}

//it will clear errors 
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}






//Update_Password of user..

export const updatePassword = (oldPassword, newPassword, confirmPassword) => async (dispatch) => {




    try {

        dispatch({
            type: UPDATE_PASSWORD_REQUEST
        })


        const { data } = await axios.put(`${API_BASE_URL}/api/v1/password/update`, { oldPassword, newPassword, confirmPassword }, config)



        dispatch({
            type: UPDATE_PASSWORD_SUCCESS,
            payload: data.user
        })

    } catch (error) {



        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.response.data.error
        })

    }

}






//Show all users by ADMIN

export const showAdminUsers = () => async (dispatch) => {




    try {

        dispatch({
            type: ADMIN_USERS_REQUEST
        })


        const { data } = await axios.get(`${API_BASE_URL}/api/v1/admin/users`,config)



        dispatch({
            type: ADMIN_USERS_SUCCESS,
            payload: data
        })

    } catch (error) {



        dispatch({
            type: ADMIN_USERS_FAIL,
            payload: error.response.data.error
        })

    }

}


//delete a single user by admin
export const deleteUser = (id) => async (dispatch) => {


    try {




        dispatch({
            type: DELETE_USER_REQUEST
        })

        await axios.delete(`${API_BASE_URL}/api/v1/admin/user/${id}`,config)



        dispatch({
            type: DELETE_USER_SUCCESS
        })

    } catch (error) {



        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.error
        })

    }

}









export const getAllRoles = () => async (dispatch) => {
    try {
        dispatch({
            type: ALL_ROLES_REQUEST,
        });

    

        const { data } = await axios.get(`${API_BASE_URL}/api/v1/admin/all/roles`, config)

        dispatch({
            type: ALL_ROLES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_ROLES_FAIL,
            payload: error.response?.data?.message || 'Could not get roles',
        });
    }
};







export const clearSuccess = () => async (dispatch) => {
    dispatch({ type: "CLEAR_SUCCESS" });
};
