import { Users, UserCircle, BookOpen, Newspaper, Image, Briefcase, FileText, Video, LogOut } from "lucide-react"
import { Sidebar } from "./Sidebar"
import { MetricCard } from "./MetricCard"
import { Button } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { loadUser, logoutUser } from "../Actions/userAction"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router-dom"
import Store from "../Store"

export default function Dashboard() {
  const { user } = useSelector(state => state.user)
  const metrics = [
    { title: "Total Users", value: 20, icon: <Users className="h-4 w-4 text-muted-foreground" /> },
    { title: "Total Roles", value: 5, icon: <UserCircle className="h-4 w-4 text-muted-foreground" /> },
    { title: "Total Blogs", value: 4, icon: <BookOpen className="h-4 w-4 text-muted-foreground" /> },
    { title: "Total Media/News", value: 6, icon: <Newspaper className="h-4 w-4 text-muted-foreground" /> },
  ]

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function removeUser() {

    dispatch(logoutUser())
      .then(() => {
        alert.success("Logout Successful");
        Store.dispatch(loadUser());
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
        // Handle the error, e.g., show an error message to the user
      });


  }

  return (
    <div className="flex min-h-screen bg-gray-50 ">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1">
        {/* Header */}
        <header className="flex h-14 items-center justify-between border-b bg-white px-6 shadow-sm">
          <h1 className="text-lg font-semibold">Admin Dashboard</h1>
          <div className="flex items-center gap-4">
            <span>
              <span className="text-xl font-bold text-blue-500"> {user.user_name} </span>
              <span className="text-sm font-medium text-gray-500 ml-2">({user.role_name})</span>
            </span>

            <Button variant="ghost" size="icon">
              <LogOut className="h-4 w-4" onClick={removeUser} />
            </Button>
          </div>
        </header>

        {/* Dashboard Metrics */}
        <main className="p-6">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            {metrics.map((metric, index) => (
              <MetricCard key={index} title={metric.title} value={metric.value} icon={metric.icon} />
            ))}
          </div>
        </main>
      </div>
    </div>
  )
}
