import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import axios from "axios";
import {
    getAllRoles,
    updateUser,
    clearErrors,
    clearSuccess,
} from "../Actions/userAction";
import LoadingSpinner from "../Loader";
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import { Sidebar } from "./Sidebar";  // Import Sidebar component

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);

const UpdateUserDetails = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { roles } = useSelector((state) => state.adminRoles) || {};
    const { error, success } = useSelector((state) => state.newUser);

    const [registerName, setRegisterName] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [newRole, setNewRole] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [loading, setLoading] = useState(true); // Initially loading

    const loginSubmit = (e) => {
        e.preventDefault();
        dispatch(
            updateUser(
                registerName,
                registerEmail,
                newRole,
                firstName,
                lastName,
                mobileNumber,
                dateOfBirth,
                id
            )
        );
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (success) {
            alert.success("User updated successfully");
            dispatch(clearSuccess());
            navigate("/admin/users"); // Redirect after successful update
        }

        const fetchUser = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/v1/admin/user/${id}`, {withCredentials: true});
                const fetchedUser = response.data.user;
                setRegisterName(fetchedUser.user_name);
                setRegisterEmail(fetchedUser.email);
                setFirstName(fetchedUser.first_name);
                setLastName(fetchedUser.last_name);
                setDateOfBirth(fetchedUser.dob);
                setMobileNumber(fetchedUser.phone);
                setNewRole(fetchedUser.roleid); // Assuming `roleid` matches the dropdown value
                setLoading(false);
            } catch (error) {
                console.error(
                    "Error fetching user:",
                    error.response ? error.response.data : error.message
                );
                setLoading(false);
            }
        };

        fetchUser();
        dispatch(getAllRoles()); // Fetch all roles
    }, [dispatch, alert, id, error, success, navigate]);

    return (
        <Fragment>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 pb-6 pr-6">
                    <Sidebar /> {/* Include Sidebar here */}
                    <div className="flex-1 mt-6 p-6 max-w-3xl mx-auto bg-white shadow-lg rounded-xl">
                        <h2 className="text-3xl font-extrabold text-gray-800 text-center mb-6">
                            Update User Details
                        </h2>
                        <form className="space-y-4" onSubmit={loginSubmit}>
                            {/* First Name */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <PersonIcon className="text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Last Name */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <PersonIcon className="text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Username */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <PersonIcon className="text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={registerName}
                                    onChange={(e) => setRegisterName(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Email */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <EmailIcon className="text-gray-400" />
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    value={registerEmail}
                                    onChange={(e) => setRegisterEmail(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Mobile Number */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <PhoneIcon className="text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Date of Birth */}
                            <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-2">
                                <CakeIcon className="text-gray-400" />
                                <input
                                    type="date"
                                    value={dateOfBirth}
                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                    className="w-full outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>

                            {/* Role */}
                            <div>
                                <label className="block text-gray-700 mb-1" htmlFor="role">
                                    Role
                                </label>
                                <select
                                    id="role"
                                    value={newRole}
                                    onChange={(e) => setNewRole(e.target.value)}
                                    className="w-full p-3 pr-10 bg-gray-50 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all appearance-none"
                                    required
                                >
                                    <option value="" disabled>
                                        Select Role
                                    </option>
                                    {roles &&
                                        roles.map((role) => (
                                            <option key={role.roleid} value={role.roleid}>
                                                {role.role_name}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            <Button
                                type="submit"
                                disabled={loading}
                                variant="contained"
                                endIcon={<SendIcon />}
                                className="w-full py-2 mt-4 text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Update User
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default UpdateUserDetails;
