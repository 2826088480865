import { Box } from "@material-ui/core";
import React, { useState } from "react";
import CustomTypography from "../CustomTypography";
import BannerStory from "../pictures/HopeStories-banner.png";
import TabMicro1 from "../pictures/HopeStories_tabM.png";
import TabMicro2 from "../pictures/HopeStories_TabM1.png";
import Story1 from "../pictures/HopeStories_story1.png";
import Story2 from "../pictures/HopeStories_story2.png";
import StoriesTestimonial from "./StoriesTestimonial";
import CustomCarousel from "../CustomCarasouel";
import MakVideo from "../pictures/Makhwat-video.png";
import CustomButton from "../ReusableComponents/CustomButton";

const HopeStories = () => {

    const [activeTab, setActiveTab] = useState("Islamic Microfinance");
    const [activeBtn, setActiveBtn] = useState("Rs. 1000");


    const slides = [
        <StoriesTestimonial
            storyImg={Story1}
            storyTitle="Akhuwat Education Services"
            titleColor="AesHead"
            storyHead="“This institute has made me believe that education is for everyone.”"
            storyDesc="The rations bags contained rice, lentils, wheat, spices, and cooking oil, which lasted three weeks for a family of six members."
        />,
        <StoriesTestimonial
            storyImg={Story2}
            storyTitle="Akhuwat Islamic Microfinance"
            titleColor="topGreen"
            storyHead="“This institute has made me believe that education is for everyone.”"
            storyDesc="The rations bags contained rice, lentils, wheat, spices, and cooking oil, which lasted three weeks for a family of six members."
        />,
        <StoriesTestimonial
            storyImg={Story1}
            storyTitle="Akhuwat Health Services"
            titleColor="AhsPrimary"
            storyHead="“This institute has made me believe that education is for everyone.”"
            storyDesc="The rations bags contained rice, lentils, wheat, spices, and cooking oil, which lasted three weeks for a family of six members."
        />,

    ]

    return (

        <Box >

            {/** banner section */}

            <Box className="lg:px-16 bg-MintCream">
                <Box className="max-w-screen-2xl mx-auto lg:py-60px lg:px-[100px] md:py-18 md:px-16 py-6 px-4">


                    <CustomTypography
                        as='h1' variant='h1' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                    >
                        Akhuwat Stories
                    </CustomTypography>


                    <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                        as='p' variant='p1' color='text-SlateGray' weightFont='font-normal'
                        alignment="text-center"
                    >
                        Lorem ipsum dolor sit amet consectetur. Tellus pretium ullamcorper diam nisl
                        tincidunt tortor amet. Ipsum tellus est vestibulum nisl pellentesque sagittis.

                    </CustomTypography>


                    <Box className="flex justify-center pt-10">
                        <img src={BannerStory} alt="banner" />
                    </Box>

                </Box>

            </Box>




            {/** tabs section */}
            <Box className="bg-white border-b-[2px] border-b-[#E4E4E4] shadow-shadowStory ">
                <Box
                    className="lg:pt-3 lg:pb-6 lg:px-[120px] 
            md:px-10 md:pt-4 md:pb-4 px-4 lg:flex lg:flex-row lg:gap-[40px] lg:items-center lg:justify-start
            grid grid-cols-3 gap-[24px] max-w-screen-2xl mx-auto ">

                    <Box onClick={() => setActiveTab("Islamic Microfinance")}>

                        <CustomTypography
                            className={`py-4 lg:py-2 text-center cursor-pointer border-b-[2px] ${activeTab === "Islamic Microfinance" ? "border-topGreen" : "border-white"}`}
                            as='p'
                            variant='p2'
                            color={`${activeTab === "Islamic Microfinance" ? "text-topGreen" : "text-MediumGray"}`}
                            weightFont='font-semibold'

                        >
                            Islamic Microfinance
                        </CustomTypography>

                    </Box>

                    <Box onClick={() => setActiveTab("Education System")}>

                        <CustomTypography
                            className={`py-4 lg:py-2 text-center cursor-pointer border-b-[2px] ${activeTab === "Education System" ? "border-topGreen" : "border-white"}`}
                            as='p'
                            variant='p2'
                            color={`${activeTab === "Education System" ? "text-topGreen" : "text-MediumGray"}`}
                            weightFont='font-semibold'

                        >
                            Education System
                        </CustomTypography>

                    </Box>

                    <Box onClick={() => setActiveTab("Khawajasara Program")}>

                        <CustomTypography
                            className={`py-4 lg:py-2 text-center cursor-pointer border-b-[2px] ${activeTab === "Khawajasara Program" ? "border-topGreen" : "border-white"}`}
                            as='p'
                            variant='p2'
                            color={`${activeTab === "Khawajasara Program" ? "text-topGreen" : "text-MediumGray"}`}
                            weightFont='font-semibold'

                        >
                            Khawajasara Program
                        </CustomTypography>

                    </Box>



                    <Box onClick={() => setActiveTab("Health Services")}>
                        <CustomTypography
                            className={`py-4 lg:py-2 text-center cursor-pointer border-b-[2px] ${activeTab === "Health Services" ? "border-topGreen" : "border-white"}`}
                            as='p'
                            variant='p2'
                            color={`${activeTab === "Health Services" ? "text-topGreen" : "text-MediumGray"}`}
                            weightFont='font-semibold'

                        >
                            Health Services
                        </CustomTypography>

                    </Box>


                    <Box onClick={() => setActiveTab("Clothes Bank")}>
                        <CustomTypography
                            className={`py-4 lg:py-2 text-center cursor-pointer border-b-[2px] ${activeTab === "Clothes Bank" ? "border-topGreen" : "border-white"}`}
                            as='p'
                            variant='p2'
                            color={`${activeTab === "Clothes Bank" ? "text-topGreen" : "text-MediumGray"}`}
                            weightFont='font-semibold'

                        >
                            Clothes Bank
                        </CustomTypography>

                    </Box>


                </Box>
            </Box>



            {activeTab === "Islamic Microfinance" && (

                <Box className="bg-customGray">
                    <Box className="lg:py-60px lg:px-120px md:py-60px md:px-10 py-6 px-4 max-w-screen-2xl mx-auto ">

                        <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-120px md:gap-80px gap-10">

                            <Box className="flex lg:justify-start justify-center">

                                <img src={TabMicro1} alt="Microfancne" />

                            </Box>


                            <Box className="flex flex-col justify-center">
                                <CustomTypography className="pt-6"
                                    as='p' variant='p3' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                                >
                                    “The biggest benefit of having a stable business is not having to ask for money from
                                    anyone; which is very liberating. My journey with Akhuwat began when I borrowed an amount
                                    to buy material for the bags I make. Over the years I learnt that I had to add in more
                                    variety to my work so I got another, bigger loan to buy more materials for a variety of
                                    bags. Thankfully the idea was successful and my business started doing well. Today, my
                                    house runs smoothly and my children are able to attend school which is all I could
                                    ever ask for."
                                </CustomTypography>

                                <CustomTypography className="pt-11"
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='lg:text-left text-center'
                                >
                                    Irshad Ahmed
                                </CustomTypography>
                            </Box>

                        </Box>

                    </Box>




                    {/** pic section */}

                    <Box className=" lg:py-60px lg:px-120px md:py-60px md:px-10 py-6 px-4 max-w-screen-2xl mx-auto ">

                        <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-120px md:gap-80px gap-10">

                            <Box className="flex lg:hidden  justify-center">

                                <img src={TabMicro2} alt="Microfancne" />

                            </Box>


                            <Box className="flex flex-col justify-center">
                                <CustomTypography className="pt-6"
                                    as='p' variant='p3' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                                >
                                    “The biggest benefit of having a stable business is not having to ask for
                                    money from anyone; which is very liberating. My journey with Akhuwat began
                                    when I borrowed an amount to buy material for the bags I make. Over the years
                                    I learnt that I had to add in more variety to my work so I got another,
                                    bigger loan to buy more materials for a variety of bags. Thankfully the idea
                                    was successful and my business started doing well. Today, my house runs
                                    smoothly and my children are able to attend school which is all I could
                                    ever ask for."
                                </CustomTypography>

                                <CustomTypography className="pt-11"
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='lg:text-left text-center'
                                >
                                    Ali Janjua
                                </CustomTypography>
                            </Box>



                            <Box className="lg:flex  hidden justify-start">

                                <img src={TabMicro2} alt="Microfancne" />

                            </Box>

                        </Box>

                    </Box>

                </Box>

            )}





            {/** Stories section */}

            <Box className="bg-paraLightGray ">
                <Box className="max-w-screen-2xl mx-auto lg:py-80px lg:px-120px md:py-[72px] md:px-8 py-6 px-4 ">



                    <CustomTypography
                        as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                    >
                        Akhuwat Stoires
                    </CustomTypography>


                    <CustomTypography
                        as='h2' variant='h2' color='text-DarkBlack' weightFont='font-bold' alignment='text-center'
                    >
                        More stories like these
                    </CustomTypography>

                </Box>
                <Box>

                    {/* Carasouel */}

                    <Box className="max-w-screen-2xl mx-auto lg:pb-120px md:pb-10 pb-6 pl-0 pr-0">

                        <CustomCarousel slides={slides} slideWidth={{
                            lg: 493,
                            md: 336,
                            sm: 310
                        }} gap={60}
                        />
                    </Box>
                </Box>
            </Box>




            <Box className="bg-customGray lg:py-60px lg:px-120px md:py-60px md:px-10 py-6 px-4 ">

                <Box className="max-w-screen-2xl mx-auto  grid lg:grid-cols-2 grid-cols-1 lg:gap-120px md:gap-80px gap-10">

                    <Box className="flex lg:justify-start justify-center">

                        <img src={TabMicro1} alt="Microfancne" />

                    </Box>


                    <Box className="flex flex-col justify-center">
                        <CustomTypography className="pt-6"
                            as='p' variant='p3' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            “The biggest benefit of having a stable business is not having to ask for money from
                            anyone; which is very liberating. My journey with Akhuwat began when I borrowed an amount
                            to buy material for the bags I make. Over the years I learnt that I had to add in more
                            variety to my work so I got another, bigger loan to buy more materials for a variety of
                            bags. Thankfully the idea was successful and my business started doing well. Today, my
                            house runs smoothly and my children are able to attend school which is all I could
                            ever ask for."
                        </CustomTypography>

                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Irshad Ahmed
                        </CustomTypography>
                    </Box>

                </Box>

            </Box>



            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] h-[500px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${MakVideo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='max-w-screen-2xl  flex flex-col justify-center items-center lg:items-start h-full 
                lg:px-120px px-4  lg:w-[700px] '>
                    <p className='font-manrope text-white font-normal lg:text-[18px] text-[12px] 
                    lg:leading-[27px] leading-[18px]'>
                        Akhuwat Health Services</p>

                    <CustomTypography className="lg:pt-4 md:pt-4 pt-3"
                        as="h1"
                        variant="h21"
                        color="text-white"
                        alignment="lg:text-left md:text-left text-center"

                    >
                        The Catastrophic Floods of 2022
                    </CustomTypography>
                    <Box className='pt-6'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>



            {/** pic section */}

            <Box className="bg-customGray lg:py-60px lg:px-120px md:py-60px md:px-10 py-6 px-4 ">

                <Box className="max-w-screen-2xl mx-auto lg:pb-120px md:pb-10 pb-6 pl-0 pr-0 grid lg:grid-cols-2 grid-cols-1 lg:gap-120px md:gap-80px gap-10">

                    <Box className="flex lg:hidden  justify-center">

                        <img src={TabMicro2} alt="Microfancne" />

                    </Box>


                    <Box className="flex flex-col justify-center">
                        <CustomTypography className="pt-6"
                            as='p' variant='p3' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            “The biggest benefit of having a stable business is not having to ask for
                            money from anyone; which is very liberating. My journey with Akhuwat began
                            when I borrowed an amount to buy material for the bags I make. Over the years
                            I learnt that I had to add in more variety to my work so I got another,
                            bigger loan to buy more materials for a variety of bags. Thankfully the idea
                            was successful and my business started doing well. Today, my house runs
                            smoothly and my children are able to attend school which is all I could
                            ever ask for."
                        </CustomTypography>

                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Ali Janjua
                        </CustomTypography>
                    </Box>



                    <Box className="lg:flex  hidden justify-start">

                        <img src={TabMicro2} alt="Microfancne" />

                    </Box>

                </Box>

            </Box>


            {/* Donate section */}


            <Box className="bg-customGreen lg:py-140px lg:px-16 md:py-60px md:px-10 py-6 px-4">

                <Box className="max-w-screen-2xl mx-auto bg-white lg:py-60px lg:px-[100px] rounded-[16px] md:py-10 md:px-10 py-5 px-5">

                    <CustomTypography
                        as='h2' variant='h2' color='text-customBlack' weightFont='font-bold' alignment='text-center'
                    >
                        Select an amount to donate
                    </CustomTypography>



                    <CustomTypography className="pt-5"

                        as='p'
                        variant='p2'
                        color="text-SlateGray"
                        weightFont='font-semibold'
                        alignment="text-center"

                    >
                        [Akhuwat recognizes four core values that support the spirit of Mawakhat: Iman,
                        Ihsan, Ikhlas, and Infaq—faith, excellence, sincerity, and giving.]
                    </CustomTypography>



                    <Box className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1
                    lg:gap-5 md:gap-5 gap-3 lg:pt-10 md:pt-10 pt-6">

                        <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
                         ${activeBtn === "Rs. 1000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
                            onClick={() => setActiveBtn("Rs. 1000")}>
                            <CustomTypography
                                as='p' variant='p3' color={` ${activeBtn === "Rs. 1000" ? "text-white" : "text-customBlack"}`}
                                weightFont='font-normal' alignment='text-center'
                            >
                                Rs. 1000
                            </CustomTypography>
                        </Box>



                        <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
                         ${activeBtn === "Rs. 2500" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
                            onClick={() => setActiveBtn("Rs. 2500")}>
                            <CustomTypography
                                as='p' variant='p3' color={` ${activeBtn === "Rs. 2500" ? "text-white" : "text-customBlack"}`}
                                weightFont='font-normal' alignment='text-center'
                            >
                                Rs. 2500
                            </CustomTypography>
                        </Box>

                        <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
                         ${activeBtn === "Rs. 5000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
                            onClick={() => setActiveBtn("Rs. 5000")}>
                            <CustomTypography
                                as='p' variant='p3' color={` ${activeBtn === "Rs. 5000" ? "text-white" : "text-customBlack"}`}
                                weightFont='font-normal' alignment='text-center'
                            >
                                Rs. 5000
                            </CustomTypography>
                        </Box>



                        <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
                         ${activeBtn === "Other amount" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
                            onClick={() => setActiveBtn("Other amount")}>
                            <CustomTypography
                                as='p' variant='p3' color={` ${activeBtn === "Other amount" ? "text-white" : "text-customBlack"}`}
                                weightFont='font-normal' alignment='text-center'
                            >
                                Other amount
                            </CustomTypography>
                        </Box>






                    </Box>

                    <Box className="flex justify-center lg:pt-10 md:pt-10 pt-6">
                        <CustomButton btnText="Donate" btnColor="white" btnBackground="customBlack" />

                    </Box>

                </Box>

            </Box>



        </Box>
    )
}

export default HopeStories