import React, { useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

function VideoPlay({ videoSrc, thumbnailPicture,roundedGrid='yes' }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      className={`lg:h-[680px] md:h-[395px] h-[200px] relative flex items-center justify-center
  ${roundedGrid==='yes' ? 'rounded-[40px]' : 'rounded-[0px]'}  overflow-hidden
  max-w-screen-2xl mx-auto`}
      style={{
        backgroundImage: !isPlaying ? `url(${thumbnailPicture})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
      }}
    >
      {/* Always render video element */}
      <video
        ref={videoRef}
        src={videoSrc}
        className="w-full h-full object-cover"
        style={{ display: isPlaying ? 'block' : 'none' }}
        onEnded={() => setIsPlaying(false)}
      />

      {/* Play/Pause icon overlay */}
      <Box
        onClick={handlePlayPause}
        className="absolute cursor-pointer bg-black bg-opacity-50 p-4 rounded-full flex items-center justify-center"
        style={{ width: '64px', height: '64px' }}
      >
        {isPlaying ? (
          <PauseIcon style={{ color: 'white', fontSize: '40px' }} />
        ) : (
          <PlayArrowIcon style={{ color: 'white', fontSize: '40px' }} />
        )}
      </Box>
    </Box>
  );
}

export default VideoPlay;
