import React from 'react';
import { Box } from '@material-ui/core';
import AkspRightUnion from "../pictures/aksp-eligible-unionR.png"
import AkspLeftUnion from "../pictures/aksp-eligible-union.png"
import AkspMemberIcon from "../pictures/aksp-member-icon.png"
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomTypography from '../CustomTypography';
import EligMob from "../pictures/aksp_elig_mobile.png"
import EligPhone from "../pictures/aksp_elig_phone.png"
import EligMail from "../pictures/aksp_elig_mail.png"

const AkspMembership = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (

        <Box className='bg-white lg:py-90px py-52px lg:px-120px  px-4'>
            {/* Membership section.... */}

            <Box className='max-w-screen-2xl mx-auto'>
                <h1 className='font-manrope lg:font-semibold font-bold text-[32px] lg:text-[55px] lg:leading-[66px] 
        text-center text-customGreen leading-[43.71px] '>
                    Membership Benefits for Khwajasiras
                </h1>

                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-10 pt-10'>
                    <Box className='bg-primaryLight rounded-[30px] lg:py-60px lg:px-60px py-8 px-5'>
                        <h1 className='text-customBlack font-manrope font-bold lg:text-[35px] 
                        lg:leading-[52.5px]  text-[24px] leading-[32.78px]'>Economic Rehabilitation</h1>

                        <Box className='pt-10'>
                            <p className='flex items-center font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Unique Akhuwat ID Card</span>
                            </p>
                            <p className='flex items-start lg:pt-6 pt-2 font-manrope font-medium lg:text-[24px] lg:leading-[32.78px]
                             text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Monthly Stipend</span>
                            </p>
                            <p className='flex items-start pt-6 font-manrope font-medium lg:text-[24px] lg:leading-[32.78px]
                             text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Financial Literacy </span>
                            </p>
                            <p className='flex items-start pt-6 font-manrope font-medium lg:text-[24px] lg:leading-[32.78px]
                             text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Capacity Building Trainings and Workshops </span>
                            </p>
                            <p className='flex items-start pt-6 font-manrope font-medium lg:text-[24px] lg:leading-[32.78px]
                             text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Career Counseling and Employment Opportunities </span>
                            </p>
                        </Box>
                    </Box>

                    {/* Card 2 */}


                    <Box className='bg-primaryLight rounded-[30px] lg:py-60px lg:px-60px py-8 px-5'>
                        <h1 className='text-customBlack font-manrope font-bold lg:text-[35px] 
                        lg:leading-[52.5px]  text-[24px] leading-[32.78px]'>Health Services</h1>

                        <Box className=' pt-10'>
                            <p className='flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Screening For HIV/Aids, Hepatitis B & C, Syphilis</span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Monthly Health Checkups </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Distribution of Free Medicines </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Psycho-Social Therapy </span>
                            </p>
                        </Box>
                    </Box>

                    {/* card 3 */}


                    <Box className='bg-primaryLight rounded-[30px] lg:py-60px lg:px-60px py-8 px-5'>
                        <h1 className='text-customBlack font-manrope font-bold lg:text-[35px] 
                        lg:leading-[52.5px]  text-[24px] leading-[32.78px]'>Community Dialogue</h1>

                        <Box className=' pt-10'>
                            <p className='flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Campaigns for Social Acceptance </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Monthly gatherings and motivational sessions</span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Peer Guidance Through Role Models  </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Games and Competitions (Poetry, Singing, Naat recitation, Mehndi design etc.) </span>
                            </p>
                        </Box>
                    </Box>


                    {/* card 4 */}


                    <Box className='bg-primaryLight rounded-[30px] lg:py-60px lg:px-60px py-8 px-5'>
                        <h1 className='text-customBlack font-manrope font-bold lg:text-[35px] 
                        lg:leading-[52.5px]  text-[24px] leading-[32.78px]'>Celebrations & Festivities </h1>

                        <Box className='pt-10'>
                            <p className='flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Eid-e-Milad-un-Nabi </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Pakistan Day: (23 March) </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Independence Day: (14 August)  </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Defense  Day: (06 September) </span>
                            </p>
                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg:mt-[3px]' />
                                <span>Christmas & Birth Anniversary of Quaid-e-Azam (25 December)</span>
                            </p>

                            <p className=' lg:pt-6 pt-2 flex items-start font-manrope lg:font-semibold font-medium lg:text-[23px] 
                            lg:leading-[34.5px] text-customBlack text-[16px] leading-[21.86px]'>
                                <img src={AkspMemberIcon} alt="icon" className='mr-[12px] lg;mt-[3px]' />
                                <span>Ramadan Iftar</span>
                            </p>
                        </Box>
                    </Box>

                </Box>
            </Box>


            {/* Eligiblity criteria */}

            <Box className='bg-customGreen lg:py-80px lg:px-80px md:py-60px px-3  py-6 md:px-6 mt-60px rounded-[30px]'
                style={{
                    backgroundImage: !isMobile ? `url(${AkspLeftUnion}),url(${AkspRightUnion}) ` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left top , right top',

                }}>
             
             <Box className='max-w-screen-2xl mx-auto'>

                <CustomTypography
                    as='h2' variant='h21' color='text-topYellow' weightFont='font-bold' alignment='text-center'
                >
                    Eligibility Criteria
                </CustomTypography>

                <CustomTypography className="pt-4"
                    as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                >
                    For Becoming a Members of AKSP
                </CustomTypography>


                <CustomTypography className='lg:pt-12 pt-6 ' as="p" variant="p2" color="text-white " weightFont="font-semibold"
                    alignment='text-center'
                >
                    Khwajasiras 40 years or older <br></br> <br></br>
                    Monthly income less than Rs.10,000
                </CustomTypography>

                <CustomTypography className="pt-4"
                    as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                >
                    Contact us for further details
                </CustomTypography>


                <Box className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:pt-12 md:pt-12 pt-6 
                lg:gap-0 md:gap-5 gap-5'>
                   
                <Box className='flex flex-col justify-center items-center lg:border-r lg:border-white
                md:border-r md:border-white border-b border-b-white lg:pb-0 md:pb-0 pb-4 
                md:border-b-[0px] lg:border-b-[0px]'>
                    <img src={EligMob} alt="mobile"/>

                    <CustomTypography className="pt-5"
                    as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                >
                    0308-144-8464
                </CustomTypography>

                </Box>

                <Box className='flex flex-col justify-center items-center lg:border-r lg:border-white
                md:border-r md:border-white border-b border-white lg:pb-0 md:pb-0 pb-4
                 md:border-b-[0px] lg:border-b-[0px]'>
                    <img src={EligPhone} alt="mobile"/>

                    <CustomTypography className="pt-5"
                    as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                >
                   042-35841221-2 ext 305 
                </CustomTypography>

                </Box>



                <Box className='flex flex-col justify-center items-center '>
                    <img src={EligMail} alt="mobile"/>

                    <CustomTypography className="pt-5"
                    as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                >
                    ksp@akhuwat.org.pk
                </CustomTypography>

                </Box>
                    <Box>

                    </Box>
                   </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default AkspMembership