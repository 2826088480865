import React from 'react';
import { Box } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import CustomTypography from '../CustomTypography';

const BannerDesign = ({ AkspLeftUnion, imageURL, headPart1, headPart2,
    backColor, belowHead, belowPara1, belowheadColor,
    belowParaColor, donateBtn }) => {

    
    const isMobile = useMediaQuery('(max-width:600px)');


    return (
        <Box>
            <Box
                className="lg:h-[905px] md:h-[480px] h-[271px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `
                        radial-gradient(58.96% 94.34% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 70%),
                        linear-gradient(180deg, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.6) 100%),
                        url(${imageURL})
                    `
                }}
            >
                <Box className="flex items-center justify-center w-full h-full absolute inset-0 text-center">


                    <CustomTypography
                        as='h1' variant='h1' color='text-white' weightFont='font-bold' alignment='text-center'
                    >
                        {headPart1} <br /> {headPart2}
                    </CustomTypography>
                </Box>
            </Box>

            {/* below banner section */}

            <Box className={` h-auto  bg-${backColor} lg:py-90px py-52px lg:px-120px px-4 
              `}
                style={{
                    backgroundImage: !isMobile ? `url(${AkspLeftUnion})` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom left',
                }}>
                <Box className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[0px] gap-5 max-w-screen-2xl mx-auto'>
                    <Box className='lg:w-[400px]'>
                        
                        <CustomTypography
                        as='h2' variant='h21' color={belowheadColor} weightFont='font-bold' alignment='lg:text-left md:text-left text-center'
                    >
                        {belowHead}
                    </CustomTypography>
                    </Box>
                    <Box >
                      

                        <CustomTypography
                        as='p' variant='p2' color={belowParaColor} weightFont='font-normal' alignment='lg:text-left md:text-left text-center'
                    >
                        {belowPara1}
                    </CustomTypography>
                        {/* showing button if required */}

                        {donateBtn && (
                            <Box className='flex lg:justify-start md:justify-start justify-center lg:pt-60px pt-7'>
                                <CustomButton btnText='Donate' btnURL='/donate' btnColor='white' btnBackground='customBlack' />
                            </Box>
                        )}

                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BannerDesign;
