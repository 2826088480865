import React, { Fragment, useState } from "react";
import axios from "axios";
import { loadUser } from "../Actions/userAction"; // Import loadUser action
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";
import LoadingSpinner from "../Loader";
import { Box } from "@material-ui/core";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);

const Login = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loading, setLoading] = useState(false); // Local loading state

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
   
      const { data } = await axios.post(
        `${API_BASE_URL}/api/v1/login`,
        { user_name: userName, password: loginPassword },
        {withCredentials: true}
      );
      console.log("This is data"+data)

      console.log("cokkie test "+data.token);


      // On successful login, call loadUser
      if (data.user && data.user.userid) {
        await dispatch(loadUser()); // Dispatch loadUser with userId
        alert.success("You have logged in successfully!"); // Show success message
        navigate("/dashboard"); // Navigate to dashboard
      }
    } catch (error) {
      // Handle login failure
      const errorMessage =
        error.response?.data?.error || "Login failed. Please try again.";
      alert.error(errorMessage);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="p-8 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
              Login
            </h2>
            <form onSubmit={loginSubmit} className="space-y-6">
              <div className="relative">
                <PersonIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Enter Username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-full pl-10 p-3 bg-gray-50 text-gray-700 border border-gray-300 rounded-md outline-none focus:border-blue-500 focus:bg-white transition-all"
                />
              </div>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="password"
                  placeholder="Enter password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  className="w-full pl-10 p-3 bg-gray-50 text-gray-700 border border-gray-300 rounded-md outline-none focus:border-blue-500 focus:bg-white transition-all"
                />
              </div>
              <button
                type="submit"
                className="w-full p-3 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-all flex items-center justify-center"
              >
                <SendIcon className="mr-2" /> Login
              </button>
            </form>
          </div>
        </Box>
      )}
    </Fragment>
  );
};

export default Login;
