import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";

const AhsServices = ({ icon, head, para, facilityCard, donateCard }) => {

    //component to create cards for services section.....

    return (
        <Box>

            {/* Showing cards for facility section */}
            {facilityCard && (
                <Box className="lg:w-[386.67px] md:w-[345px] w-full lg:h-[600px] md:h-[600px]  h-auto bg-white md:py-10 lg:py-60px lg:px-6 rounded-[20px] 
                flex flex-col items-center py-8 px-5 ">
                    <Box className="w-16 h-16 flex justify-center items-center">
                        <img className="w-full h-full object-contain" src={icon} alt="clinic icon" />
                    </Box>


                        <CustomTypography className="pt-4 lg:pt-5"
                    as='p' variant='p1' color='text-customGreen' weightFont='font-semibold'
                    alignment="text-center"
                >
                    {head}
                </CustomTypography>

                <CustomTypography className="pt-7 lg:pt-10"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                            alignment="text-center"

                        >
                            {para}
                        </CustomTypography>
                </Box>

            )}


            {donateCard && (
                <Box className="w-auto lg:h-[500px] md:h-[500px] h-auto bg-white lg:py-10 py-8 lg:px-6 px-5 
                rounded-[16px] flex flex-col items-center flex-grow border border-RubyRed">
                    <Box className="w-16 h-16 flex justify-center items-center">
                        <img className="w-full h-full object-contain" src={icon} alt="clinic icon" />
                    </Box>


                    <CustomTypography className="pt-4 lg:pt-5"
                    as='h3' variant='h3' color='text-customGreen' weightFont='font-semibold'
                    alignment="text-center"
                >
                    {head}
                </CustomTypography>

                <CustomTypography className="pt-7"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                            alignment="text-center"

                        >
                            {para}
                        </CustomTypography>
                </Box>
            )}




        </Box>
    )

}

export default AhsServices

