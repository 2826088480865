import React from 'react';
import { Box } from "@material-ui/core";
import CustomTypography from '../CustomTypography';

const AcbClothes = ({ processImg, ProcessText }) => {
  return (
    <Box className='lg:h-[417px] h-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-10 gap-3'>
      {/* showing and hiding image */}
      <Box className='lg:hidden md:hidden'>
        <img src={processImg} alt="process" />
      </Box>
      <Box>
       

        <CustomTypography
          as='h3' variant='h3' color='text-SlateGray' weightFont='font-medium'
        >
         {ProcessText}
        </CustomTypography>
      </Box>

      <Box className='lg:flex md:flex hidden'>
        <img src={processImg} alt="process" />
      </Box>

    </Box>
  )
}

export default AcbClothes