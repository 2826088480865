export const ALL_USER_REQUEST="ALL_USER_REQUEST"
export const ALL_USER_SUCCESS="ALL_USER_SUCCESS"
export const ALL_USER_FAIL="ALL_USER_FAIL"

export const NEW_USER_REQUEST="NEW_USER_REQUEST"
export const NEW_USER_SUCCESS="NEW_USER_SUCCESS"
export const NEW_USER_FAIL="NEW_USER_FAIL"

export const DETAIL_USER_REQUEST="DETAIL_USER_REQUEST"
export const DETAIL_USER_SUCCESS="DETAIL_USER_SUCCESS"
export const DETAIL_USER_FAIL="DETAIL_USER_FAIL"

export const LOAD_USER_REQUEST="LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS="LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL="LOAD_USER_FAIL"


export const UPDATE_PASSWORD_REQUEST="UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS="UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAIL="UPDATE_PASSWORD_FAIL"

export const ADMIN_USERS_REQUEST="ADMIN_USERS_REQUEST"
export const ADMIN_USERS_SUCCESS="ADMIN_USERS_SUCCESS"
export const ADMIN_USERS_FAIL="ADMIN_USERS_FAIL"

export const DELETE_USER_REQUEST= "DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"


export const LOGOUT_USER_SUCCESS="LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAIL="LOGOUT_USER_FAIL"

export const CLEAR_ERRORS="CLEAR_ERRORS"


export const ALL_ROLES_REQUEST="ALL_ROLES_REQUEST"
export const ALL_ROLES_SUCCESS="ALL_ROLES_SUCCESS"
export const ALL_ROLES_FAIL="ALL_ROLES_FAIL"

export const DELETE_ROLE_REQUEST="DELETE_ROLE_REQUEST"
export const DELETE_ROLE_SUCCESS="DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_FAIL="DELETE_ROLE_FAIL"



export const SINGLE_USER_REQUEST="SINGLE_USER_REQUEST"
export const SINGLE_USER_SUCCESS="SINGLE_USER_SUCCESS"
export const SINGLE_USER_FAIL="SINGLE_USER_FAIL"