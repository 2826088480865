import React from 'react';
import { Box } from '@mui/material';


const FaqLogos = ({ logoImg }) => {
    return (

        <Box>

            {/* Logo 1 */}
            <Box className=" flex justify-center items-center h-[95px] w-auto lg:bg-customGray bg-white rounded-[10px]">
                <img src={logoImg} alt="Partner 1" className="h-12 lg:h-auto" />
            </Box>

        </Box>
    )
}

export default FaqLogos;