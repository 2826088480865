// actions/jobActions.js
import axios from 'axios';
import {
  UPLOAD_JOBS_REQUEST, UPLOAD_JOBS_SUCCESS, UPLOAD_JOBS_FAIL,
  DELETE_JOBS_REQUEST, DELETE_JOBS_SUCCESS, DELETE_JOBS_FAIL,
  GET_JOBS_REQUEST, GET_JOBS_SUCCESS, GET_JOBS_FAIL,
  UPDATE_JOBS_REQUEST, UPDATE_JOBS_SUCCESS, UPDATE_JOBS_FAIL
} from '../Constants/JobConstants';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);

const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true, // Include cookies
};

export const getJobs = () => async (dispatch) => {


  try {

    dispatch({
        type: GET_JOBS_REQUEST
    })

   const {data}=await axios.get(`${API_BASE_URL}/api/v1/jobs`,config)
      
    dispatch({
        type:GET_JOBS_SUCCESS,
        payload:data
    })
    
} catch (error) {
    dispatch({
        type:GET_JOBS_FAIL,
        payload:error.response.data.message
    })
}
};

export const uploadJob = (title,description,jobType,jobLocation,jobGoogleLink,createdBy) => async (dispatch) => {
    console.log(title,description,jobType,jobLocation,jobGoogleLink,createdBy);
  try {

    dispatch({
        type:UPLOAD_JOBS_REQUEST
    })

    const { data } = await axios.post(`${API_BASE_URL}/api/v1/job/new`,{title,description,jobType,jobLocation
        ,jobGoogleLink,createdBy
    },config);
    


    dispatch({
        type:UPLOAD_JOBS_SUCCESS,
        payload:data
    })
    
} catch (error) {
    dispatch({
        type:UPLOAD_JOBS_FAIL,
        payload:error.response.data.message
    })
}
};



//delete a single user by admin
export const deleteJob=(id)=>async(dispatch)=>{
      
       
    try {
            dispatch({
                type:DELETE_JOBS_REQUEST
            })
         
        await axios.delete(`${API_BASE_URL}/api/v1/job/${id}`,config)
        
     

        dispatch({
            type:DELETE_JOBS_SUCCESS
        })
        
    } catch (error) {
      
         
      
        dispatch({
            type:DELETE_JOBS_FAIL,
            payload:error.response.data.error
        })
    
    }

 }
