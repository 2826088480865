import { Box } from "@material-ui/core";
import React from "react";
import AesBanner from '../pictures/Aes-banner.png'
import JoinImg from '../pictures/involved_img.png'
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import Shop1 from '../pictures/involved_shop1.png'
import Shop2 from '../pictures/involved_shop2.png'
import Shop3 from '../pictures/involved_shop3.png'
import Shop4 from '../pictures/involved_shop4.png'
import Shop5 from '../pictures/involved_shop5.png'

const GetInvolved = () => {
    return (
        <Box>

            <Box
                className="lg:min-h-[905px] h-[271px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `
                        radial-gradient(58.96% 94.34% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 70%),
                        linear-gradient(180deg, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.6) 100%),
                        url(${AesBanner})
                    `
                }}
            >
                <Box className="flex items-center justify-center w-full h-full absolute inset-0 text-center">


                    <CustomTypography
                        as='h1' variant='h1' color='text-white' weightFont='font-bold' alignment='text-center'
                    >
                        Get Involved
                    </CustomTypography>
                </Box>
            </Box>



            {/**  OUR Founder section */}

            <Box className="bg-MistyLilc h-auto lg:py-140px md:py-60px md:p-10 py-8 px-4 lg:px-120px">
                <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-2 grid-cols-1 rounded-[20px] overflow-hidden h-auto  lg:h-[620px]">
                    <Box>
                        <img className="lg:h-[620px] md:h-[434px] h-[251px] w-full object-cover " src={JoinImg} alt="founder" />
                    </Box>
                    <Box className="bg-customGray lg:pl-10 pl-5 lg:pr-[60px] pr-5 lg:py-80px py-8 flex flex-col justify-center">


                        <CustomTypography as="h2" variant="h2" color="text-customBlack "
                            alignment="lg:text-left text-center">
                            Join Us
                        </CustomTypography>

                        <Box className="lg:pt-10 pt-5">


                            <CustomTypography as="p" variant="p2" color="text-SlateGray " weightFont="font-normal"
                            >
                                Join the world’s best interest-free microfinance organization of its kind and help us work towards poverty alleviation
                            </CustomTypography>
                        </Box>
                        <Box className="flex justify-center lg:justify-start pt-8">
                            <CustomButton btnText="Read more" btnBackground="customBlack" btnColor="white"
                                alignment="lg:text-left text-center" />
                        </Box>
                    </Box>
                </Box>
            </Box>



            {/* Ways to suppport section */}


            <Box className="max-w-screen-2xl mx-auto lg:py-120px lg:px-120px  bg-white md:py-60px md:py-10 py-6 px-4">
                <CustomTypography as="h2" variant="h21" color="text-customBlack" weightFont="font-bold"
                    alignment="text-center" >
                    Ways to Support
                </CustomTypography>


                <CustomTypography className="lg:pt-10 md:pt-10 pt-6" as="p" variant="p2" color="text-SlateGray " weightFont="font-normal"
                    alignment="text-center"
                >
                    Akhuwat needs your support and donations to alleviate poverty, educate deserving
                    students, support Khwajasiras (transgender community), and provide relief measures
                    during natural disasters. Your donations enable people to earn their living and
                    prosper in their businesses, educate boys and girls from underprivileged families,
                    empower women and transgenders, build houses, and contribute to the overall social
                    development.
                </CustomTypography>



                <Box className="grid lg:grid-cols-2 grid-cols-1 gap-10 pt-80px">

                    <Box className="lg:py-[50px] lg:px-[50px] md:py-60px md:px-10 py-6 px-4 border border-topGreen rounded-[40px]
                     lg:h-[670px] h-auto flex flex-col justify-between">

                        <CustomTypography as="h3" variant="h3" color="text-customGreen " weightFont="font-medium"
                            alignment="text-left"
                        >
                            Fundraise at college/university
                        </CustomTypography>



                        <CustomTypography className="md:pt-6 lg:pt-0 pt-6" as="p" variant="p2" color="text-SlateGray" weightFont="font-normal"
                            alignment="text-left"
                        >
                            Whether you’re a teacher or student, you can set up a fundraiser within your
                            college & university. You can gather old spare and new clothes, shoes,
                            accessories, and donate them to Akhuwat Clothes Bank (ACB). You can also gather
                            cash, write down the names of donors, and then donate them to Akhuwat either at
                            the office or online.
                        </CustomTypography>

                        <Box className="flex justify-start md:pt-6 lg:pt-0 pt-6 ">
                            <CustomButton btnText="Donate" btnColor="white" btnBackground="customBlack" />

                        </Box>


                    </Box>



                    <Box className="lg:py-[50px] lg:px-[50px] md:py-60px md:px-10 py-6 px-4 border border-topGreen rounded-[40px]
                     lg:h-[670px] h-auto flex flex-col justify-between">

                        <CustomTypography as="h3" variant="h3" color="text-customGreen " weightFont="font-medium"
                            alignment="text-left"
                        >
                            Fundraise at college/university
                        </CustomTypography>



                        <CustomTypography className="md:pt-6 lg:pt-0 pt-6" as="p" variant="p2" color="text-SlateGray" weightFont="font-normal"
                            alignment="text-left"
                        >
                            Whether you’re a teacher or student, you can set up a fundraiser within your
                            college & university. You can gather old spare and new clothes, shoes,
                            accessories, and donate them to Akhuwat Clothes Bank (ACB). You can also gather
                            cash, write down the names of donors, and then donate them to Akhuwat either at
                            the office or online.
                        </CustomTypography>

                        <Box className="flex justify-start md:pt-6 lg:pt-0 pt-6">
                            <CustomButton btnText="Donate" btnColor="white" btnBackground="customBlack" />

                        </Box>


                    </Box>

                </Box>




                {/* Donate section */}

                <Box className="lg:mt-10 md:mt-10 mt-6 bg-customGreen lg:py-80px lg:px-80px rounded-[40px] md:py-60px md:px-10 py-6 px-4">

                    <CustomTypography as="h3" variant="h3" color="text-topYellow " weightFont="font-medium"
                        alignment="text-center"
                    >
                        Fundraise at college/university
                    </CustomTypography>


                    <CustomTypography className="pt-[56px] " as="p" variant="p2" color="text-white"
                        weightFont="font-semibold" alignment="text-center">
                        Our donation page regularly runs campaigns that support a range of initiatives, ranging from Zakat
                        & Sadqa to donations for different programs and relief activities, undertaken by Akhuwat.
                    </CustomTypography>


                    <Box className="pt-[56px] flex justify-center">
                        <CustomButton btnText="Donate" btnColor="customBlack" btnBackground="white" />
                    </Box>

                </Box>

            </Box>


            {/* Shop section */}
               <Box className=" bg-customGray">
            <Box className="lg:py-120px lg:px-120px max-w-screen-2xl mx-auto  md:py-60px  py-6 px-4">

                <Box className="flex lg:flex-row flex-col justify-between">

                    <Box className="lg:w-[740px] w-auto">


                        <Box className="bg-MistyLilc rounded-full py-[6px] px-5 flex justify-start w-[130px]">
                            <h1 className="font-manrope font-semibold text-[12px] leading-[17px]
                           text-blackColor">coming soon</h1>
                        </Box>

                        <CustomTypography className="pt-3" as="h2" variant="h21" color="text-blackColor" weightFont="font-bold"
                            alignment="text-left" >
                            Shop at Our Store
                        </CustomTypography>


                        <CustomTypography className="pt-3" as="h3" variant="h3" color="text-customBlack" weightFont="font-medium"
                            alignment="text-left"
                        >
                            Every Purchase at Our Store Supports Akhuwat’s Vision of Poverty-alleviation
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-start items-center md:pt-12 pt-6 lg:pt-0">
                        <CustomButton btnText="Go to shop" btnColor="white" btnBackground="customBlack" />
                    </Box>

                </Box>


                {/* Picture section */}
                <Box className="grid lg:grid-cols-[682px_auto] grid-cols-1 gap-5 pt-80px" >

                    <Box className="lg:max-w-[682px] w-auto grid grid-cols-1 gap-5">

                        <Box className="grid grid-cols-[186px_auto] gap-5">

                            <Box className="lg:w-[186px]">
                                <img
                                    src={Shop1}
                                    alt="shop1"
                                    className="h-[301px] lg:w-[186px] w-full object-cover rounded-[18px]"
                                />
                            </Box>


                            <Box >
                                <img
                                    src={Shop2}
                                    alt="shop2"
                                    className="h-[301px] lg:w-[476px] w-full object-cover rounded-[18px]"
                                />
                            </Box>

                        </Box>


                        <Box className="grid grid-cols-[auto_186px] gap-5">

                            <Box >
                                <img
                                    src={Shop3}
                                    alt="shop1"
                                    className="h-[301px] lg:w-[476px]  w-full object-cover rounded-[18px]"
                                />
                            </Box>


                            <Box className="lg:w-[186px]" >
                                <img
                                    src={Shop4}
                                    alt="shop2"
                                    className="lg:h-[301px] lg:w-[186px]  w-full object-cover rounded-[18px]"
                                />
                            </Box>

                        </Box>

                   

                    </Box>

                    <Box >
                        <img src={Shop5} alt="shop1" className="lg:h-[622px] h-[226px] w-full  object-cover rounded-[18px]" />
                    </Box>

                </Box>
                   </Box>

            </Box>


            {/*Community section */}

            <Box className="lg:py-120px lg:px-120px  bg-primaryLight md:py-60px  py-6 px-4">

                <Box className="max-w-screen-2xl mx-auto bg-customGreen lg:py-16 lg:px-120px md:py-16 md:px-120px py-52px px-6
                flex lg:flex-row flex-col justify-between rounded-[30px]">

                    <CustomTypography as="h3" variant="h3" color="text-white" weightFont="font-medium"
                        alignment="lg:text-left text-center"
                    >
                        Join Akhuwat Community
                    </CustomTypography>


                    <Box className="flex lg:justify-end justify-center md:pt-10 pt-10 lg:pt-0">
                        <CustomButton btnText="Subscribe" btnColor="customBlack" btnBackground="white" />
                    </Box>

                </Box>

            </Box>


        </Box>
    )
}

export default GetInvolved