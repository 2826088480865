import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import british from "./pictures/british.png";
import gov from "./pictures/gov-logo.png";
import ikf from "./pictures/ikf.png";
import partner from "./pictures/partner3.png";
import ukAid from "./pictures/uk-aid.png";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomTypography from './CustomTypography';
import FaqLogos from './FaqLogos';
import FaqCarasouel from './FaqCarasouel';





const OurPartener = () => {






  const slides = [
    <FaqLogos
      logoImg={british}
    />,
    <FaqLogos
      logoImg={ukAid}
    />,
    <FaqLogos
      logoImg={partner}
    />,
    <FaqLogos
      logoImg={ikf}
    />,
    <FaqLogos
      logoImg={gov}
    />
  ]



  return (
    <Box>

      {/* Our partener section  desktop */}
      <Box className="hidden lg:block partner lg:h-[336px] lg:py-[140px] lg:px-[120px] bg-customGray h-[650px] py-12">
     
      <Box className=' max-w-screen-2xl mx-auto'>    {/* implemented max width */}      
        <Box className="lg:flex lg:items-center lg:gap-8">
          <Box className="lg:w-1/4 flex items-center justify-center mb-8 lg:mb-0">

            <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-bold">
              Our Partners
            </CustomTypography>
          </Box>
          <Box className="lg:w-3/4 lg:pl-8">
          <FaqCarasouel slides={slides} slideWidth={{
                        lg: 140,
                        md: 158,
                        sm: 158
                    }} gap={32}

                    />
          </Box>
        </Box>
      </Box>


      {/*parther section logos  mobile*/}

      <Box className='lg:hidden bg-[#F7F7F7] py-10 h-auto'>
        <Box className="lg:w-1/4 flex items-center justify-center mb-8 ">
          <h2 className="text-2xl  font-bold font-manrope leading-14  text-center text-gray-700">
            Our Partners
          </h2>
        </Box>
        <Box className="pt-80px relative md:px-120px px-0 ">
    
        <FaqCarasouel slides={slides} slideWidth={{
                        lg: 158,
                        md: 158,
                        sm: 158
                    }} gap={6}

                    />
         
        </Box>
      </Box>
      </Box>
    </Box>
  );
}

export default OurPartener