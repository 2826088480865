import React from 'react';
import { Box } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton from './CustomButton';
import CustomTypography from '../CustomTypography';
import patternLeft from '../pictures/aim-left-pattern.png'
import patternRight from '../pictures/aim-right-pattern.png'

const DonateNowSetion = ({leftPattern=patternLeft,rightPattern=patternRight,backColor,Head6,Head2,HeadColor6,HeadColor2}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




    return (


        <Box
            className={`h-auto lg:py-[128px] py-16 lg:px-120px px-5 md:px-10 relative flex 
                flex-col items-center justify-center text-center bg-${backColor}` }
        >
            {/* Background Ellipses */}
            <Box
                className="absolute inset-0"
                style={{
                    backgroundImage: !isMobile ? `url(${leftPattern}), url(${rightPattern})` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom left ,bottom right',

                }}
            />
            {/* Content */}
            <Box className="relative z-10">
           
                <CustomTypography
                            as='p' variant='p3' color={HeadColor6} weightFont='font-semibold'
                            alignment='text-center'
                        >
                           {Head6}
                        </CustomTypography>

                <CustomTypography className='pt-5' as="h2" variant="h2" color={HeadColor2} weightFont="font-bold"
                alignment='text-center'>
                {Head2}
          </CustomTypography>

                <Box className="pt-5 flex justify-center">
                    <CustomButton btnText='Donate' btnColor='customBlack' btnBackground='white' />
                </Box>
            </Box>
        </Box>
    )

}

export default DonateNowSetion