import { Box } from "@material-ui/core";
import React, { useState } from 'react';
import DisBanner from "../pictures/disaster-banner.png";
import DisPattern from "../pictures/disaster-pattern.png";
import BannerDesign from "../AES_AIM/BannerDesign";
import CustomButton from "../ReusableComponents/CustomButton";
import ReliefIcon1 from "../pictures/disaster-relief-icon1.png";
import ReliefIcon2 from "../pictures/disaster-relief-icon2.png";
import ReliefIcon3 from "../pictures/disaster-relief-icon3.png";
import ReliefIcon4 from "../pictures/disaster-relief-icon4.png";
import ReliefIcon5 from "../pictures/disaster-relief-icon5.png";
import ReliefIcon6 from "../pictures/disaster-relief-icon6.png";
import Story1 from "../pictures/disaster-stories-img.png";
import Relief1 from "../pictures/disaster-relief-img1.png";
import Relief2 from "../pictures/relief-img2.png";
import AhsStories from "../ACB_AHS/AhsStories";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ReliefTestimonials from "./ReliefTestimonials";
import CustomCarousel from "../CustomCarasouel";
import CustomTypography from "../CustomTypography";
import AcbFaq from "../ACB_AHS/AcbFaq";


const Disaster = () => {

    const [currentSlideStories, setCurrentSlidestories] = useState(0);//stories
    const totalSlides = 4, totalSlidesStories = 4;
    //disaster response page

    const [activeTab, setActiveTab] = useState("COVID-19");



    const handleNextStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrevStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };

    const handleChangeSlideStories = (index) => {
        setCurrentSlidestories(index); // Update current slide index when a button is clicked
    };


    const slides = [
        <ReliefTestimonials
            reliefImg={Relief1}
            reliefTitle="45,000+ Families"
            reliefDesc="Akhuwat provided cash assistance, averaging PKR 5,000 per family to help them buy food, water, and medicines."
        />,
        <ReliefTestimonials
            reliefImg={Relief2}
            reliefTitle="14,000+ Ration Bags"
            reliefDesc="The rations bags contained rice, lentils, wheat, spices, and cooking oil, which lasted three weeks for a family of six members."
        />,
        <ReliefTestimonials
            reliefImg={Relief1}
            reliefTitle="2,000+ Tents"
            reliefDesc="Waterproof tents served as temporary shelters against extreme weather and served as storage points for essential food items and harvested crops that could be saved. "
        />,
        <ReliefTestimonials
            reliefImg={Relief2}
            reliefTitle="2,000+ Tents"
            reliefDesc="Waterproof tents served as temporary shelters against extreme weather and served as storage points for essential food items and harvested crops that could be saved. "
        />
    ];







    return (
        <Box>
            {/* Banner section */}
            <Box>
                <BannerDesign imageURL={DisBanner} headPart1={'Disaster Response'}
                    belowHead={'Disaster Response'}
                    belowPara1="Akhuwat is committed to helping people out in times of crises, whether it 
                involves financial stress, inability to pursue education due to monetary reasons, or
                 natural disasters. Akhuwat Relief and Disaster Management Program specifically works 
                 in times of natural disasters, such as earthquake, floods, and COVID-19. "
                    belowheadColor="text-Disaster"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={DisPattern}
                    donateBtn="donate"
                />
            </Box>

            {/* Tabs section.. */}
            <Box className="bg-customGreen">
                <Box className="max-w-screen-2xl mx-auto  lg:pt-10 lg:pb-60px lg:px-120px pt-4 pb-5 px-4 grid grid-cols-2">

                    <Box
                        className={`pb-[10px] border-b-[3px] cursor-pointer ${activeTab === "COVID-19" ? "border-MintGreen" : "border-white"}`}
                        onClick={() => setActiveTab("COVID-19")}
                    >
                        <h6 className={`font-manrope lg:font-semibold lg:text-[23px] lg:leading-[34.5px] text-center text-[16px] leading-[20px] ${activeTab === "COVID-19" ? "text-MintGreen" : "text-white"}`}>
                            COVID-19
                        </h6>
                    </Box>

                    {/* Tab 2: International Chapters */}
                    <Box
                        className={`pb-[10px] border-b-[3px] cursor-pointer ${activeTab === "2022 Floods" ? "border-MintGreen" : "border-white"}`}
                        onClick={() => setActiveTab("2022 Floods")}
                    >
                        <h6 className={`font-manrope lg:font-semibold lg:text-[23px] lg:leading-[34.5px] text-center text-[16px] leading-[20px] ${activeTab === "2022 Floods" ? "text-MintGreen" : "text-white"}`}>
                            2022 Floods
                        </h6>
                    </Box>
                </Box>
            </Box>

            <Box className="bg-white lg:p-[128px] md:p-10  p-4 max-w-screen-2xl mx-auto">

                {activeTab === "COVID-19" ? (
                    <Box className="lg:h-[680px] md:h-[396px] h-[325px] bg-customGray lg:rounded-[30px] 
                    md:rounded-[24px] rounded-[16px] flex flex-col justify-center items-center">

                        <h1 className="font-manrope text-customBlack font-bold lg:text-[68.66px]
                         lg:leading-[82.4px] tracking-[-3%] md:text-[44.5px] md:leading-[53.4px]
                         text-center text-[28.38px] leading-[34.06px]">Covid</h1>

                        <p className="font-manrope font-normal lg:text-[28.13px] lg:leading-[39.37px] text-center
                         text-SlateGray pt-4 md:text-[21.88px] md:leading-[30.63px] lg:text-[17.72px]
                          lg:leading-[24.81px] lg:w-[507px] md:w-[507px] w-[250px]   ">
                            Stories of Resilience And Rebuilding After the 2022 Floods</p>

                        <Box className="flex justify-center items-center lg:pt-10 md:pt-10 pt-6">
                            <CustomButton btnText="Buy now" btnColor="white" btnBackground="customBlack"
                                btnURL="/buy" />
                        </Box>

                    </Box>
                ) : (


                    <Box className="lg:h-[680px] md:h-[396px] h-[325px] bg-customGray lg:rounded-[30px] 
                    md:rounded-[24px] rounded-[16px] flex flex-col justify-center items-center">

                        <CustomTypography
                            as="h1"
                            variant="h1"
                            alignment="text-center"
                            color="text-customBlack"
                        >
                            Makhwat
                        </CustomTypography>

                        <p className="font-manrope font-normal lg:text-[28.13px] lg:leading-[39.37px] text-center
                         text-SlateGray pt-4 md:text-[21.88px] md:leading-[30.63px] 
                          lg:w-[507px] md:w-[507px] w-[250px]   ">
                            Stories of Resilience And Rebuilding After the 2022 Floods</p>

                        <Box className="flex justify-center items-center lg:pt-10 md:pt-10 pt-6">
                            <CustomButton btnText="Buy now" btnColor="white" btnBackground="customBlack"
                                btnURL="/buy" />
                        </Box>

                    </Box>
                )}

            </Box>


            {/* Our relief effort section */}
            
                <Box className="bg-PowderColor lg:py-120px md:py-10  py-4 ">
                    <Box className="max-w-screen-2xl mx-auto">
                    <CustomCarousel slides={slides} slideWidth={{ lg: 493, md: 336, sm: 284 }}
                        gap={40} sliderTitle="Our Relief Efforts"
                    />
                    </Box>
                </Box>
               
      


            {/* Stories section */}

            <Box className="bg-white lg:p-[128px] md:p-10  p-4 max-w-screen-2xl mx-auto ">

                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1'>
                    <Box className='flex lg:justify-start md:justify-start justify-center items-center'>

                        <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-bold"
                            alignment="lg:text-left md:text-left text-center">
                            AHS Stories
                        </CustomTypography>
                    </Box>


                    <Box className='col-span-1 lg:flex md:flex hidden items-center justify-end '>
                        <Box
                            onClick={handlePrevStories}
                            className={`cursor-pointer ${currentSlideStories === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentSlideStories === 0 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowLeftIcon className={` ${currentSlideStories === 0 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                        <Box
                            onClick={handleNextStories}
                            className={`ml-4 cursor-pointer ${currentSlideStories === totalSlidesStories - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                            style={{ borderColor: totalSlidesStories === totalSlidesStories - 1 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowRightIcon className={`${currentSlideStories === totalSlidesStories - 1 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className='lg:pt-12 pt-8  '>

                    <Carousel className='md:hidden hidden lg:block'
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >

                        {/* Different view for mobile and desktop */}

                        <AhsStories topHead="I am very grateful to the brotherhood who held our hands 
                        when there was no other support." belowHead="Sanam"
                            gridImage={Story1}
                            btnCondt="yes"
                            btnText='Disaster Response'
                            textColor='text-customBlack'
                            gridColor='SoftVanila'
                            btnColor='customBlack' />



                    </Carousel>


                    {/* For mobile */}

                    <Carousel className='lg:hidden  '
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >



                        <Box className='rounded-[30px] overflow-hidden'>
                            <AhsStories gridImage={Story1} />
                            <AhsStories topHead="I am very grateful to the brotherhood who held our hands 
                        when there was no other support." belowHead="Sanam"
                                btnCondt="yes"
                                btnText='Disaster Response'
                                textColor='text-customBlack'
                                gridColor='SoftVanila'
                                btnColor='customBlack'

                            />



                        </Box>






                    </Carousel>

                    {/* Navigation Buttons */}
                    <Box className="lg:hidden md:hidden flex justify-center pt-8">
                        <button
                            onClick={() => handleChangeSlideStories(0)}
                            className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideStories === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(1)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full focus:outline-none  ${currentSlideStories === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(2)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full   ${setCurrentSlidestories === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>


                    </Box>

                </Box>





            </Box>


            {/* How we work */}

            <Box className="bg-white lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-[18px]
            max-w-screen-2xl mx-auto">

                <CustomTypography
                    as="h2"
                    variant="h2"
                    color="text-customBlack"
                >
                    How We Work
                </CustomTypography>

                <Box className="lg:pt-[96px] pt-10">
                    <AcbFaq FaqDisaster="disaster" />
                </Box>

            </Box>


            {/* Our relief section */}

            <Box className="bg-customGreen">
                <Box className="max-w-screen-2xl mx-auto  lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-[18px]
            grid lg:grid-cols-2 lg:gap-120px md:gap-[72px] gap-8">

                    <Box>
                        <CustomTypography as="h2" variant="h2" color="text-MintGreen" weightFont="font-bold">
                            Our Relief Efforts
                        </CustomTypography>

                        <CustomTypography className="pt-6 lg:pt-10 md:pt-10 " as="p" variant="p2" color="text-white"
                            weightFont="font-normal" >
                            The COVID-19 pandemic adversely impacted economic activity, increasing
                            unemployment and In order to provide relief to the most vulnerable, Akhuwat
                            Corona Support Fund was established with the objective of providing prepared
                            meals and ration packages to food-insecure families, emergency relief loans and
                            grants to support declining economic activity and medical support to bolster
                            hospitals and medical staff.
                        </CustomTypography>

                    </Box>

                    <Box>
                        <Box className="flex  lg:pb-6 md:pb-6 pb-4 border-b border-b-topGreen ">
                            <img src={ReliefIcon1} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Corona Relief Loans & Grants
                            </CustomTypography>


                        </Box>

                        <Box className="flex  lg:py-6 md:py-6 py-4 border-b border-b-topGreen ">
                            <img src={ReliefIcon2} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Free COVID-19 Testing
                            </CustomTypography>

                        </Box>

                        <Box className="flex  lg:py-6 md:py-6 py-4 border-b border-b-topGreen ">
                            <img src={ReliefIcon3} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Ration bags & Groceries
                            </CustomTypography>


                        </Box>


                        <Box className="flex  lg:py-6 md:py-6 py-4 border-b border-b-topGreen ">
                            <img src={ReliefIcon4} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Fresh meals
                            </CustomTypography>

                        </Box>


                        <Box className="flex  lg:py-6 md:py-6 py-4 border-b border-b-topGreen ">
                            <img src={ReliefIcon5} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Professional Medical Helpline
                            </CustomTypography>


                        </Box>


                        <Box className="flex  lg:py-6 md:py-6 py-4">
                            <img src={ReliefIcon6} alt="corona" />

                            <CustomTypography className="lg:pl-6 md:pl-6 pl-3 " as="p" variant="p2" color="text-white"
                                weightFont="font-normal" >
                                Financial Support to Hospitals
                            </CustomTypography>


                        </Box>
                    </Box>
                </Box>
            </Box>



        </Box>
    )
}


export default Disaster;