import { Box } from "@material-ui/core";
import React from 'react';

const ReliefTestimonials=({reliefImg,reliefTitle,reliefDesc})=>{


    return(
        <Box >
          <img src={reliefImg} alt="Relief" />
            
            <h3 className="pt-[30px] font-manrope font-semibold lg:text-[35.16px] lg:leading-[49.22px] tracking-[-2%]
            text-customBlack md:text-[26.02px] md:leading-[36.43px] text-[19.93px] leading-[23.92px] ">
                {reliefTitle}
            </h3>

            <p className="lg:pt-8 md:pt-8 pt-5 font-manrope  lg:text-[18px] lg:leading-[25.2px] font-normal text-SlateGray 
            md:text-[15.66px] md:leading-[21.92px] text-[14px] leading-[22.4px]"> 
                {reliefDesc} </p>

        </Box>
    )
}
export default ReliefTestimonials