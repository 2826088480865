import { Box } from "@material-ui/core";
import React from "react";
import Banner from "../pictures/Active_banner.png";
import CustomTypography from "../CustomTypography";
import ActiveTestimonials from "./ActiveTestimonials";
import Obj1 from "../pictures/Active_obj1.png";
import Obj2 from "../pictures/Active_obj2.png";
import CustomCarousel from "../CustomCarasouel";
import CustomButton from "../ReusableComponents/CustomButton";
import ActiveJobCategories from "./ActiveJobCategories";
import CustomerJob from "../pictures/Active_job1.png";
import TechJob from "../pictures/Active_job2.png";
import DesignJob from "../pictures/Active_job3.png";
import MarketJob from "../pictures/Active_job4.png";
import FacilitlyPic from "../pictures/Active_facility.png";
import FounderIconArrow from "../pictures/founder-arrow-icon.png";
import { Link } from "react-router-dom";


const AkhuwatActive = () => {



    const slides = [
        <ActiveTestimonials
            storyImg={Obj1}
            storyHead="Provision"
            storyDesc="To provide the training centres under ACITVE (Akhuwat Centre for Information Technology & Vocational Education) with the required infrastructure and resources for optimal operations. "
        />,
        <ActiveTestimonials
            storyImg={Obj2}
            storyHead="Programmes"
            storyDesc="To develop programmes and training as per the current global requirements, leading to national and international certifications. "
        />,
        <ActiveTestimonials
            storyImg={Obj1}
            storyHead="Programmes"
            storyDesc="To develop programmes and training as per the current global requirements, leading to national and international certifications. "
        />,

    ]


    const Jobs = [
        <ActiveJobCategories
            JobImg={CustomerJob}
            JobTitle="Customer Service Jobs"
            jobType1="Virtual Support"
            jobType2="Virtual Assistance "
            jobType3="Data Entry "
            jobType4="Digital Project Management"
            jobType5="General Research Services "

        />,
        <ActiveJobCategories
            JobImg={TechJob}
            JobTitle="Tech Jobs"
            jobType1="Full Stack Development"
            jobType2="Front-End Development"
            jobType3="Back-End Development"
            jobType4="Mobile App Development"
            jobType5="Web-Design"

        />,
        <ActiveJobCategories
            JobImg={DesignJob}
            JobTitle="Design Jobs"
            jobType1="Graphic Design"
            jobType2="Video Editing "
            jobType3="Illustration "
            jobType4="3D Animation "
            jobType5="Presentation Design "

        />,

        <ActiveJobCategories
            JobImg={MarketJob}
            JobTitle="Marketing Jobs"
            jobType1="SEO"
            jobType2="Social Media Marketing "
            jobType3="Digital Marketing "
            jobType4="Leads Generation"
            jobType5="Business Development "

        />,

    ]


    return (

        <Box>


            <Box
                className="lg:h-[900px] md:h-[426px] h-[245px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${Banner})
                `
                }}
            >

            </Box>


            {/** below banner */}

            <Box className="bg-white lg:py-[96px] lg:px-[128px] md:py-60px md:px-10 py-6 px-4
            max-w-screen-2xl mx-auto">

                <CustomTypography
                    as='h1' variant='h1' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Akhuwat Centre for Information Technology & Vocational Education (ACITVE)
                </CustomTypography>


                <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-normal'
                    alignment="text-center"
                >
                    ACITVE envisions being an innovative and up-to-date technical institute that
                    provides an educated and superior workforce instilled with the technical ability,
                    entrepreneurial mindset, and the spirit of Mawakhat (solidarity between the haves
                    and have-nots) that makes a positive impact on the economic and social development
                    of the entire country.

                </CustomTypography>

            </Box>



            {/** Mision section */}

            <Box className="bg-MistyLilc lg:py-120px lg:px-120px md:py-60px md:px-10 py-6 px-4">

                <Box className="grid lg:grid-cols-2 grid-cols-1 gap-[54px]
                max-w-screen-2xl mx-auto">
                    <Box className="bg-white lg:p-10 md:p-8 p-4 rounded-[24px]  lg:h-auto h-[225px]">

                        <CustomTypography
                            as='h2' variant='h2' color='text-SlateGray' weightFont='font-bold'
                        >
                            Vision
                        </CustomTypography>



                        <CustomTypography className="lg:pt-10 md:pt-5 pt-5"
                            as='p' variant='p2' color='text-SlateGray' weightFont='font-normal'

                        >
                            Building Futures by Building Skills

                        </CustomTypography>

                    </Box>




                    <Box className="bg-white lg:p-10 md:p-8 p-4 rounded-[24px]
                    lg:h-auto h-[225px]">

                        <CustomTypography
                            as='h2' variant='h2' color='text-SlateGray' weightFont='font-bold'
                        >
                            Mission
                        </CustomTypography>



                        <CustomTypography className="lg:pt-10 md:pt-5 pt-5"
                            as='p' variant='p2' color='text-SlateGray' weightFont='font-normal'

                        >
                            To deliver the highest quality technology training to enable youth to become
                            self-reliant through IT skills, employability training, and entrepreneurial
                            spirit to enhance their full potential and build a positive future

                        </CustomTypography>

                    </Box>

                </Box>

            </Box>


            {/** Objective section */}


            {/* Carasouel */}

            <Box className="lg:py-120px lg:px-120px md:py-60px md:px-10 py-6 px-4 bg-white ">

                <CustomCarousel slides={slides} slideWidth={{
                    lg: 590,
                    md: 455,
                    sm: 310
                }} gap={40}
                    sliderTitle="Objectives"
                />


                {/* Rationable section */}

                <Box className="lg:pt-60px pt-10">

                    <CustomTypography as="h2" variant="h2" color="text-SlateGray " weightFont="font-bold"

                    >
                        Rationale
                    </CustomTypography>

                    <Box className="lg:pt-60px pt-10">

                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >
                            Remote work has increased significantly since Covid
                        </CustomTypography>

                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            The size of the freelancer market valued at $ 3.8 Billion in 2021 is expected
                            to reach $ 12 Billion by 2028. The


                        </CustomTypography>


                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            Philippines (208%) is leading the growth in the freelancer market followed by
                            India (160%).


                        </CustomTypography>



                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            Pakistan is among the top 5 globally in registered freelancers over various
                            platforms but this number is not resulting in earnings.


                        </CustomTypography>

                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            This highlights a lack of skills offered by Pakistani freelancers.


                        </CustomTypography>


                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            The Pakistani freelancer may be enhanced by inculcating soft skills in
                            addition to a technology skill.


                        </CustomTypography>

                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            Targeted training should be offered in line with the requirements of today.

                        </CustomTypography>

                        <CustomTypography className="relative pl-4 before:content-['\2022'] before:absolute before:left-0 before:top-0 before:text-black"
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                        >

                            The training content should be reviewed annually to ensure relevance in the
                            market.

                        </CustomTypography>



                    </Box>

                </Box>
            </Box>



            {/* Freelancing job types */}


            <Box className="bg-PowderColor lg:py-120px lg:px-120px md:py-60px md:px-10 py-6 px-4">


                <CustomTypography as="h2" variant="h2" color="text-customBlack " weightFont="font-bold"
                    alignment="lg:text-left text-center"
                >
                    Top 5 Job Categories on freelancing platforms
                </CustomTypography>

                <Box className="lg:grid grid-cols-2 hidden pt-80px gap-10 ">
                    {Jobs}

                </Box>

                <Box className="md:pt-80px pt-10 lg:hidden">
                    <CustomCarousel slides={Jobs} slideWidth={{
                        lg: 590,
                        md: 331,
                        sm: 310
                    }} gap={20}

                    />
                </Box>

            </Box>



            {/* Current facilities */}

            <Box className="bg-white lg:pt-80px lg:pb-10 lg:px-120px md:pt-60px md:pb-60px md:px-10 pt-6 pb-6 px-4">

                <CustomTypography as="h2" variant="h2" color="text-customGreen" weightFont="font-bold"
                    alignment="text-center"
                >
                    Current Facilities

                </CustomTypography>


                <CustomTypography className="lg:pt-[72px] md:pt-10 pt-6"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold'

                >
                    ACITVE has four developed facilities which are fully equipped with IT infrastructure and
                    boarding quarters to run these programs. Our preference is to start off with the
                    flagship course where the students would be offered boarding and lodging in addition to
                    the training. This would allow ACITVE students to engross fully into the training and
                    mentoring in the areas of personal development, entrepreneurial mindset, employability
                    skills and their selected training skills.

                </CustomTypography>


                {/**pic section */}

                <Box className="lg:pt-16 pt-10">
                    <img src={FacilitlyPic} alt="tech job" />

                </Box>


                <Box className="lg:px-120px md:px-16 px-6">
                    <CustomTypography className="lg:pt-[72px] md:pt-10 pt-6"
                        as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold'

                    >
                        This program would then be expanded to limited-time in-person (day scholars) training
                         and online training programs in the form of MOOCs (Massive Open Online Courses) to 
                         reach the larger population. The current facilities are located in Faisalabad, 
                         Kasur, Lahore, Chakwal and Karachi; with the vision to expand in Sargodha, Lilla, 
                         Narowal and Multan soon.

                    </CustomTypography>

                    <Box className=" flex justify-start  pt-8">
                                <Link
                                    to="/about"
                                    className="flex items-center justify-center  "
                                >

                                    <CustomTypography
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                    >
                                    Apply for Admission 

                                    </CustomTypography>

                                    <img className='ml-2 relative w-6 h-6' src={FounderIconArrow} alt="arrow" />
                                </Link>
                            </Box>

                </Box>



            </Box>




            {/* Donate section */}

            <Box className="lg:mt-10 md:mt-10 mt-6 bg-customGreen lg:py-80px lg:px-80px  md:py-60px md:px-10 py-6 px-4">


                <CustomTypography
                    as='p' variant='p3' color='text-white' weightFont='font-semibold'
                    alignment="text-center"
                >
                    Akhuwat Education

                </CustomTypography>

                <CustomTypography className="pt-3" as="h2" variant="h2" color="text-MintGreen " weightFont="font-bold"
                    alignment="text-center"
                >
                    Support ACITVE
                </CustomTypography>


                <CustomTypography className="pt-5 " as="p" variant="p2" color="text-white"
                    weightFont="font-semibold" alignment="text-center">
                    Support Akhuwat ACITVE to empower our youth with cutting-edge IT skills and
                    entrepreneurial training and pave the way for their brighter future. Your donation will
                    help us provide essential resources, infrastructure, and opportunities to facilitate
                    them and transform their lives.
                </CustomTypography>


                <Box className="pt-7 flex justify-center">
                    <CustomButton btnText="Donate" btnColor="customBlack" btnBackground="white" />
                </Box>

            </Box>

        </Box>
    )


}

export default AkhuwatActive