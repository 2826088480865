import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBlogs } from "../Actions/BlogActions";
import CustomButton from "../ReusableComponents/CustomButton";

const Blogs = () => {
    const dispatch = useDispatch();
    const { blogs } = useSelector(state => state.allBlogs) || {};

    useEffect(() => {
        dispatch(getBlogs());
    }, [dispatch]);

    return (
        <Box className="lg:p-120px md:p-60px p-10">
            <h1 className="text-3xl font-bold mb-6">Blogs</h1>
            <Box className="grid lg:grid-cols-3 grid-cols-1 gap-5 pt-10">
                {blogs && blogs.length > 0 ? (
                    blogs.map(blog => (
                        <div key={blog.id} className="flex flex-col justify-between p-4 mb-4 border rounded shadow h-full">
                            <img src={blog.image} alt={blog.title} className="w-full h-[400px] mb-2 object-cover" />
                            <div className="flex flex-col flex-grow">
                                <h3 className="text-xl font-bold">{blog.title}</h3>
                                <p className="text-gray-600 mb-2">
                                    {blog.description.length > 30 
                                        ? `${blog.description.slice(0, 30)}...` 
                                        : blog.description}
                                </p>
                                <p className="text-sm text-gray-500">Created by: {blog.createdBy}</p>
                                <p className="text-sm text-gray-500">Created at: {new Date(blog.createdAt).toLocaleDateString()}</p>
                            </div>

                            {/* Ensuring the button is at the bottom of the card */}
                            <Box className="pt-4">
                                <CustomButton 
                                    btnText="Read More" 
                                    btnURL={`/blogs/${blog.id}`} 
                                    btnColor="white" 
                                    btnBackground="customBlack" 
                                />
                            </Box>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-gray-600">No blogs available.</p>
                )}
            </Box>
        </Box>
    );
};

export default Blogs;
