import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from '../Loader';
import CustomButton from '../ReusableComponents/CustomButton';
import { Box } from '@material-ui/core';
import { Sidebar } from './Sidebar'; // Import the Sidebar component
import backgroundImage from '../pictures/Active_facility.png';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true, // Include cookies
};

const AddRole = () => {
    const [role_name, setRole] = useState('');
    const [role_description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!role_name.trim()) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Role cannot be empty.',
            });
            return;
        }
        setLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/v1/role/new`, { role_name, role_description }, config);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Role added successfully!',
            });
            setRole(''); // Clear the input fields
            setDescription('');
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || 'Failed to add role. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display="flex" className="bg-white min-h-screen">
            {/* Sidebar */}
            <Box width="250px" className="bg-gray-100 shadow-lg">
                <Sidebar />
            </Box>

            {/* Main Content */}
            <Box flex={1} className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
                <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="w-full md:w-1/2 p-8">
                                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Add New Role</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="role">
                                            Add Role
                                        </label>
                                        <input
                                            type="text"
                                            id="role"
                                            value={role_name}
                                            onChange={(e) => setRole(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="role-description">
                                            Role Description
                                        </label>
                                        <input
                                            type="text"
                                            id="role-description"
                                            value={role_description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
                                    >
                                        Add Role
                                    </button>
                                </form>
                                <Box className="flex justify-center pt-10">
                                    <CustomButton btnURL="/admin/all/roles" btnText="Check All Roles" btnBackground="customBlack" />
                                </Box>
                            </div>

                            <div
                                className="hidden md:block md:w-1/2 bg-cover bg-center"
                                style={{ backgroundImage: `url(${backgroundImage})` }}
                            ></div>
                        </>
                    )}
                </div>
            </Box>
        </Box>
    );
};

export default AddRole;
