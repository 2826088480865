import { Box } from "@material-ui/core";
import React from "react";
import testimonial from "../pictures/kasur_testimonial1.png";
import CustomButton from "../ReusableComponents/CustomButton";
import CustomTypography from "../CustomTypography";

const KasurTestimonial = () => {
    return (
        <Box className="lg:h-[810px] h-[432px] flex flex-col justify-between"
            style={{
                backgroundImage: `
                radial-gradient(58.96% 94.34% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 70%),
                linear-gradient(180deg, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.6) 100%),
                url(${testimonial})
            `,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white'
            }}>

            {/* Button at the top */}
            <Box className="flex justify-start pt-10 px-6">
                <CustomButton 
                    btnText="Akhuwat College Kasur Campus facilities"
                    btnBackground="white" 
                    btnColor="customGreen" 
                />
            </Box>

            {/* Text content at the bottom */}
            <Box className="flex flex-col justify-center items-center pb-10 px-6
            ">
                <CustomTypography as="p" variant="p2" color="text-white"
                    weightFont="font-semibold" alignment="text-center">
                    Chemistry Lab
                </CustomTypography>

                <CustomTypography  className="pt-1 max-w-[560px]" as="p" variant="p2" color="text-white"
                    weightFont="font-normal" alignment="text-center">
                    In the Chemistry Lab, students perform hands-on experiments that complement their
                    theoretical knowledge.
                </CustomTypography>
            </Box>
        </Box>
    );
}

export default KasurTestimonial;
