import React from 'react';
import { Box } from '@material-ui/core';

const Testimonial = ({ personImage, bottomHead, para,personDetails}) => {


    return (
        <Box className='lg:p-6 rounded-[16px] bg-white '>
   
          <p className='text-left font-manrope font-normal text-[18px] leading-[27px]
           text-[#1D1D1F] '>{para}</p>
            
            <Box className='grid lg:grid-cols-[72px_auto] grid-cols-1 gap-4 pt-10'>
               <Box className='w-[72px]'>
                   <img src={personImage} alt="person"></img>
               </Box>
               <Box className='w-auto'>
                   <h2 className='text-left font-manrope  font-normal text-[18px] leading-[27px] 
                   text-topGreen'>
                    {bottomHead}
                   </h2>
                   <p className='text-left pt-[2px] font-manrope  font-normal text-[18px] 
                   leading-[27px] text-customBlack'>
                     {personDetails}
                   </p>
               </Box>
            </Box>
        </Box>
    )

}

export default Testimonial