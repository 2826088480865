import React, { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import heroVideo from "./pictures/hero_video.mp4";
import CountUp from "react-countup";
import LatestNews from "./LatestNews";
import Faq from "./Faq";
import Programs from "./Programs";
import HeroDonate from "./HeroDonate";
import { useMediaQuery, useTheme } from "@material-ui/core";
import NumberPattern from "./pictures/home-number-pattern.png";
import CustomTypography from "./CustomTypography";
import CustomButton from "./ReusableComponents/CustomButton";
import axios from "axios";

const Home = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [activeBtn, setActiveBtn] = useState("Rs. 1000");
  const [heroContent, setHeroContent] = useState("");
  const [interestNumber, setInterestNumber] = useState("");
  const [familyNumber, setFamilyNumber] = useState("");
  const [interestContent, setInterestContent] = useState("");
  const [familyContent, setFamilyContent] = useState("");
  const [founderImg, setFounderImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [countersVisible, setCountersVisible] = useState(false);

  const countersRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchDropdownData = async (shortNames) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/content/short/${shortNames}`
        );
        const contentData = response.data?.data?.[0]?.content || "Default Content";
        const interestData = response.data?.data?.[2]?.content || "Default Content";
        const familyData = response.data?.data?.[3]?.content || "Default Content";
        const interestContentData =
          response.data?.data?.[4]?.content || "Default Content";
        const familyContentData = response.data?.data?.[5]?.content || "Default Content";
        const founderContentData =
          response.data?.data?.[1]?.page_image || "Default Content";
        setHeroContent(contentData);
        setInterestNumber(interestData);
        setFamilyNumber(familyData);
        setInterestContent(interestContentData);
        setFamilyContent(familyContentData);
        setFounderImg(founderContentData);
      } catch (error) {
        console.error("Error fetching content data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDropdownData(
      "hero_head,interest-free,family,interest-content,families-content,founder_img"
    );
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCountersVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    if (countersRef.current) {
      observer.observe(countersRef.current);
    }

    return () => {
      if (countersRef.current) {
        observer.unobserve(countersRef.current);
      }
    };
  }, []);

  return (
    <Box>
      {/* Hero Section */}
      <Box className="h-auto pt-3 lg:px-16 md:px-10 px-4 lg:pb-60px pb-6">
        <Box className="lg:hidden hero-content-mobile mb-4">
          <CustomTypography
            as="h1"
            variant="h1"
            color="text-customBlack"
            weightFont="font-semibold"
            alignment="text-center"
          >
            {loading ? "Loading..." : heroContent}
          </CustomTypography>
        </Box>
        <Box
          className="lg:h-[682px] md:h-[301px] w-full h-[163px] relative lg:rounded-[30px] rounded-[15px] lg:py-[60px] py-[0px] overflow-hidden
        max-w-screen-2xl mx-auto"
        >
          <video
            autoPlay
            loop
            muted
            className="absolute top-0 left-0 w-full h-full object-cover z-0"
          >
            <source src={heroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box className="z-1 absolute bottom-0 left-0 w-full lg:grid grid-cols-2 hidden p-60px gap-10">
            <Box className="flex items-end">
              <CustomTypography
                as="h1"
                variant="h1"
                color="text-white"
                weightFont="font-semibold"
              >
                {loading ? "Loading..." : heroContent}
              </CustomTypography>
            </Box>
            <Box className="flex items-end justify-end">
              <HeroDonate />
            </Box>
          </Box>
        </Box>

        <Box className="lg:hidden">
          <HeroDonate />
        </Box>
      </Box>

      {/* Impact Numbers Section */}
      <Box
        ref={countersRef}
        className="h-auto lg:pt-[90px] pt-8 lg:px-[120px] px-6 lg:pb-[140px] pb-8 bg-LightBrown"
        style={{
          backgroundImage: !isMobile ? `url(${NumberPattern})` : "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
        }}
      >
        <Box className="max-w-screen-xl mx-auto">
          <Box>
            <CustomTypography
              as="h2"
              variant="h2"
              color="text-customGreen"
              weightFont="font-semibold"
            >
              Akhuwat’s Impact in Numbers
            </CustomTypography>
          </Box>
          <Box className="grid lg:grid-cols-3 grid-cols-1 gap-12 lg:pt-[130px] pt-12">
            {/* Interest Number */}
            <Box className="flex flex-col items-start">
              <Box>
                <h1>
                  <CountUp
                    className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[15px]"
                    start={countersVisible ? 0 : null}
                    end={interestNumber}
                    duration={3}
                    suffix="B"
                  />
                  <span className="font-manrope text-customGreen lg:text-[30px] text-[20px] leading-[30px] font-bold absolute lg:pt-2 pt-[4px] pl-2">
                    PKR
                  </span>
                </h1>
              </Box>
              <CustomTypography
                as="p"
                variant="p3"
                color="text-customGreen"
                weightFont="font-semibold"
              >
                {interestContent}
              </CustomTypography>
            </Box>

            {/* Family Number */}
            <Box className="flex flex-col lg:items-center lg:justify-self-center">
              <Box>
                <h1>
                  <CountUp
                    className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px]"
                    start={countersVisible ? 0 : null}
                    end={familyNumber}
                    decimals={1}
                    duration={3}
                    suffix="M+"
                  />
                </h1>
              </Box>
              <CustomTypography
                as="p"
                variant="p3"
                color="text-customGreen"
                weightFont="font-semibold"
              >
                {familyContent}
              </CustomTypography>
            </Box>

            {/* Recovery Rate */}
            <Box className="flex flex-col lg:justify-self-end">
              <Box>
                <h1>
                  <CountUp
                    className="font-bebs text-customGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px] tracking-[-0.03em]"
                    start={countersVisible ? 0 : null}
                    end={99.9}
                    decimals={1}
                    duration={3}
                    suffix="%"
                  />
                </h1>
              </Box>
              <CustomTypography
                as="p"
                variant="p3"
                color="text-customGreen"
                weightFont="font-semibold"
              >
                RECOVERY RATE
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Programs />
      <LatestNews founderImg={loading ? "Loading..." : founderImg} />
      <Faq />

      {/* Donate Section */}

      <Box className="bg-customGreen lg:py-140px lg:px-16 md:py-60px md:px-10 py-6 px-4">

        <Box className="bg-white lg:py-60px lg:px-[100px] rounded-[16px] md:py-10 md:px-10 py-5 px-5
         max-w-screen-2xl mx-auto">

          <CustomTypography
            as='h2' variant='h2' color='text-customBlack' weightFont='font-bold' alignment='text-center'
          >
           Turn Your Empathy into Action Header
          </CustomTypography>



          <CustomTypography className="pt-5"

            as='p'
            variant='p2'
            color="text-SlateGray"
            weightFont='font-semibold'
            alignment="text-center"

          >
           Your generosity holds the power to change lives. Support us in alleviating poverty and 
           empowering those who deserve help.
          </CustomTypography>



          <Box className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1
    lg:gap-5 md:gap-5 gap-3 lg:pt-10 md:pt-10 pt-6">

            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 1000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 1000")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 1000" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 1000
              </CustomTypography>
            </Box>



            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 2500" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 2500")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 2500" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 2500
              </CustomTypography>
            </Box>

            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Rs. 5000" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Rs. 5000")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Rs. 5000" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Rs. 5000
              </CustomTypography>
            </Box>



            <Box className={`h-[59px] rounded-full flex items-center justify-center border border-customBlack
         ${activeBtn === "Other amount" ? "border-topGreen bg-topGreen" : "border-customBlack bg-white"}   `}
              onClick={() => setActiveBtn("Other amount")}>
              <CustomTypography
                as='p' variant='p3' color={` ${activeBtn === "Other amount" ? "text-white" : "text-customBlack"}`}
                weightFont='font-normal' alignment='text-center'
              >
                Other amount
              </CustomTypography>
            </Box>






          </Box>

          <Box className="flex justify-center lg:pt-10 md:pt-10 pt-6">
            <CustomButton btnText="Donate" btnColor="white" btnBackground="customBlack" />

          </Box>

        </Box>

      </Box>



    </Box>
  );
};

export default Home;
