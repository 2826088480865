import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box } from "@material-ui/core";
import program1 from "./pictures/program1.png";
import AcbProgram from "./pictures/program-pic2.png";
import AkspProgram from "./pictures/program-pic3.png";
import AhsProgram from './pictures/ahs-banner.png';
import ProgramsCards from './ProgramsCards';
import arrow from "./pictures/home-program-arrow.png";
import CustomTypography from './CustomTypography';
import CustomCarousel from './CustomCarasouel';
import AesProgram from './pictures/Aes-banner.png'
import ReliefProgram from "./pictures/disaster-banner.png";
import CommunityProgram from "./pictures/community-banner.png";
import { Link } from 'react-router-dom';

const Programs = () => {

    const autoPlayInterval = 5000;

    const [currentSlide1, setCurrentSlide1] = useState(0);
    const totalSlides = 6; // Number of slides

   const slides=[
    < ProgramsCards   programImg={AesProgram}
    programHead="Akhuwat Education Services (AES)"
    programDesc="Provides fee-free, quality education to deserving students across Pakistan, inspiring them to break through the cycles of inter-generational poverty."
    cardLink="/programs/aes"
    />,
    
    < ProgramsCards programImg={AcbProgram}
    programHead="Akhuwat Cloth Bank (ACB)"
  programDesc="Collects donated clothing and household items and distributes them among those in need with respect and dignity."
      cardLink="/programs/acb"
   />,
    < ProgramsCards programImg={AkspProgram}
    programHead="Akhuwat Khwajasira program (AKSP)"
    programDesc="Facilitates Khwajasiras (Transgenders) by giving them trainings, free medical checkups, and creating a sense of community"
    cardLink="/programs/aksp"
   />,
    
    < ProgramsCards programImg={AhsProgram}
    programHead="Akhuwat Health Services (AHS)"
    programDesc="Provides effective and affordable healthcare services to financially vulnerable people along with subsidized medicines, lab tests, consultation, and nursing services."
     cardLink="/programs/ahs"
    />,
    
   < ProgramsCards programImg={ReliefProgram}
    programHead="Relief and Disaster Management Program"
    programDesc="Works in times of natural disasters, such as earthquakes, floods, and COVID-19 and provides relief and rehabilitation to the affected people."
     cardLink="/programs/disaster"
    />,
    
    < ProgramsCards programImg={CommunityProgram}
    programHead="Akhuwat Community Development  (ACD)"
    programDesc="Implements a participatory development approach involving the local community to identify problems and design localized solutions for development."
     cardLink="/programs/community"
    />
    
    ];



    const handleNext = () => {
        setCurrentSlide1((prev) => (prev === totalSlides - 1 ? 0 : prev + 1)); // Infinite loop forward
    };

    // Auto play functionality
    useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, autoPlayInterval);

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, [currentSlide1]);

    return (  
        <Box className='bg-customGreen'>
        <Box className="h-auto  lg:pt-140px lg:pb-80px lg:px-16 pt-10 pb-10 px-4 ">
           
           <Box className='max-w-screen-2xl mx-auto' > {/* Box to implement max width */}


            <Box>
                <Box className='lg:px-[200px]'>

                    <CustomTypography as="h2" variant="h21" color="text-white" alignment="text-center">
                        Akhuwat Programs
                    </CustomTypography>

                    <CustomTypography className='lg:pt-6 pt-3' as="p" variant="p2" color="text-customGray" alignment="text-center"
                    weightFont="font-semibold">
                   Since 2001, Akhuwat has been uplifting the underprivileged through its programs in the
                    fields of microfinance, education, healthcare, transgender support, community 
                    development, and natural disasters.
                    </CustomTypography>
                </Box>

                <Box className=" lg:grid grid-cols-[1fr_465px] lg:pt-80px pt-3 h-[605px]   hidden">
                    <Box>
                        <img className="  rounded-l-2xl h-[605px] w-full " src={program1} alt="image" />
                    </Box>
                    <Box className=" bg-white rounded-r-2xl   p-8  w-[465px] flex flex-col">
                        <h4 className='font-manrope font-bold text-[35px] leading-[52.5px]  text-topGreen '>
                            Akhuwat Islamic Microfinance (AIM)
                        </h4>

                 
                        <CustomTypography className='pt-10' as="p" variant="p2" color="text-topGray" 
                    weightFont="font-normal">
                       Through interest-free, microcredit loans, economically active and deserving 
                       individuals have the opportunity to build and expand their businesses.
                    </CustomTypography>

                        <Box className='flex justify-end items-end mt-auto'>
                            <Link to="/aim">
                            <Box className='w-[40px] h-[40px] rounded-full border
                        border-customBlack flex items-center justify-cente'>
                                <img src={arrow} alt="arrow" className='pl-2' />
                            </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>



            {/** Mobile carousel */}
            <Box className="lg:hidden mt-6 relative">
                {/* Carousel */}
                <Carousel
                    selectedItem={currentSlide1}
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    interval={5000}
                    showIndicators={false}
                    autoPlay infiniteLoop
                >
                 
                 {slides.map((slide, index) => (
                <div
                    key={index}
                    className='mr-2 ml-2'
                >
                    {slide}
                </div>
            ))}
                </Carousel>

                {/* Navigation Buttons */}
                <Box className="flex justify-center mt-9">
                    {slides.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentSlide1(index)}
                            className={`w-[48px] h-[4px] rounded-full ml-2 ${currentSlide1 === index ? 'bg-simpleGreen' : 'bg-white'}`}
                        ></button>
                    ))}
                </Box>
            </Box>
           </Box>
        </Box>

                    {/** Desktop... carousel */}

                    <Box className="lg:grid pb-140px  hidden  pl-0 pr-0 max-w-screen-2xl mx-auto">
                {/* Carousel */}
              
                    <CustomCarousel slides={slides} slideWidth={{
                        lg: 580,
                        md: 331,
                        sm: 310
                    }} gap={30}
                    ArrowPosition='bottom'
                    ArrowActiveColor='white'
                    ArrowInActiveColor='#A6A6A6'
                    ArrowAligned='start'

                    />
               

           
            </Box>
        </Box>
    );
}

export default Programs;
