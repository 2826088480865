import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";

//Akhuwat stories testimonials
const StoriesTestimonial = ({ storyImg, storyTitle, storyDesc, titleColor,storyHead }) => {

    return (

        <Box>

            <Box className="rounded-[24px] overflow-hidden">
            <img src={storyImg} alt="story image" />
            </Box>
           

            <Box className="pt-8">
                <h1 className={`font-manrope font-extrabold  text-[14px] leading-[14px]
                lg:text-left md:text-left text-center
                    text-${titleColor}`}> {storyTitle} </h1>

                <CustomTypography className="pt-8"
                    as='h3' variant='h3' color='text-customBlack' weightFont='font-medium' 
                    alignment="lg:text-left md:text-left text-center"
                >
                    {storyHead}
                </CustomTypography>

                <CustomTypography className="pt-8"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment="lg:text-left md:text-left text-center"
                >
                    {storyDesc}
                </CustomTypography>


                <Box className="flex justify-center lg:justify-start md:justify-start pt-8"> 
                    <CustomButton btnText="Read more" btnColor="white" btnBackground="customBlack" />

                </Box>

            </Box>

        </Box>
    )

}

export default StoriesTestimonial