import { Box } from "@material-ui/core";
import React from "react";
import StoryBanner from "../pictures/Akhuwat_story_banner.png";
import CustomTypography from "../CustomTypography";

const AkhuwatStory = () => {


    return (

        <Box>


            <Box
                className="lg:h-[688px] md:h-[426px] h-[245px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${StoryBanner})
                    `
                }}
            >

            </Box>

            {/* About us section */}

            <Box className="max-w-screen-2xl mx-auto  lg:pt-[72px] pt-10  lg:pb-60px md:pb-10 lg:px-[260px] md:px-10 px-4 pb-4 bg-white">

                <CustomTypography
                    as='p' variant='p2' color='text-topGray' weightFont='font-semibold' alignment='text-center'
                >
                    About Us
                </CustomTypography>


                <CustomTypography
                    as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Our Story
                </CustomTypography>

                <CustomTypography className="pt-10"
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-center'
                >
                  Akhuwat gives interest-free loans, educates deserving students, and supports the underprivileged in our society.
                </CustomTypography>

            </Box>


            <Box className="lg:pt-[72px] pt-10  lg:px-140px md:px-14 px-4  bg-white max-w-screen-2xl mx-auto">

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold' 
                >
                Progressing from a historical and philosophical discourse towards a pragmatic model of 
                poverty alleviation, Akhuwat was founded by Dr. Muhammad Amjad Saqib in 2001. Before 
                establishing Akhuwat, Dr. Saqib served in the Civil Services of Pakistan in the District 
                Management Group (DMG). He also served as the General Manager of the Punjab Rural Support
                 Program (PRSP). He had witnessed the devastating impact of poverty which encouraged him 
                 to dedicate his life to alleviating the suffering of the poor.
                </CustomTypography>


                <CustomTypography className="pt-[52px]"
                    as='p' variant='p2' color='text-AesHead' weightFont='font-semibold' 
                >
                 One of Akhuwat’s initial loans was granted to a widow from Rasool Park. Dr. Amjad 
                 recalls her story in the book ‘Akhuwat: A Journey”,

                </CustomTypography>



                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-semibold'
                >
                   “With tears in her eyes, each one of which mirrored years of trial and tribulation, 
                   this woman related how her circumstances had worsened considerably after her husband 
                   died. While the full responsibility for maintaining the household had fallen on her, 
                   and she was not averse to fulfilling these responsibilities, begging appeared
                    unacceptable to her. Once her situation was confirmed, she was presented with 
                    Rs. 10,000 (USD 200 at the time) in the form of an interest-free loan. She bought 
                    herself two new sewing machines with this amount. During the next six months, she 
                    toiled and sweated day and night. With the help of the income obtained from these 
                    two sewing machines, the determined woman was not only able to cover her household
                     expenses but later married off one of her daughters and eventually repaid the whole
                      loan.” (p. 14)

                </CustomTypography>

            </Box>



            <Box className="max-w-screen-2xl mx-auto lg:pt-[72px] pt-10 lg:px-120px md:px-10 px-4 lg:pb-[72px]  pb-10   bg-white">

               

                    <CustomTypography
                        as='p' variant='p3' color='text-MidnightTest' weightFont='font-semibold' 
                    >
                   For the first eighteen months, Akhuwat didn’t have a proper office and was managed
                    from a small cabin within the premises of PRSP. However, the spirit of solidarity and
                     the commitment of the Akhuwat team persevered. More than two decades later, Akhuwat
                      has disbursed more than PKR 200 billion and opened offices in 350+ cities in
                       Pakistan. 

                    </CustomTypography>
               


              

            </Box>

        </Box>
    )
}

export default AkhuwatStory;