// CustomTypography.js
import React from 'react';
import typographyConfig from './TypographyConfig';

const CustomTypography = ({ 
  as: Component = 'h1',      // HTML element, defaults to 'h1'
  variant = 'h1',            // Custom variant from typographyConfig
  alignment = 'text-left',   // Text alignment, defaults to 'left'
  color,  
  weightFont,                   // Custom color, defaults to color from config
  className = '',            // Additional class names
  children                   // Child elements
}) => {
  // Destructure typography properties based on the variant (heading or paragraph)
  const typography = typographyConfig.headings[variant] || typographyConfig.paragraphs[variant];

  if (!typography) {
    console.warn(`Typography variant "${variant}" is not defined in the config.`);
    return null; // Safely handle undefined variants
  }

  const { size, lineHeight, weight, UpdatedColor, fontFamily, letterSpacing, defaultAlignment } = typography;

  return (
    <Component
      className={`
        ${size} ${lineHeight} ${weightFont||weight} ${color || UpdatedColor} ${fontFamily} ${letterSpacing} 
        ${alignment || defaultAlignment} 
        
        ${className}
      `}
    >
      {children}
    </Component>
  );
};

export default CustomTypography;
