// constants/blogConstants.js
export const UPLOAD_JOBS_REQUEST = "UPLOAD_JOBS_REQUEST";
export const UPLOAD_JOBS_SUCCESS = "UPLOAD_JOBS_SUCCESS";
export const UPLOAD_JOBS_FAIL = "UPLOAD_JOBS_FAIL";

export const DELETE_JOBS_REQUEST = "DELETE_JOBS_REQUEST";
export const DELETE_JOBS_SUCCESS = "DELETE_JOBS_SUCCESS";
export const DELETE_JOBS_FAIL = "DELETE_JOBS_FAIL";

export const GET_JOBS_REQUEST = "GET_JOBS_REQUEST";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

export const UPDATE_JOBS_REQUEST = "UPDATE_JOBS_REQUEST";
export const UPDATE_JOBS_SUCCESS = "UPDATE_JOBS_SUCCESS";
export const UPDATE_JOBS_FAIL = "UPDATE_JOBS_FAIL";


 export const CLEAR_ERROS="CLEAR_ERROS"
