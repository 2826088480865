import React from 'react';
import { Box } from '@material-ui/core';

const AkspLinkage = ({ boxImage, head,contactNumber, backColor }) => {
    return (
        <Box className='lg:h-[305px] h-[180px] lg:w-auto md:w-auto w-[244px] rounded-[30px] border border-[#DEDEDE] flex 
        flex-col items-center justify-center'
            style={{ backgroundColor: backColor }}>
            
            {boxImage && (
                <img src={boxImage} alt="image box" className='max-w-[50%] mb-4 object-contain lg:h-full h-[104px]' />
            )}

            {head && (
                <h1 className='lg:w-[441px] w-auto text-center font-manrope font-semibold text-[18px] lg:text-[28px] leading-[42px]
                 text-topGreen'>
                    {head} <br></br> 

                    {contactNumber}
                </h1>
            )}
        </Box>
    )
}

export default AkspLinkage;
