import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobs, deleteJob } from "../Actions/JobActions";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomTypography from "../CustomTypography";
import {Sidebar} from "./Sidebar"; // Assuming Sidebar is in this path

const AllJobs = () => {
    const dispatch = useDispatch();
    const { jobs } = useSelector(state => state.getAllJobs) || {};

    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);

    const handleDeleteJob = (id) => {
        dispatch(deleteJob(id)).then(() => {
            dispatch(getJobs());
        });
    };

    return (
        <Box className="flex">
            {/* Sidebar Section */}
            <Sidebar />

            {/* Main Content Section */}
            <Box className="flex-1 lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-4">
                <CustomTypography as="h2" variant="h2" alignment="text-center" color="text-customBlack">
                    Uploaded Jobs
                </CustomTypography>
                <TableContainer component={Paper} className="p-4 mt-80px" sx={{ boxShadow: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'red' }}>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Title</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Location</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobs && jobs.length > 0 ? (
                                jobs.map((job) => (
                                    <TableRow key={job.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                                        <TableCell style={{ padding: '16px', fontSize: '14px' }}>{job.title}</TableCell>
                                        <TableCell style={{ padding: '16px', fontSize: '14px' }}>{job.description}</TableCell>
                                        <TableCell style={{ padding: '16px', fontSize: '14px' }}>{job.jobType}</TableCell>
                                        <TableCell style={{ padding: '16px', fontSize: '14px' }}>{job.jobLocation}</TableCell>
                                        <TableCell style={{ padding: '16px', fontSize: '14px' }}>{job.status}</TableCell>
                                        <TableCell style={{ padding: '16px', textAlign: 'center' }}>
                                            <Box display="flex" justifyContent="center">
                                                <IconButton color="primary" onClick={() => { /* handle edit job */ }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="secondary" onClick={() => handleDeleteJob(job.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No jobs available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default AllJobs;
