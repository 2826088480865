import { Box } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import BannerDesign from "../AES_AIM/BannerDesign";
import CommBanner from "../pictures/community-banner.png";
import CommPattern from "../pictures/community-pattern.png";
import DonateNowSetion from "../ReusableComponents/DonateNowSection";
import Kasur from "../pictures/community-kausr.png";
import Mustafa from "../pictures/community-mustfa.png";
import AkCollege from "../AES_AIM/AkCollege";
import AcbDonate from "../ACB_AHS/AcbDonate";
import CustomTypography from "../CustomTypography";
import CustomCarousel from "../CustomCarasouel";

const Community = () => {

    const [currentSlide1, setCurrentSlide1] = useState(0); // donate cards
    const totalSlides = 4; // Total number of slides

    const autoPlayInterval = 5000;






    const donateSlides = [

        <AcbDonate cardNo="01." cardTitle="Generosity Brings Joy"
            cardDesc="The main components of this developmental process are participation in taking initiatives to identify unmet needs, and self-reliance, breaking away from dependencies that suppress the creativity of the poor. It is more a psychological than an economic or physical process." />,

        <AcbDonate cardNo="02." cardTitle="Identification of Suitable Stakeholders"
            cardDesc="The public involvement of stakeholders in development projects is widely recognized as a fundamental element of the process. Since we had a presence in Mustafabad, we were hopeful on successful implementation and completion of our programs." />,

        <AcbDonate cardNo="03." cardTitle="We Donate with Dignity"
            cardDesc="By participating in decision making and implementation activities, local people help officials identify their needs and the necessary resources required. For example, Akhuwat did a participation workshop in a local mosque where both men and women were invited to share their views." />,

        <AcbDonate cardNo="04." cardTitle="Needs Identification and Goal Determination"
            cardDesc="At Akhuwat, we don’t consider our donations as charity. We want to share these gifts with our brothers and sisters because we want to clothe them with dignity." />,
    ];

    // Auto play functionality
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide1((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
        }, autoPlayInterval);

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, [currentSlide1]);

    return (
        <Box>
            {/* Banner section */}
            <Box>
                <BannerDesign imageURL={CommBanner} headPart1={'Community'} headPart2={' Development'}
                    belowHead={'Community Development'}
                    belowPara1="Given Akhuwat’s commitment to poverty alleviation and community development, Akhuwat has initiated two community development programs: Rural Development (Kasur Mawakhat Program) and Urban Development (The Mustafabad Mawakhat Project). Under these projects, Akhuwat implements a participatory development approach involving the local community to identify problems and design localized solutions for development."
                    belowheadColor="text-RuralPrimary"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={CommPattern}
                    donateBtn="donate"
                />
            </Box>

            {/* Development programs */}
            <Box className="lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-4 bg-customGray ">
                <Box className="max-w-screen-2xl mx-auto">
                    <AkCollege colgImage={Kasur} commTopHead="RURAL DEVELOPMENT"
                        RURAL DEVELOPMENT collegeHead="Kasur Mawakhat Program"
                        collegePara="The Kasur Mawakhat Program (KMP) was a participatory rural development program that mobilized local communities to lead their development projects in their villages. Launched in 2014 in Kasur, Pakistan, it has now been replicated in seven other adjoining villages. With the support of Akhuwat, hundreds of individuals are engaged in uplifting their families and communities out of poverty in a self-determined manner. The project was successfully completed (2014 – 2018) in Union Council Marali, District Kasur, where villages are converted to model villages and many acres of barren, saline and water-logged soil was reclaimed and made cultivable."
                        backColor="LightSand"
                        paraColor="customBlack"
                        btnColor="white"
                        commBox="community" />

                    <Box className="pt-60px">
                        <AkCollege colgImage={Mustafa} commTopHead="URBAN DEVELOPMENT"
                            RURAL DEVELOPMENT collegeHead="Mustafabad Project"
                            collegePara="Under the umbrella of Project Mustafabad, Akhuwat is implementing the Sustainable Development Goals (SDGs) proposed by the United Nations in Mustafabad, Kasur. These include: ending poverty, improving health facilities, and improving economic growth for the sake of reducing inequalities. Akhuwat involves the local people to identify problem areas and development priorities. In this regard, 12 out of 17 SDGs have been selected, which include provision of free IT training, skill development programs, food supplements, and sanitation drives to empower and educate the community. In addition, Akhuwat is also providing interest-free loans for toilets and solar panels and developing a Waste Management plant for Mustafabad."
                            backColor="MintCream"
                            paraColor="customBlack"
                            btnColor="white"
                            commBox="community" />
                    </Box>
                </Box>
            </Box>

            {/* Community development */}
            <Box>
                <Box className='lg:py-140px lg:px-120px py-6 px-4 md:py-[72px] md:px-16
            max-w-screen-2xl mx-auto'>

                    <Box className="flex justify-center">

                        <CustomTypography className="lg:w-[900px]" as="h2" variant="h21" color="text-customGreen" weightFont="font-bold"
                            alignment="text-center"
                        >
                            Lessons Learnt in Community Development
                        </CustomTypography>

                    </Box>





                </Box>

                {/* Carousel */}
                <Box className="lg:pb-140px pb-6 md:pb-60px ">

                    <CustomCarousel slides={donateSlides} slideWidth={{
                        lg: 330,
                        md: 330,
                        sm: 310
                    }} gap={40}

                        ArrowPosition="bottom"
                        ArrowAligned="end"
                        ArrowActiveColor="#0F6742"
                        ArrowInActiveColor="#A6A6A6"

                    />


                </Box>
            </Box>

            {/* Donaate now section  */}



            <DonateNowSetion Head6="Community Development"
                Head2="Your Help Improves Lives. Donate Now." HeadColor2="text-white" HeadColor6="text-simpleGreen"
                backColor="customGreen" />
        </Box>
    );
};

export default Community;
