// AboutCard.jsx

import React from 'react';
import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography';


const AboutCard = ({CardTitle,cardDesc,cardColor}) => {

    return (
        <Box className={`bg-${cardColor} lg:px-10 lg:py-80px md:px-2 py-6 px-4 rounded-[24px] min-h-[340px] `}>


                <CustomTypography as="h2" variant="h2" color="text-white" weightFont="font-bold"
                >
                   {CardTitle}
                </CustomTypography>
                <Box className='pt-5'>

                    <CustomTypography as="p" variant="p1" color="text-white" weightFont="font-semibold"
                    >
                       {cardDesc}
                    </CustomTypography>
                </Box>
           

        </Box>
    );
};

export default AboutCard;
