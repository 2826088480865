import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

const BlogDetail = () => {
    const { id } = useParams(); // Get the blog ID from the URL
    const [blog, setBlog] = useState(null); // Local state to store blog data
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`/api/v1/blogs/${id}`);
                setBlog(response.data.data); // Accessing the actual blog data
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blog:", error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };

        fetchBlog();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (!blog) return <p>Blog not found.</p>;

    return (
        <Box className="p-10 max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
            <p className="text-sm text-gray-500 mb-2">
                Created by: {blog.createdBy} on {new Date(blog.createdAt).toLocaleDateString()}
            </p>
            <img src={blog.image} alt={blog.title} className="w-full h-auto mb-4 object-cover rounded-lg" />
            <div className="blog-content text-gray-700" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </Box>
    );
};

export default BlogDetail;
