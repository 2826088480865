import React from "react";
import { Box } from "@material-ui/core";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";


const LoanProcess = () => {
    return (

        <Box className='max-w-screen-2xl mx-auto'>

            <Box className="bg-white lg:py-[178px] lg:px-[234px] md:py-18 md:px-16 px-6 py-[18px]">

                <CustomTypography as="h1" variant="h1" weightFont='font-bold' alignment="text-center">
                    Loan Process
                </CustomTypography>

                {/* Number section */}

                <Box className="lg:pt-90px md:pt-90px flex flex-col items-center justify-center pt-60px">
                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            1
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Program Introduction
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>

                    {/* 2 */}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            2
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Application submission by potential borrower
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>



                    {/* 3*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            3
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Social Appraisal by Staff
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>


                    {/* 4*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            4
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Business Appraisal by Staff
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>


                    {/* 5*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            5
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Second Appraisal by Senior Staff
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>



                    {/* 6*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            6
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Monitoring of the borrower / business
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>





                    {/* 7*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            7
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Recovery to be received in Akhuwat office
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>





                    {/* 8*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            8
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Loan disbursement to borrower in Mosque
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>




                    {/* 9*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            9
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Loan approved by Loan Approval Committee
                    </CustomTypography>

                    <Box className="my-6 text-center border-r-[2px] border-r-borderGray h-60px " >

                    </Box>





                    {/* 10*/}


                    <Box className="w-16 h-16 rounded-full bg-simpleGreen p-[10px] flex items-center justify-center">
                        <CustomTypography as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                            10
                        </CustomTypography>
                    </Box>

                    <CustomTypography className="pt-5 max-w-[460px]" as="h3" variant="h3" alignment="text-center" weightFont="font-medium">
                        Guarantors of loan to be provided by borrower
                    </CustomTypography>







                </Box>






                <Box className="lg:mt-[250px] md:mt-[250px] mt-80px rounded-[24px] lg:py-60px lg:px-60px py-10 md:px-10 px-4  bg-simpleGreen flex flex-col justify-center lg:w-[952px] w-auto">

                    <CustomTypography as="h2" variant="h21" weightFont='font-bold' alignment="text-center"
                        color='text-customGreen'>
                        Contact us
                    </CustomTypography>


                    <CustomTypography className='pt-4' as="p" variant="p2" weightFont='font-normal' alignment="text-center"
                        color='text-customGreen'>
                        To discover more about our services or to apply for a loan, please visit your nearest branch. Locate nearest branch here
                    </CustomTypography>

                    <Box className="flex justify-center pt-10">
                        <CustomButton btnText="Branch network" btnColor="white" btnBackground="customBlack" />

                    </Box>

                </Box>











            </Box>

        </Box>
    )
}


export default LoanProcess