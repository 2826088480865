// constants/blogConstants.js
export const UPLOAD_BLOG_REQUEST = "UPLOAD_BLOG_REQUEST";
export const UPLOAD_BLOG_SUCCESS = "UPLOAD_BLOG_SUCCESS";
export const UPLOAD_BLOG_FAIL = "UPLOAD_BLOG_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";

export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";


 export const CLEAR_ERROS="CLEAR_ERROS"
