import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import CustomTypography from './CustomTypography';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';

function Submenu({ title, desc, links, image, isVisible, btnLink }) {
    const [activeItem, setActiveItem] = useState(null);
    const [activeSubItem, setActiveSubItem] = useState(null);

    const handleItemClick = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    const handleMenuHover = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    // Function to close submenu when mouse leaves


    const subItemhandleItemClick = (SubIndex) => {
        setActiveSubItem(activeSubItem === SubIndex ? null : SubIndex);
    };

    return (
        <Box
            className={`absolute fixed left-0 w-full transition-all duration-300 z-50 ${isVisible ? 'h-[400px] rounded-b-[40px]' : 'h-0'
                } bg-primaryLight shadow-lg overflow-hidden`}
            style={{
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for separation
            }}
        >
            <Box className="grid grid-cols-3 gap-10 p-60px max-w-screen-2xl mx-auto">
                {/* 1st Column - Title/Text */}
                <Box className="flex-1 border-r border-gray-300 px-6">
                    <CustomTypography
                        as="p"
                        variant="p2"
                        color="text-customBlack"
                        weightFont="font-semibold"
                    >
                        {title}
                    </CustomTypography>

                    <CustomTypography
                        as="p"
                        variant="p3"
                        color="text-SlateGray"
                        weightFont="font-normal"
                        className="pt-4"
                    >
                        {desc}
                    </CustomTypography>

                   {btnLink &&(
                    <Box className='flex  pt-4'>
                        <Link
                            to={btnLink}
                            className="mt-1 inline-block  py-2 font-manrope   text-SlateGray rounded-lg font-semibold text-[18px]"
                        >
                      Read more   <EastIcon style={{ width: '25px', height: '25px' }} />
                        </Link>
                    </Box>
                   )} 
                </Box>

                {/* 2nd Column - Submenu Links */}
                <Box className="border-r border-gray-300 px-6">
                    <ul className="space-y-1 grid grid-cols-1 gap-3">
                        {links.map((link, index) => (
                            <li
                                key={index}
                                onClick={() => handleItemClick(index)}
                                onMouseEnter={() => handleMenuHover(index)}
                                className={`font-manrope font-bold text-[14.4px] leading-[18px] text-SlateGray hover:text-topGreen cursor-pointer ${activeItem === index ? 'text-topGreen' : ''}`}
                            >
                                {/* Check if link.path exists; if it does, render Link, otherwise render label as text */}
                                {link.path ? (
                                    <Link to={link.path} className={`font-manrope font-bold text-[14.4px] leading-[18px] text-SlateGray hover:text-topGreen cursor-pointer ${activeItem === index ? 'text-topGreen' : ''}`}>
                                        {link.label}
                                    </Link>
                                ) : (
                                    <span>{link.label}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </Box>


                {/* 3rd Column - Dynamic Content */}
                <Box className="px-6">
                    {activeItem !== null && (
                        <>
                            {links[activeItem]?.items ? (
                                // Displaying items list if items are present for the selected label
                                <ul className="space-y-1">
                                    {links[activeItem].items.map((item, subIndex) => (
                                        <li key={subIndex}
                                            onClick={() => subItemhandleItemClick(subIndex)}
                                        >

                                            <Link
                                                to={item.path || '#'}
                                                className={`font-manrope font-bold text-[14.4px] 
                                                leading-[18px] text-SlateGray
                                                 hover:text-topGreen ${activeSubItem === subIndex ? 'text-topGreen' : ''
                                                    }`}
                                            >
                                                {item.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <>
                                    {/* Check if the selected link has content and display it */}
                                    {links[activeItem]?.content && (
                                        <>
                                            {links[activeItem].content.image && (
                                                <img
                                                    src={links[activeItem].content.image}
                                                    alt={links[activeItem].content.heading || "Visual content"}
                                                    className="w-full h-[170px] object-cover rounded-lg "
                                                />
                                            )}
                                            {/* Display heading and detail if available */}
                                            <CustomTypography
                                                as="p"
                                                variant="p3"
                                                color="text-SlateGray"
                                                weightFont="font-semibold"
                                                className="mt-3"
                                            >
                                                {links[activeItem].content.heading || "Heading Not Available"}
                                            </CustomTypography>
                                            <CustomTypography
                                                as="p"
                                                variant="p3"
                                                color="text-SlateGray"
                                                weightFont="font-normal"

                                            >
                                                {links[activeItem].content.detail || "Details Not Available"}
                                            </CustomTypography>
                                            {/* Display button if available */}
                                            {links[activeItem].content.buttonText && (
                                                <Link
                                                    to={links[activeItem].content.buttonLink || '#'}
                                                    className="mt-1 inline-block  py-2 font-manrope underline  text-SlateGray rounded-lg font-normal text-[14.4px]"
                                                >
                                                    {links[activeItem].content.buttonText || 'Read More'}
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default Submenu;
