// store.js
import { configureStore } from '@reduxjs/toolkit';
import { addBlogReducers, getBlogReducer } from './Reducers/BlogReducer';
import { addJobReducers,getJobReducer} from './Reducers/JobReducer';
import { adminUserReducers, getRoleReducer, newUserReducers, updatePasswordReducers, userReducers } from './Reducers/UserReducers';



const Store = configureStore({
    reducer: {
        blog: addBlogReducers,
        allBlogs: getBlogReducer,
        addJob : addJobReducers,
        getAllJobs : getJobReducer,    
        user: userReducers,
        newUser: newUserReducers,
        updatePassword: updatePasswordReducers,
        adminUsers: adminUserReducers,
        adminRoles : getRoleReducer,

    },
});

export default Store;
