import { Box } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"




const CustomButton = ({btnText="Read",btnURL="/",btnColor="white",btnBackground="customBlack"}) => {

    // This component will return a button with dynamic properties...

    return (

        <Box>
            <Link className=
               {`lg:h-[59px] h-10 lg:px-8 px-6 lg:py-4 py-3 flex items-center justify-center rounded-full font-manrope
                text-[14px] lg:text-[18px] font-bold  leading-[28px]
                bg-${btnBackground} text-${btnColor}`}
                to={btnURL}
            >
                {btnText}
            </Link>
        </Box>
    )
}

export default CustomButton