import React from 'react';
import CustomTypography from '../CustomTypography';
import { Box } from '@material-ui/core';

const PrincipalTestimonials = ({PrincipleTitle,PrincipleDesc}) => {

    return (

       

            <Box className='lg:min-h-[428px] md:min-h-[372px] min-h-[287px] rounded-[20px] p-6 bg-white shadow-md'>


                <CustomTypography as="p" variant="p1" color="text-topGreen " weightFont="font-semibold"
                 alignment='lg:text-left md:text-left text-center'
                >
                    {PrincipleTitle}
                </CustomTypography>


                <CustomTypography className='lg:pt-6 pt-3' as="p" variant="p3" color="text-SlateGray " weightFont="font-normal"
                alignment='lg:text-left md:text-left text-center'
                >
                 {PrincipleDesc}
                </CustomTypography>

            </Box>

     
    )
}


export default PrincipalTestimonials