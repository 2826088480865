import { Box } from '@material-ui/core';
import React from 'react';
import CustomTypography from './CustomTypography';

const MediaCard = ({ cardImg, cardTitle, titleColor, titleBackColor, cardDesc, cardDate }) => {
    return (
        <Box 
            className="rounded-[20px] overflow-hidden flex flex-col min-h-[400px] h-full bg-white shadow-lg" 
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            {/* Image Section */}
            <img 
                src={cardImg} 
                alt="card image"  
                className="object-cover w-full h-[200px]" 
                style={{ flex: '0 0 auto' }} 
            />

            {/* Content Section */}
            <Box className="flex-1 p-5 flex flex-col justify-between">
                {/* Title */}
                <Box className="flex justify-start">
                    <Box 
                        className={`py-1 px-4 inline-block rounded-full bg-${titleBackColor}`} 
                       
                    >
                        <h1 
                            className={`font-manrope font-bold text-[12.44px] leading-[14.93px] text-${titleColor}`} 
                           
                        >
                            {cardTitle}
                        </h1>
                    </Box>
                </Box>

                {/* Description */}
                <CustomTypography 
                    className="pt-3 text-left flex-grow" 
                    as="p" 
                    variant="p2" 
                    weightFont="font-semibold" 
                    color="customBlack"
                    style={{ lineHeight: '1.5', flex: '1' }}
                >
                    {cardDesc}
                </CustomTypography>

                {/* Date */}
                <Box className="pt-5 text-left">
                    <p className="font-manrope font-normal text-topGray text-[13.23px] leading-[18px]">
                        {cardDate}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default MediaCard;
