import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import backgroundImage from '../pictures/Active_facility.png';
import Swal from 'sweetalert2';
import Loader from '../Loader';
import { uploadBlog, getBlogs } from '../Actions/BlogActions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '../ReusableComponents/CustomButton';
import { Box } from '@material-ui/core';

const BlogUpload = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [createdBy, setCreatedBy] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { blogs } = useSelector(state => state.allBlogs) || {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('content', content);
        formData.append('image', image);
        formData.append('createdBy', createdBy);
        
        try {
            // Upload the blog via the API and pass the form data, which includes the image
            await dispatch(uploadBlog(formData));

            // After the blog is uploaded, fetch the updated list of blogs
            dispatch(getBlogs());

            // Notify the user of the success
            Swal.fire({
                title: 'Success!',
                text: 'Blog uploaded successfully!',
                icon: 'success',
                confirmButtonText: 'Okay',
            });

            // Reset form fields after successful upload
            setTitle('');
            setDescription('');
            setContent('');
            setImage(null);
            setCreatedBy('');
        } catch (error) {
            console.error('Error uploading blog:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Error uploading blog. Please try again.',
                icon: 'error',
                confirmButtonText: 'Okay',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch the list of blogs after component mounts
        dispatch(getBlogs());
    }, [dispatch]);


    

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ size: [] }],
            ["bold", "underline", "italic", "strike", "blockquote"],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ["link", "image", "video"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
        ]
    };

    return (
        <>
            <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
                <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div className="w-full  p-8">
                                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Upload New Blog</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            id="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="description">Description</label>
                                        <textarea
                                            id="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        ></textarea>
                                    </div>
       
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="content">Content</label>
                                        <ReactQuill style={{ minHeight: '500px' }}
                                            value={content}
                                            onChange={setContent}
                                            className="border border-gray-300 rounded-md"
                                            theme="snow"
                                            modules={modules}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="image">Image</label>
                                        <input
                                            type="file"
                                            id="image"
                                            onChange={(e) => setImage(e.target.files[0])}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 mb-1" htmlFor="createdBy">Created By</label>
                                        <input
                                            type="text"
                                            id="createdBy"
                                            value={createdBy}
                                            onChange={(e) => setCreatedBy(e.target.value)}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">Upload Blog</button>
                                </form>
                                <Box className='flex justify-center pt-10'>
                                    <CustomButton btnURL='/admin/blogs' btnText='Check Uploaded Blogs' btnBackground='customBlack' />
                                </Box>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default BlogUpload;
