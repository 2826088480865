import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSpinner from "../Loader";
import { useSelector } from "react-redux";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);
const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true, // Include cookies
};

const MediaContent = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [sections, setSections] = useState([]);
    const [pageid, setPageId] = useState("");
    const [roleid, setRoleId] = useState(id);
    const [content, setContent] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedSectionId, setSelectedSectionId] = useState(""); // Added state for selected section
    const { user } = useSelector((state) => state.user);

    const fetchSections = async (pageId) => {
        if (!pageId) return;

        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/api/v1/sections/${pageId}`,config);
            const fetchedSections = response.data.data || [];

            if (fetchedSections.length === 0) {
                Swal.fire({
                    icon: "info",
                    title: "No Sections Found",
                    text: "No sections are associated with this page.",
                });
            }

            setSections(fetchedSections);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || "Failed to fetch sections.",
            });
            setSections([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchContent = async (sectionId) => {
        if (!sectionId) return;

        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/api/v1/content/${sectionId}`,config);
            const fetchedContent = response.data.data || [];

            const filteredContent = fetchedContent.filter((item) => item.content === null);

            if (filteredContent.length === 0) {
                Swal.fire({
                    icon: "info",
                    title: "No Content Found",
                    text: "No content with null image is associated with this section.",
                });
            }

            setContent(filteredContent);
            setSelectedItem(null);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || "Failed to fetch content.",
            });
            setContent([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchPages = async () => {
            setLoading(true);
            try {
                const pagesResponse = await axios.get(`${API_BASE_URL}/api/v1/admin/all/pages/${user.userid}`,config);
                setPages(pagesResponse.data.data || []);
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response?.data?.message || "Failed to load pages.",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchPages();
    }, [user.userid]);

    const handlePageChange = (e) => {
        const selectedPageId = e.target.value;
        setPageId(selectedPageId);
        fetchSections(selectedPageId);
        setContent([]);
        setSelectedSectionId("");
    };

    const handleSectionChange = (e) => {
        const selectedSectionId = e.target.value;
        setSelectedSectionId(selectedSectionId);
        fetchContent(selectedSectionId);
    };

    const handleItemChange = (e) => {
        const selectedShortName = e.target.value;
        const selected = content.find((item) => item.short_name === selectedShortName);
        setSelectedItem(selected || null);
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpdateContent = async () => {
        if (!selectedFile) {
            Swal.fire({
                icon: "warning",
                title: "Invalid File",
                text: "Please select a file to upload.",
            });
            return;
        }

        const formData = new FormData();
        formData.append("short_name", selectedItem.short_name);
        formData.append("image", selectedFile);

        setLoading(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/api/v1/content/update/media`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            },config);

            Swal.fire({
                icon: "success",
                title: "Image Updated",
                text: response.data.message || "Image updated successfully.",
            });

            setSelectedItem({ ...selectedItem, page_image: URL.createObjectURL(selectedFile) });
            setSelectedFile(null);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || "Failed to update image.",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="min-h-screen bg-gray-100 p-8">
                    <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-10 space-y-8">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                            <div>
                                <label htmlFor="pages" className="block text-lg font-medium text-gray-700">
                                    Select Page
                                </label>
                                <select
                                    id="pages"
                                    value={pageid}
                                    onChange={handlePageChange}
                                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-400"
                                >
                                    <option value="">Select a Page</option>
                                    {pages.map((page) => (
                                        <option key={page.pageid} value={page.pageid}>
                                            {page.page_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="sections" className="block text-lg font-medium text-gray-700">
                                    Select Section
                                </label>
                                <select
                                    id="sections"
                                    value={selectedSectionId}
                                    onChange={handleSectionChange}
                                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-400"
                                >
                                    <option value="">Select a Section</option>
                                    {sections.map((section) => (
                                        <option key={section.sec_id} value={section.sec_id}>
                                            {section.section_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="items" className="block text-lg font-medium text-gray-700">
                                    Select Item
                                </label>
                                <select
                                    id="items"
                                    onChange={handleItemChange}
                                    className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-400"
                                >
                                    <option value="">Select an Item</option>
                                    {content.map((item) => (
                                        <option key={item.content_id} value={item.short_name}>
                                            {item.short_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {selectedItem && (
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-start">
                                <div className="p-4 bg-gray-100 rounded-lg shadow-md">
                                    <h3 className="text-lg font-bold text-gray-800">Existing Image</h3>
                                    {selectedItem.page_image ? (
                                        <img src={selectedItem.page_image} alt="Existing" />
                                    ) : (
                                        <p className="text-gray-500">No image available</p>
                                    )}
                                </div>
                                <div className="p-4 bg-white rounded-lg shadow-md">
                                    <h3 className="text-lg font-bold text-gray-800">Update Image</h3>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="w-full mt-4 p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-400"
                                    />
                                    <button
                                        onClick={handleUpdateContent}
                                        className="mt-4 w-full p-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                                    >
                                        Update Image
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default MediaContent;
