import React from 'react';
import { Box } from '@material-ui/core';
import CountUp from 'react-countup';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomTypography from '../CustomTypography';


const AimNumber = () => {


    return (

        <Box className='bg-customGreen'>
            <Box className="max-w-screen-2xl mx-auto lg:py-80px md:py-80px md:px-10 py-52px lg:px-120px px-4  ">
                <Box>
                    <CustomTypography as="h2" variant="h2" color="text-white" weightFont="font-semibold">
                        Akhuwat’s Impact in Numbers
                    </CustomTypography>
                </Box>

                <Box className="grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 lg:gap-[130px] md:gap-60px gap-[70px] lg:pt-[120px] pt-12">

                    {/* 1st Column: Left Aligned */}
                    <Box >
                        <Box className='flex'>
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[15px]" end={230} decimals={1} suffix="B" />
                                <span className="font-manrope text-simpleGreen lg:text-[30px] text-[20px] leading-[30px] font-bold absolute lg:pt-2 pt-[4px] pl-2">PKR</span>
                            </h1>
                        </Box>


                        <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                            DISBURSED IN INTEREST-FREE LOANS
                        </CustomTypography>
                    </Box>

                    {/* 2nd Column: Center Aligned */}
                    <Box>
                        <Box >
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px] " end={99.1} decimals={1} suffix="%" />
                            </h1>
                        </Box>
                        <Box className="interest-text2">

                            <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                                RECOVERY RATE
                            </CustomTypography>
                        </Box>
                    </Box>

                    {/* 3rd Column: Right Aligned */}
                    <Box >
                        <Box>
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal mb-[12px] tracking-[-0.03em]" end={800} suffix="+" />
                            </h1>
                        </Box>
                        <Box>

                            <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                                BRANCHES
                            </CustomTypography>
                        </Box>
                    </Box>




                    {/* 1st Column: Left Aligned */}
                    <Box >
                        <Box className="flex items-end">
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen 
                                lg:text-[120px] text-[80px] lg:leading-[120px] leading-[80px] font-normal 
                                mb-[15px]" end={608} suffix="K+" />

                            </h1>
                        </Box>


                        <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                            ACTIVE LOANS
                        </CustomTypography>
                    </Box>

                    {/* 2nd Column: Center Aligned */}
                    <Box >
                        <Box >
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen lg:text-[120px] 
                                text-[80px] lg:leading-[120px] leading-[80px] font-normal
                                 mb-[12px] " end={400} suffix="+" />
                            </h1>
                        </Box>
                        <Box className="interest-text2">

                            <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                                CITIES
                            </CustomTypography>
                        </Box>
                    </Box>

                    {/* 3rd Column: Right Aligned */}
                    <Box >
                        <Box>
                            <h1>
                                <CountUp className="font-bebs text-simpleGreen lg:text-[120px] text-[80px] 
                                lg:leading-[120px] leading-[80px] font-normal mb-[12px]
                                 tracking-[-0.03em]" end={6.26} decimals={2} suffix="M+" />
                            </h1>
                        </Box>
                        <Box>

                            <CustomTypography as="p" variant="p3" color="text-customGray" weightFont="font-semibold">
                                TOTAL NO. OF LOANS
                            </CustomTypography>
                        </Box>
                    </Box>


                </Box>




                <Box className=' lg:pt-60px pt-5'>
                    <h1 className='text-customGray font-normal font-manrope text-[12px] lg:text-left md:text-left text-center
                    leading-[18px] '> Data Collected Till June 30, 2024.</h1>
                </Box>

            </Box>
        </Box>

    )
}

export default AimNumber