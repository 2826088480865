// typographyConfig.js
const typographyConfig = {
    headings: {

        //step 6 heading as per figma design
      h1: {
        size: 'text-fluid-28',
        lineHeight: 'leading-fluid-line-34',
        weight: 'font-bold',
        UpdatedColor: 'text-black',       // Default color
        fontFamily: 'font-manrope', // Font family
        letterSpacing: '-3%',      // Letter spacing
        defaultAlignment: 'text-left', // Default alignment
      },

              //step 3 heading as per figma design
              h3: {
                size: 'text-fluid-19',
                lineHeight: 'leading-fluid-line-23',
                weight: 'font-medium',
                UpdatedColor: 'text-black',       // Default color
                fontFamily: 'font-manrope', // Font family
                letterSpacing: '-2%',      // Letter spacing
                defaultAlignment: 'text-left', // Default alignment
              },


              //step 4 heading as per figma design
              h2: {
                size: 'text-fluid-25',
                lineHeight: 'leading-fluid-line-30',
                weight: 'font-bold',
                UpdatedColor: 'text-black',       // Default color
                fontFamily: 'font-manrope', // Font family
                letterSpacing: '-2.5%',      // Letter spacing
                defaultAlignment: 'text-left', // Default alignment
              },

//step 5
              h21: {
                size: 'text-fluid-26',
                lineHeight: 'leading-fluid-line-31',
                weight: 'font-bold',
                UpdatedColor: 'text-white',       // Default color
                fontFamily: 'font-manrope', // Font family
                letterSpacing: '-2.5%',      // Letter spacing
                defaultAlignment: 'text-left', // Default alignment
              },

                            //step 2 heading as per figma design
                            h31: {
                                size: {
                                  lg: 'lg:text-[28.13px]', // Large screens
                                  md: 'md:text-[18.45px]', // Medium screens
                                  sm: 'text-[15.75px]',     // Small screens (default)
                                },
                                lineHeight: {
                                  lg: 'lg:leading-[45px]', // Large screens
                                  md: 'md:leading-[29.52px]', // Medium screens
                                  sm: 'leading-[22.05px]',    // Small screens (default)
                                },
                                weight: 'font-semibold',
                                UpdatedColor: 'text-black',       // Default color
                                fontFamily: 'font-manrope', // Font family
                                defaultAlignment: 'text-left', // Default alignment
                              },
      // Define more heading styles as needed...
    },
    paragraphs: {

        //step 2
      p1: {
        size: 'text-fluid-17',
        lineHeight: 'fluid-line-24',
        weight: 'font-normal',
        Updatedcolor: 'text-black',       // Default color
        fontFamily: 'font-manrope', // Font family
        defaultAlignment: 'text-left'
      },

//step1
      p2: {
        size: 'text-fluid-15',
        lineHeight: 'fluid-line-22',
        weight: 'font-normal',
        Updatedcolor: 'text-black',       // Default color
        fontFamily: 'font-manrope', // Font family
        defaultAlignment: 'text-left'
      },


      
//step0
p3: {
    size: 'text-fluid-14-18',
    lineHeight: 'fluid-line-16',
    weight: 'font-semibold',
    Updatedcolor: 'text-black',       // Default color
    fontFamily: 'font-manrope', // Font family
    defaultAlignment: 'text-left'
  },
      // Define more paragraph styles as needed...
    },
  };
  
  export default typographyConfig;
  