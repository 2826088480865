import React from 'react';
import { Box } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AkspCards = ({ topHead, para }) => {


    return (
        <Box className='lg:h-[622px] h-auto bg-topGreen lg:py-60px lg:px-60px md:px-6 md:py-8 
        rounded-[30px] py-6 px-3'>

            <CustomTypography
                as='h3' variant='h3' color='text-white' weightFont='font-medium'
                alignment='lg:text-left text-center'
            >
               {topHead}
            </CustomTypography>


            <CustomTypography className='lg:pt-90px pt-7'
                as='p' variant='p2' color='text-white' weightFont='font-normal'
                alignment='lg:text-left text-center'
            >
               {para}
            </CustomTypography>

        </Box>
    )

}

export default AkspCards