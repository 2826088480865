import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import WorkBanner from "../pictures/Our_work_banner.png";
import WorkAkhuwat from "../pictures/Our_work_akhuwat.png";
import interest from "../pictures/Our_work_interest.png";
import Health from "../pictures/Our_work_health.png";
import clothes from "../pictures/Our_work_clothes.png";
import community from "../pictures/Our_work_community.png";

import crisis from "../pictures/Our_work_crisis.png";

const OurWork = () => {
    return (
        <Box className="pt-60px pb-[140px]">

            <CustomTypography
                as='h2' variant='h21' color='text-customBlack' weightFont='font-bold' alignment='text-center'
            >
                Our Work
            </CustomTypography>


            <CustomTypography className="lg:pt-10 md:pt-10 pt-6 lg:px-[240px] px-10 px-4"
                as='p' variant='p2' color='text-topGray' weightFont='font-normal' alignment='text-center'
            >
                Akhuwat gives interest-free loans, educates deserving students, and supports the
                underprivileged in our society.
            </CustomTypography>



            {/* Pic section */}


            <Box
                className="lg:h-[688px] md:h-[426px] h-[245px] bg-cover bg-center relative lg:mt-[80px]
                md:mt-10 mt-6"
                style={{
                    backgroundImage: `url(${WorkBanner})
                    `
                }}
            >

            </Box>

            {/* Approach section */}

            <Box className="max-w-screen-2xl mx-auto lg:py-60px md:py-60px lg:px-120px md:px-10 px-4 py-6 lg:mt-80px d:mt-60px mt-10">

                <CustomTypography
                    as='h2' variant='h2' color='text-customGreen' weightFont='font-bold' alignment='text-center'
                >
                    Akhuwat's Holistic Approach
                </CustomTypography>


                <CustomTypography className="pt-10"
                    as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='text-center'
                >
                    Akhuwat is dedicated to alleviating poverty and empowering marginalized communities in
                    Pakistan through a diverse range of programs. These programs are guided by the principle
                    of Mawakhat (Solidarity between the haves and have-nots) and seek to uplift individuals
                    and families by providing them with the resources and opportunities to improve their lives.

                </CustomTypography>

                <Box className="flex justify-center">
                    <img src={WorkAkhuwat} alt="Akhuawat" />
                </Box>




            </Box>



            {/* Interest section */}

            <Box className="max-w-screen-2xl mx-auto lg:px-120px lg:py-60px md:py-60px md:px-10 py-6 px-4">

                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6">

                    <Box className="lg:hidden flex flex-col justify-center">

                        <img src={interest} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-AesHead' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Interest-free Microloans
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Akhuwat's flagship initiative, Akhuwat Islamic Microfinance (AIM)  has been the
                            cornerstone of our efforts since its launch in 2001. It started with disbursing two loans
                            of PKR 10,000 in April 2001. Later, in May 2003, Akhuwat was registered under the
                            Societies Act as a Not-for-Profit Organisation. Till 2023, Akhuwat disbursed PKR 200
                            billion since its inception in interest-free loans. Akhuwat Islamic Microfinance (AIM)
                            offers interest-free loans to low-income individuals and enables them to start small
                            businesses and achieve financial independence. By supporting entrepreneurship, this
                            program helps break the cycle of poverty and promotes sustainable economic development.


                        </CustomTypography>

                    </Box>

                    <Box className="lg:block hidden">

                        <img src={interest} alt="interset" />

                    </Box>

                </Box>

                {/* Edu section */}


                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6 lg:pt-120px
                md:pt-60px pt-6">

                    <Box className="flex flex-col justify-center">

                        <img src={interest} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-AhsPrimary' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Access to Education
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Akhuwat Education Services (AES) was launched in 2015 with the inauguration of Akhuwat College, Kasur.
                            Later that year, Akhuwat Adopted the Narain Jagannath Vaidya (NJV) High School in Sindh in partnership
                            with the Sindh Government. Akhuwat Education Services (AES) provides fee-free education to deserving
                            students across Pakistan. With a network of schools and colleges, Akhuwat ensures that children from
                            underserved communities have access to quality education, nurturing the next generation of leaders.


                        </CustomTypography>

                    </Box>



                </Box>

                {/** distirbution section */}
                <Box className="lg:pt-120px md:pt-60px pt-6">

                    <img src={clothes} alt="clothes" />
                    <Box className="grid lg:grid-cols-2 grid-cols-1 gap-10 pt-10">

                        <CustomTypography
                            as='h2' variant='h2' color='text-BrightOrange' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Clothes Distribution Initiative
                        </CustomTypography>


                        <CustomTypography
                            as='p' variant='p3' color='text-DarkBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Launched in 2014, Akhuwat Clothes Bank (ACB) was founded on the philosophy of
                            Mawakhat, aiming to create a society that is equal and compassionate. It
                            seeks to bridge the gap between excess and need, promoting a culture of
                            responsible consumption and compassionate sharing. Overall, Akhuwat Clothes
                            Bank (ACB) signifies a shift towards a conscious lifestyle that thrives on
                            the principles of sharing and recycling.  This initiative not only provides
                            warmth and comfort but also restores dignity to those who might otherwise
                            go without.




                        </CustomTypography>

                    </Box>


                </Box>


                {/* Transgender sectoin */}



                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6 lg:pt-120px md:pt-60px pt-6">

                    <Box className="lg:hidden flex flex-col justify-center">

                        <img src={interest} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-AesHead' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Empowering Transgender Individuals
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Launched in 2011, the Akhuwat Khwajasira Support Program (AKSP) facilitates transgenders in different
                            areas of their lives and has registered more than 2000+ transgenders. AKSP also provides Khwajasiras with
                            health services, psycho-social therapy, and monthly income support. Through skills training,
                            microfinance, and social support, this program helps Khwajasiras (transgender individuals) lead lives
                            of dignity and self-reliance.



                        </CustomTypography>

                    </Box>

                    <Box className="lg:block hidden">

                        <img src={interest} alt="interset" />

                    </Box>

                </Box>

                {/* Health care section */}


                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6 lg:pt-120px
md:pt-60px pt-6">

                    <Box className="flex flex-col justify-center">

                        <img src={Health} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-AhsPrimary' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Access to Healthcare
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Akhuwat launched its Health Services (AHS) program in 2009 by setting up a Health Centre in Township,
                            Lahore. AHS focuses on providing affordable healthcare to those in need and offers subsidized medicines
                            (e.g., diabetes injections and footwear), lab tests, free examination, consultation, and nursing
                            services. Through clinics and medical camps, this program offers medical treatment, preventive care,
                            and health education to communities that lack access to basic healthcare facilities.


                        </CustomTypography>

                    </Box>



                </Box>

                {/* Stengethn communities */}


                {/** distirbution section */}
                <Box className="lg:pt-120px md:pt-60px pt-6">

                    <img src={community} alt="clothes" />
                    <Box className="grid lg:grid-cols-2 grid-cols-1 gap-10 pt-10">

                        <CustomTypography
                            as='h2' variant='h2' color='text-RuralPrimary' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Strengthening Communities
                        </CustomTypography>


                        <CustomTypography
                            as='p' variant='p3' color='text-DarkBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Since 2014, Akhuwat Community Development Program has worked to strengthen
                            community ties and promote social cohesion through various initiatives.
                            Akhuwat implements a participatory development approach involving the local
                            community to identify problems and design localized development solutions.
                            Under this program, Akhuwat has initiated two community development programs:
                            Rural Development (Kasur Mawakhat Program) and Urban Development
                            (The Mustafabad Mawakhat Project).





                        </CustomTypography>

                    </Box>


                </Box>

                {/* Crisis seciotn */}


                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6 lg:pt-120px
md:pt-60px pt-6">

                    <Box className="flex flex-col justify-center">

                        <img src={crisis} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-Disaster' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Responding to Crises
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Akhuwat is committed to helping people out in times of crisis, whether it involves financial stress,
                            inability to pursue education due to monetary reasons, or natural disasters. Akhuwat Relief and Disaster
                            Management Program specifically works in times of natural disasters, such as earthquakes, floods, and
                            COVID-19. From distributing food and shelter to rebuilding homes and livelihoods, this program plays a
                            crucial role in helping affected communities recover and rebuild.



                        </CustomTypography>

                    </Box>



                </Box>

                {/* Poverty section */}

                <Box className="grid lg:grid-cols-2  grid-cols-1 lg:gap-120px md:gap-11 gap-6 lg:pt-120px
md:pt-60px pt-6">

                    <Box className="lg:hidden flex flex-col justify-center">

                        <img src={WorkAkhuwat} alt="interset" />

                    </Box>

                    <Box className="flex flex-col justify-center">

                        <CustomTypography
                            as='h2' variant='h2' color='text-customGreen' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Addressing Poverty through Mawakhat
                        </CustomTypography>


                        <CustomTypography className="pt-11"
                            as='p' variant='p3' color='text-customBlack' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Together, these programs form a holistic approach to poverty alleviation, addressing the multiple
                            aspects of marginalization and helplessness faced by underprivileged communities in Pakistan. The guiding
                            principle behind Akhuwat's vision for development is "Mawakhat," or solidarity. Akhuwat pursues this by
                            fostering the charitable aspects of faith, encouraging the spirit of volunteerism, and transforming its
                            beneficiaries into agents of change.



                        </CustomTypography>

                    </Box>

                    <Box className="lg:block hidden">

                        <img src={WorkAkhuwat} alt="interset" />

                    </Box>

                </Box>

            </Box>

        </Box>
    )
}

export default OurWork