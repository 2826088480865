import { Box} from "@material-ui/core";
import React, { useEffect } from "react";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import FounderVideos from "../Founder/FounderVideos";
import NewsBanner from "../pictures/Media_news_banner.png";
import News1 from "../pictures/Media_news1.png";
import News2 from "../pictures/Media_news2.png";
import News3 from "../pictures/Media_news3.png";
import FounderIconArrow from "../pictures/founder-arrow-icon.png";
import MediaPubl from "../pictures/Media_publication.png";
import MediaPaper1 from "../pictures/Media_paper1.png";
import MediaPaper2 from "../pictures/Media_paper2.png";
import MediaPaper3 from "../pictures/Media_paper3.png";
import { Link } from "react-router-dom";
import heroVideo from '../pictures/hero_video.mp4';




const AkhuwatMedia = () => {

   
    return (

        <Box className="max-w-screen-2xl mx-auto">
            {/* Library section */}

            <Box className="lg:pt-80px lg:px-120px lg:pb-120px bg-white md:pt-60px md:pb-60px md:px-10 
            pt-6 pb-6 px-4">

                <Box className="lg:py-90px  md:py-52px py-0">

                    <CustomTypography
                        as='h2' variant='h21' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                    >
                        Akhuwat Media Library
                    </CustomTypography>


                    <CustomTypography className="pt-2"
                        as='p' variant='p2' color='text-blackColor' weightFont='font-semibold' alignment='text-center'
                    >
                        Insights, Updates, and Stories of Akhuwat
                    </CustomTypography>



                    <CustomTypography className="pt-6"
                        as='p' variant='p3' color='text-blackColor' weightFont='font-normal' alignment='text-center'
                    >
                        Here you will find a comprehensive collection of news, blogs, videos, publications,
                        and research papers that showcase our efforts, stories, and impact across various
                        programs in Pakistan. Stay informed and inspired as we share the progress,
                        challenges, and lessons that reflect our mission to create a poverty-free
                        society.
                    </CustomTypography>


                    <Box className="lg:pt-120px md:pt-120px pt-52px  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-3
                    gap-6">

<a href="#news-section">
                        <Box className="py-4 px-8 flex items-center justify-center rounded-full h-[50px] border border-SlateGray
                        cursor-pointer bg-white ">

                            <CustomTypography className="text-[14px] leading-[17.28px]"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                            >
                                News
                            </CustomTypography>

                        </Box>
                        </a>



                        <a href="#blog-section">
                        <Box className="py-4 px-8 flex items-center justify-center rounded-full h-[50px] border border-SlateGray
                        cursor-pointer bg-white ">

                            <CustomTypography className="text-[14px] leading-[17.28px]"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                            >
                                Blogs
                            </CustomTypography>

                        </Box>
                        </a>




                     <a href="#video-section">
                        <Box className="py-4 px-8 flex items-center justify-center rounded-full h-[50px] border border-SlateGray
                        cursor-pointer bg-white "
                        >


                            <CustomTypography className="text-[14px] leading-[17.28px]"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                            >
                             Videos
                            </CustomTypography>

                        </Box>
                        </a>



                        <a href="#publication-section">
                        <Box className="py-4 px-8 flex items-center justify-center rounded-full h-[50px] border border-SlateGray
                        cursor-pointer bg-white ">

                            <CustomTypography className="text-[14px] leading-[17.28px]"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                            >
                                Publications
                            </CustomTypography>

                        </Box>

                        </a>



                        <a href="#research-section">
                        <Box className="py-4 px-8 flex items-center justify-center rounded-full h-[50px] border border-SlateGray
                        cursor-pointer bg-white ">

                            <CustomTypography className="text-[14px] leading-[17.28px]"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-bold' alignment='text-center'
                            >
                                Research Papers
                            </CustomTypography>

                        </Box>
                        </a>

                    </Box>
                </Box>


            </Box>


            {/* News section */}

            <Box className="lg:py-60px lg:px-120px  bg-white md:py-60px md:px-10  py-6 px-4"
            id="news-section">

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
                    <Box>


                        <CustomTypography
                            as='h2' variant='h2' color='text-blackCoor' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            News
                        </CustomTypography>


                        <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Find the latest news and updates about Akhuwat’s Programs and our achievements.
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-center items-center">

                        <CustomButton btnText="Explore More News" btnURL="/about" btnColor="white"
                            btnBackground="customBlack" />
                    </Box>

                </Box>



                {/* Idea section... */}

                <Box id="news-section" >



                    <Box className='pt-60px grid lg:grid-cols-3  grid-cols-1 
                    gap-8 lg:h-[410px]'>
                        <Box className='lg:col-span-2 '>
                            <img src={NewsBanner} alt="ideas" />
                        </Box>

                        <Box className='lg:col-span-1 flex flex-col justify-center'>
                            <h1 className='font-extrabold  font-manrope text-[14px] leading-[14px]  text-topGreen'>
                                Parwaaz program</h1>

                            <CustomTypography className="pt-3"
                                as='p' variant='p1' color='text-customBlack' weightFont='font-semibold'
                            >
                                The Akhuwat Fellowship Program - 2024

                            </CustomTypography>

                            <CustomTypography className="pt-3"
                                as='p' variant='p3' color='text-topGray' weightFont='font-normal'
                            >
                                The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                and moral leaders, committed to making a difference.

                            </CustomTypography>

                            <Box className=" flex justify-start  pt-3">
                                <Link
                                    to="/about"
                                    className="flex items-center justify-center  "
                                >

                                    <CustomTypography
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                    >
                                        Read more

                                    </CustomTypography>

                                    <img className='ml-2 relative w-6 h-6' src={FounderIconArrow} alt="arrow" />
                                </Link>
                            </Box>
                        </Box>
                    </Box>






                    {/* Founder video */}

                    <Box className='pt-80px'>
                        <Box className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10'>
                            <FounderVideos thumbnail={News1} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                 and moral leaders, 
                                 committed to making a difference."
                                titleColor="topGreen"
                                paraColor="text-SlateGray" />

                            <FounderVideos thumbnail={News2} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                 and moral leaders, 
                                 committed to making a difference."
                                titleColor="topGreen"
                                paraColor="text-SlateGray" />


                            <FounderVideos thumbnail={News3} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                 and moral leaders, 
                                 committed to making a difference."
                                titleColor="topGreen"
                                paraColor="text-SlateGray" />
                        </Box>



                    </Box>


                </Box>




                {/* */}

            </Box>



            {/* blog section */}

            <Box className="lg:py-60px lg:px-120px  bg-white md:py-60px md:px-10  py-6 px-4">

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
                    <Box>
                        <CustomTypography
                            as='h2' variant='h2' color='text-blackCoor' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Blogs
                        </CustomTypography>


                        <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Here you can find the latest blogs about Akhuwat’s initiatives and programs and gather insights about our work and the lessons we learn in the field.
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-center items-center">

                        <CustomButton btnText="View all" btnURL="/about" btnColor="white"
                            btnBackground="customBlack" />
                    </Box>

                </Box>



                {/* Idea section... */}

                <Box  id="blog-section">



                    <Box className='pt-60px grid lg:grid-cols-3  grid-cols-1 
    gap-8 lg:h-[410px]'>
                        <Box className='lg:col-span-2 '>
                            <img src={NewsBanner} alt="ideas" />
                        </Box>

                        <Box className='lg:col-span-1 flex flex-col justify-center'>
                            <h1 className='font-extrabold  font-manrope text-[14px] leading-[14px]  text-topGreen'>
                                Parwaaz program</h1>

                            <CustomTypography className="pt-3"
                                as='p' variant='p1' color='text-customBlack' weightFont='font-semibold'
                            >
                                The Akhuwat Fellowship Program - 2024

                            </CustomTypography>

                            <CustomTypography className="pt-3"
                                as='p' variant='p3' color='text-topGray' weightFont='font-normal'
                            >
                                The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                and moral leaders, committed to making a difference.

                            </CustomTypography>

                            <Box className=" flex justify-start  pt-3">
                                <Link
                                    to="/about"
                                    className="flex items-center justify-center  "
                                >

                                    <CustomTypography
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                    >
                                        Read more

                                    </CustomTypography>

                                    <img className='ml-2 relative w-6 h-6' src={FounderIconArrow} alt="arrow" />
                                </Link>
                            </Box>
                        </Box>
                    </Box>






                    {/* Founder video */}

                    <Box className='pt-80px' id="video-section">
                        <Box className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10'>
                            <FounderVideos thumbnail={News1} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                 and moral leaders, 
                 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />

                            <FounderVideos thumbnail={News2} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                 and moral leaders, 
                 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />


                            <FounderVideos thumbnail={News3} videoTitle="PARWAZ PROGRAM"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                 and moral leaders, 
                 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />
                        </Box>



                    </Box>


                </Box>




                {/* */}

            </Box>




            {/* Video section */}

            <Box className="lg:py-60px lg:px-120px  bg-white md:py-60px md:px-10  py-6 px-4">

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
                    <Box>


                        <CustomTypography
                            as='h2' variant='h2' color='text-blackCoor' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Videos
                        </CustomTypography>


                        <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Find interviews, donation calls, and documentaries about the various programs of Akhuwats, beneficiaries, students, and khwajasiras.
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-center items-center">

                        <CustomButton btnText="Watch on Youtube" btnURL="/about" btnColor="white"
                            btnBackground="customBlack" />
                    </Box>

                </Box>



                {/* Idea section... */}

                <Box >



                    <Box className='pt-60px grid lg:grid-cols-3  grid-cols-1 
gap-8 lg:h-[410px]'>
                        <Box className='lg:col-span-2 '>
                            <img src={NewsBanner} alt="ideas" />
                        </Box>

                        <Box className='lg:col-span-1 flex flex-col justify-center'>
                            <h1 className='font-extrabold  font-manrope text-[14px] leading-[14px]  text-topGreen'>
                                Parwaaz program</h1>

                            <CustomTypography className="pt-3"
                                as='p' variant='p1' color='text-customBlack' weightFont='font-semibold'
                            >
                                The Akhuwat Fellowship Program - 2024

                            </CustomTypography>

                            <CustomTypography className="pt-3"
                                as='p' variant='p3' color='text-topGray' weightFont='font-normal'
                            >
                                The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
                                and moral leaders, committed to making a difference.

                            </CustomTypography>

                            <Box className=" flex justify-start  pt-3">
                                <Link
                                    to="/about"
                                    className="flex items-center justify-center  "
                                >

                                    <CustomTypography
                                        as='p' variant='p3' color='text-customBlack' weightFont='font-semibold'
                                    >
                                        Read more

                                    </CustomTypography>

                                    <img className='ml-2 relative w-6 h-6' src={FounderIconArrow} alt="arrow" />
                                </Link>
                            </Box>
                        </Box>
                    </Box>






                    {/* Founder video */}

                    <Box className='pt-80px'>
                        <Box className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10'>
                            <FounderVideos thumbnail={News1} videoTitle="Education"
                            heroVideo={heroVideo}
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
 and moral leaders, 
 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />

                            <FounderVideos thumbnail={News2} videoTitle="Education"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
 and moral leaders, 
 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />


                            <FounderVideos thumbnail={News3} videoTitle="Education"
                                head="The Akhuwat Fellowship Program - 2024"
                                para="The Akhuwat Fellowship Program seeks to cultivate a community of creative entrepreneurs
 and moral leaders, 
 committed to making a difference."
                                titleColor="text-topGreen"
                                paraColor="text-SlateGray" />
                        </Box>



                    </Box>


                </Box>




                {/* */}

            </Box>



            {/* Publication  section */}

            <Box className="lg:py-60px lg:px-120px  bg-white md:py-60px md:px-10  py-6 px-4"
            id="publication-section">

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
                    <Box>


                        <CustomTypography
                            as='h2' variant='h2' color='text-blackCoor' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                            Publications
                        </CustomTypography>


                        <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                            Find our latest publications in the form of booklets, books, and reports that
                            give an in-depth understanding of our programs and the kind of impact we are
                            making in the lives of people we collaborate with.
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-center items-center">

                        <CustomButton btnText="View all" btnURL="/about" btnColor="white"
                            btnBackground="customBlack" />
                    </Box>

                </Box>

                <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-60px">
                    <Box>
                        <img src={MediaPubl} alt="publication" />
                        <FounderVideos
                            head="Mawakhat "
                            para="Lorem ipsum dolor sit amet consectetur. Nunc integer sed vulputate."
                            paraColor="text-SlateGray" />

                    </Box>

                    <Box>
                        <img src={MediaPubl} alt="publication" />
                        <FounderVideos
                            head="Akhuwat Booklet 2023 "
                            para="Lorem ipsum dolor sit amet consectetur. Nunc integer sed vulputate."
                            paraColor="text-SlateGray" />

                    </Box>



                    <Box>
                        <img src={MediaPubl} alt="publication" />
                        <FounderVideos
                            head="2022 Floods Report"
                            para="Lorem ipsum dolor sit amet consectetur. Nunc integer sed vulputate."
                            paraColor="text-SlateGray" />

                    </Box>

                </Box>
            </Box>





            
            {/* Research paper  section */}

            <Box className="lg:py-60px lg:px-120px  bg-white md:py-60px md:px-10  py-6 px-4"
            id="research-section">

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-8">
                    <Box>


                        <CustomTypography
                            as='h2' variant='h2' color='text-blackCoor' weightFont='font-bold' alignment='lg:text-left text-center'
                        >
                          Research Papers
                        </CustomTypography>


                        <CustomTypography className="pt-3"
                            as='p' variant='p2' color='text-blackColor' weightFont='font-normal' alignment='lg:text-left text-center'
                        >
                          Here you will find the latest research papers by different independent scholars
                           and researchers in the field of microfinance, free education, and community 
                           development, who study and analyze our projects and share their understanding 
                           of our work. 
                        </CustomTypography>

                    </Box>

                    <Box className="flex lg:justify-end justify-center items-center">

                        <CustomButton btnText="View all" btnURL="/about" btnColor="white"
                            btnBackground="customBlack" />
                    </Box>

                </Box>

                <Box className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-60px">
                    <Box className="bg-customGray rounded-[16px] p-4">
                        <img src={MediaPaper1} alt="publication" />
                        <FounderVideos
                        videoTitle="June 8, 2024"
                           
                            para="Measuring The Performance And Achievement Of Microfinance Institutions 
                            Incorporating Subsidy Dependence Index And Outreach Index In Pakistan’s case"
                            paraColor="text-topGreen" />

                    </Box>

                    <Box className="bg-customGray rounded-[16px] p-4">
                        <img src={MediaPaper2} alt="publication" />
                        <FounderVideos
                             videoTitle="June 8, 2024"
                            para="Interest Free Microfinance And Impact On Poverty Alleviation"
                            paraColor="text-topGreen"  />

                    </Box>



                    <Box className="bg-customGray rounded-[16px] p-4">
                        <img src={MediaPaper3} alt="publication" />
                        <FounderVideos
                         videoTitle="June 8, 2024"
                         para="The Social And Financial Performance Of Conventional And Islamic Microfinance Institutions In Pakistan"
                         paraColor="text-topGreen"  />
                    </Box>

                </Box>
            </Box>







        </Box>
    )
}

export default AkhuwatMedia