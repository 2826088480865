import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import AimBanner from '../pictures/Aim-banner.png'
import BannerDesign from './BannerDesign';
import AimBussinesCard from './AimBussinesCard';
import AimBusiness from '../pictures/aimsmall-bs-icon.jfif'
import AimbsIcon from '../pictures/aimsmall-bs-icon.svg'
import AimHouse from '../pictures/aim-house-icon.svg'
import AimFormer from '../pictures/aim-former-icon.svg'
import AimAgriculturePicture from '../pictures/aim_agri.png'
import AimHouseImage from '../pictures/aim_house.png'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AimInterest from '../pictures/aim-interest.png'
import AimCulture from '../pictures/aim-culture.jfif'
import AimWork from './AimWork';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AimStories from './AimStories';
import AimStoryPic from "../pictures/aim-stories.png";
import AimStoryComma from "../pictures/aim-stories-comma.png"
import AimTestm1 from "../pictures/aim-testimonial-pic.png"
import Testimonial from './Testimonial';
import AimBannerPatern from "../pictures/aim-banner-pattern.png"
import OurPartener from '../OurPartener';
import AimNumber from './AimNumber';
import DonateNowSetion from '../ReusableComponents/DonateNowSection';
import CustomButton from '../ReusableComponents/CustomButton';
import AimAppElipse from '../pictures/aim-app-elipse.png'
import AimAppElipseMobile from '../pictures/aim-elipse-mobile.png'
import AhsDoctor1 from '../pictures/ahs-doctor-pic1.png';
import CustomTypography from '../CustomTypography';
import CustomCarousel from '../CustomCarasouel';

const Aim = () => {

    const AimCards = [
        <AimBussinesCard CardImg={AimBusiness} iconPic={AimbsIcon}
            head="Enterprise loans for supporting small-scale businesses" />,

        <AimBussinesCard CardImg={AimAgriculturePicture} iconPic={AimFormer}
            head="Agriculture loans for small-scale farmers" />,

        <AimBussinesCard CardImg={AimHouseImage} iconPic={AimHouse}
            head="Housing loans for construction, addition, or repair of house" />,
    ]

    const AimWorkCards = [

        <AimWork frontHead="Interest-free loans"
            backPara="Interest-free loans help empower and do not exert additional financial burden on our beneficiaries." />,

        <AimWork frontHead="Cycle of Reciprocity"
            backPara="We empower our borrowers, so that they can become donors to our cause and help us fight poverty." />,


        <AimWork frontHead="Cultural Context"
            backPara="Due to cultural values, interest-free loans have been the norm for centuries. That’s why AIM has a 99.1% recovery rate." />,
        <AimWork frontHead="Diversity and Inclusion"
            backPara="AIM conducts its operations without any religious or political affiliations and gives loans without any kind of discrimination." />,
        <AimWork frontHead="Use of Religious Places"
            backPara="AIM operates its loan drives from religious places, such as mosques, temples, and churches in the spirit of welfare and Mawakhat (solidarity)." />,
    ]


    const AimTestimonial = [


        <Testimonial personImage={AimTestm1} para="“Akhuwat is unique because it breaks many of the 
        generally accepted rules of microfinance, but has nevertheless (or perhaps for that reason) 
        survived and grown. In the present crisis of opinion and reputation which is swirling around 
        microfinance … Akhuwat shows that it is possible to choose a totally different paradigm, 
        and still grow and serve large numbers of poor people.”"
            bottomHead="Malcolm Harper" personDetails="Emeritus Professor at Cranfield School of Management" />,


        <Testimonial personImage={AimTestm1} para="
“The board of trustees (Ramon Magsaysay Award Foundation) recognizes the intelligence and compassion that enabled him (Dr. Amjad Saqib) to create the largest microfinance institution (Akhuwat) in Pakistan; his inspiring belief that human goodness and solidarity will find ways to eradicate poverty; and his determination to stay with a mission that has already helped millions of Pakistani families.”
"
            bottomHead="Ramon Magsaysay" personDetails="The Ramon Magsaysay Award Foundation " />,


        <Testimonial personImage={AimTestm1} para="“Akhuwat stands as a living embodiment of the values of universal brotherhood: Iman (faith), Ihsan (excellence), Ikhlas (sincerity), and Infaq (giving). Akhuwat's provision of interest-free loans to flood victims has been instrumental in helping people reconstruct their homes and aspirations. Having worked with underprivileged communities, I remain in awe of Akhuwat’s efforts and message which continue to transform the lives of millions of people across Pakistan.”"
            bottomHead="Dr. Sohail Chughtai" personDetails="Chairman, Chughtai Foundation" />,
    ]



    const [currentSlide1, setCurrentSlide1] = useState(0);


    const totalSlides = 4; // Total number of slides in the carousel

    const handleNext = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrev = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };



    return (
        <Box>

            <Box>
                <BannerDesign imageURL={AimBanner} headPart1={'Akhuwat Islamic'} headPart2={'Microfinance (AIM)'} backColor="white"
                    belowHead="What is Microfinance?"
                    belowPara1="Microfinance refers to the provision of financial services to deserving 
                    and low-income individuals. Microfinance Institutions (MFI) give small loans to 
                    excluded and marginalized people to promote self-sufficiency among them while following
                     ethical lending practices."

                    belowheadColor="text-customGreen"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={AimBannerPatern}
                    donateBtn="yes"
                />



            </Box>

            {/* Aim impact numbers */}

            <Box>
                <AimNumber />
            </Box>

            {/* What Aim do?  */}
            <Box>
                <Box className='h-auto bg-white lg:py-90px md:py-90px md:px-10 py-52px lg:px-120px px-4'>
                    <Box className='max-w-screen-2xl mx-auto'>
                        <CustomTypography as="h2" variant="h2" color="text-customBlack " weightFont="font-bold"
                            alignment='lg:text-left md:text-left text-center'
                        >
                            Introduction of Akhuwat Islamic Microfinance (AIM)
                        </CustomTypography>


                        <CustomTypography className="pt-10 " as="p" variant="p2" color="text-SlateGray"
                            weightFont="font-semibold" alignment='lg:text-left md:text-left text-center'>
                            Akhuwat Islamic Microfinance (AIM) was founded in 2001 as a tool for poverty alleviation.
                            AIM provides interest-free/Shariah-compliant loans (Qarz-e-Hasan) to individuals and
                            families who are deprived of financial inclusion and supports them in creating sustainable
                            pathways out of poverty.  <br></br> <br></br>
                            Operating across Pakistan, Akhuwat’s microfinance program is the world’s largest
                            interest-free microfinance program and provides the following services:
                        </CustomTypography>

                        <Box className='pt-90px lg:grid  lg:grid-cols-3   gap-5 hidden'>

                            {AimCards}
                        </Box>


                    </Box>
                </Box>

                {/* Carasouel for mobile */}
                <Box className='lg:hidden md:pb-90px pb-52px '>



                    {/* Carousel */}

                    <CustomCarousel slides={AimCards} slideWidth={{
                        lg: 580,
                        md: 346,
                        sm: 310
                    }} gap={20}
                        ArrowPosition='bottom'
                        ArrowActiveColor='#329A51'
                        ArrowInActiveColor='#A6A6A6'

                    />

                </Box>
            </Box>


            {/* Why it Works section  */}
            <Box className='bg-MintCream'>
                <Box className='lg:pt-120px pt-60px px-4 lg:px-[120px]  md:px-10 '>
                    <Box className='max-w-screen-2xl mx-auto'>
                        <CustomTypography
                            as='h2' variant='h2' color='text-customBlack' weightFont='font-bold'
                            alignment='lg:text-left text-center'
                        >
                            Why It Works
                        </CustomTypography>


                    </Box>
                </Box>



                <Box className='pt-7 lg:pt-16 md:pt-16  lg:pb-16 md:pb-16 pb-7 lg:pl-8'>

                    <CustomCarousel slides={AimWorkCards}
                        ArrowAligned='end'
                        ArrowPosition='bottom'
                        ArrowActiveColor='#329A51'
                        ArrowInActiveColor='#A6A6A6' />


                </Box>
            </Box>



            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] md:h-[500px] h-[421px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${AhsDoctor1})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='flex flex-col lg:justify-center md:justify-center justify-end h-full 
                lg:px-[128px] md:px-16 px-4 md:py-0 lg:py-0  lg:w-[900px] md:w-[500px] w-auto  py-6 
                max-w-screen-2xl '>

                    <p className='font-manrope text-white font-extrabold text-[14px] 
                    lg:leading-[14px] lg:text-left md:text-left text-center '>
                        AKHUWAT ISLAMIC MICROFINANCE</p>

                    <CustomTypography className='pt-2' as="h2" variant="h21" color="text-white " weightFont="font-bold"
                        alignment="lg:text-left md:text-left text-center"
                    >
                        The Impact of Akhuwat Micro-finance
                    </CustomTypography>

                    <Box className='pt-6 flex lg:justify-start md:justify-start justify-center'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>




            {/* Loan Process section */}

            <Box className='bg-customGreen lg:py-120px lg:px-140px md:py-120px md:px-10 py-52px px-4'>
                <Box className='max-w-screen-2xl mx-auto'> {/* max width applied */}
                    <Box>

                        <CustomTypography as="h2" variant="h2" color="text-topYellow" weightFont="font-bold"
                            alignment="text-center"
                        >
                            Akhuwat Loan Process
                        </CustomTypography>

                        <CustomTypography className="lg:pt-4"
                            as='p' variant='p3' color='text-white' weightFont='font-normal' alignment='text-center'

                        >
                            Akhuwat Islamic Microfinance (AIM) works under a specific eligibility criteria,
                            conducts social and business appraisal of the potential beneficiaries, and enlist
                            guarantors for loan disbursement.

                        </CustomTypography>


                        <Box className="flex justify-center pt-10">
                            <CustomButton btnText='View details' btnURL='/programs/loan/process' btnColor='customBlack' btnBackground='white' />
                        </Box>
                    </Box>

                    <Box className='lg:pt-80px pt-10 '>
                        <Box className='flex lg:flex-row md:flex-row flex-col gap-4 '>
                            {/* Application Text */}


                            {/* Application Image */}
                            <Box className='flex justify-center items-center' >


                                <CustomTypography className='absolute' as="h2" variant="h2" color="text-white" weightFont="font-bold" alignment="text-center">
                                    Application
                                </CustomTypography>




                                <img src={AimAppElipse} alt="elipse" className='lg:block md:block hidden  object-cover' /> {/* Adjusted size */}
                                <img src={AimAppElipseMobile} alt="elipse" className='lg:hidden md:hidden mt-[-120px] ' /> {/* Adjusted size for mobile */}
                            </Box>



                            {/* Appraisal Image */}
                            <Box className='flex justify-center items-center' >

                                <CustomTypography className='absolute' as="h2" variant="h2" color="text-white" weightFont="font-bold" alignment="text-center">
                                    Appraisal
                                </CustomTypography>
                                <img src={AimAppElipse} alt="elipse" className='lg:flex md:flex hidden  object-cover' /> {/* Adjusted size */}
                                <img src={AimAppElipseMobile} alt="elipse" className='lg:hidden md:hidden ' /> {/* Adjusted size for mobile */}
                            </Box>

                            {/* Approval Text */}



                            <Box className='flex justify-center items-center' >
                                <CustomTypography className='absolute' as="h2" variant="h2" color="text-white" weightFont="font-bold" alignment="text-center">
                                    Approval
                                </CustomTypography>
                                <img src={AimAppElipse} alt="elipse" className='lg:block md:block hidden object-cover ' /> {/* Adjusted size */}
                                <img src={AimAppElipseMobile} alt="elipse" className='lg:hidden md:hidden ' /> {/* Adjusted size for mobile */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>



            {/* Stories */}

            <Box className='bg-white lg:py-120px py-[52px] md:px-[72px] lg:px-140px px-4'>
                <Box className='max-w-screen-2xl mx-auto'> {/* max width applied */}
                    <Box className='grid lg:grid-cols-3 grid-cols-1' >
                        <Box className='lg:col-span-2 cols-span-1'>


                            <CustomTypography as="h2" variant="h2" color="text-customBlack " weightFont="font-bold"
                                alignment="text-center lg:text-left"
                            >
                                Stories of Hope and Inspiration
                            </CustomTypography>


                            <CustomTypography c className="pt-5 " as="p" variant="p2" color="text-customBlack"
                                weightFont="font-semibold" alignment="lg:text-left text-center">
                                How AIM Beneficiaries Change Their lives!
                            </CustomTypography>

                        </Box>
                        <Box className='col-span-1 lg:flex hidden items-center justify-end'>
                            <Box
                                onClick={handlePrev}
                                className={`cursor-pointer ${currentSlide1 === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                                style={{ borderColor: currentSlide1 === 0 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowLeftIcon className={`text-customBlack ${currentSlide1 === 0 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                            </Box>
                            <Box
                                onClick={handleNext}
                                className={`ml-4 cursor-pointer ${currentSlide1 === totalSlides - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                                style={{ borderColor: currentSlide1 === totalSlides - 1 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowRightIcon className={`text-customBlack ${currentSlide1 === totalSlides - 1 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='lg:pt-[80px] pt-10'>
                        <Carousel
                            selectedItem={currentSlide1}
                            onChange={(index) => setCurrentSlide1(index)}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            interval={5000}
                            showIndicators={false}
                            autoPlay
                            infiniteLoop
                            emulateTouch // Ensures smooth touch behavior
                            swipeable // Allows swipe gestures on mobile devices
                            width="100%" // Ensures full width on all devices
                        >
                            <AimStories gridImage={AimStoryPic} vectorImage={AimStoryComma}
                                para="“The biggest benefit of having a stable business is not having to ask 
                             for money from anyone; which is very liberating. My journey with Akhuwat 
                             began when I borrowed money to buy materials for the bags I made. Over the 
                             years I learned that I had to add more variety to my work so I got another,
                              bigger loan to buy more materials for various bags. Thankfully the idea was
                               successful and my business started doing well. Today, my house runs smoothly
                                and my children can attend school which is all I could ever ask for.”"
                                bottomHead="Irshad Ahmed, Chungi Amar Sidhu (Punjab)"
                            />

                            <AimStories gridImage={AimStoryPic} vectorImage={AimStoryComma}
                                para="“I was always worried about my business, even after taking a loan from 
                             the bank several times, my business was not making a profit. One day my friend
                              told me about Akhuwat interest-free loans. With interest-free loans, I 
                              bought goods for my shop. After sometimes my financial situation improved 
                              and I realized that success is only possible without interest.”"
                                bottomHead="Mohammad Nisar, Hasilpur (Punjab)"
                            />

                            <AimStories gridImage={AimStoryPic} vectorImage={AimStoryComma}
                                para="“Before this business, I was a taxi driver, and despite working
                                 sixteen hours straight, my income wasn't enough to support my family in
                                  a better way. One day my neighbor told me about the Akhuwat interest-free
                                   loans program. I Applied and within a few days, I got the loan amount. 
                                   With this amount, I bought shop supplies and started my own business. 
                                   Praise be to Allah! Within a short period, I started making a profit 
                                   and not only did my income increase but I was also able to provide a 
                                   better life for my family.”"
                                bottomHead="Inamullah, Lucky Marwat (KPK)"
                            />
                        </Carousel>

                        <Box className='flex lg:hidden items-center justify-center pt-10'>
                            <Box
                                onClick={handlePrev}
                                className={`cursor-pointer ${currentSlide1 === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                                style={{ borderColor: currentSlide1 === 0 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowLeftIcon className={`text-customBlack ${currentSlide1 === 0 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                            </Box>
                            <Box
                                onClick={handleNext}
                                className={`ml-4 cursor-pointer ${currentSlide1 === totalSlides - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                                style={{ borderColor: currentSlide1 === totalSlides - 1 ? '#A6A6A6' : '#2A2A2A' }}
                            >
                                <KeyboardArrowRightIcon className={`text-customBlack ${currentSlide1 === totalSlides - 1 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>


            {/* Paternership section */}

            <Box className='bg-primaryLight lg:py-140px py-52px lg:px-140px md:py-60px md:px-10 px-4'>
                <Box className='max-w-screen-2xl mx-auto'> {/* max width has been applied */}

                    <CustomTypography
                        as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' >
                        Akhuwat Partnerships

                    </CustomTypography>
                    <CustomTypography className='pt-6'
                        as='p' variant='p1' color='text-SlateGray' weightFont='font-bold'

                    >
                        <span className='mr-1 text-topGreen font-manrope font-normal lg:font-bold lg:text-[28px] 
                    text-[18px] lg:leading-[42px] leading-[27px]'>
                            Akhuwat  engages in impactful collaborations with local, international, and
                            public-private partners
                        </span>

                        to extend interest-free loans and empower families and
                        communities across Pakistan. Through these partnerships, Akhuwat strengthens
                        its mission of alleviating poverty and supporting those who need and deserve it.


                    </CustomTypography>


                    <Box className='lg:grid grid-cols-3 grid-cols-1 lg:gap-10  lg:pt-80px md:pt-11 pt-11'>
                        <Box>

                            <CustomTypography
                                as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' >
                                Public-Private Partnerships

                            </CustomTypography>


                            <CustomTypography className="pt-6"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                            >   These are the
                                partnerships between Akhuwat Islamic Microfinance (AIM)
                                and Govt. Organizations. In which Govt. provides funds and operational cost
                                while AIM manages all operations.
                            </CustomTypography>
                        </Box>

                        {/* 2nd */}

                        <Box className='lg:pt-0 pt-10'>

                            <CustomTypography
                                as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' >
                                International Partnerships

                            </CustomTypography>


                            <CustomTypography className="pt-6"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                            >
                                These are the
                                partnerships between Akhuwat Islamic Microfinance (AIM) and International
                                Organizations. In which organizations provide funds and operational cost
                                while AIM manages all operations.
                            </CustomTypography>
                        </Box>


                        {/* 3rd*/}

                        <Box className='lg:pt-0 pt-10'>

                            <CustomTypography
                                as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' >
                                Local Partnerships

                            </CustomTypography>


                            <CustomTypography className="pt-6"
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'

                            >
                                These are the partnerships between Akhuwat Islamic Microfinance (AIM) and
                                local organizations and philanthropists. In which local org. provides funds and
                                operational cost while AIM manages all operations.
                            </CustomTypography>

                        </Box>

                    </Box>



                </Box>
            </Box>


            {/* Testimonials section .. */}

            <Box className='bg-white lg:py-120px py-60px  shadow-custom-shadow lg:px-0 px-6'>
                <Box className='max-w-screen-2xl mx-auto'> {/* max width applied */}


                    {/* Carousel */}

                    <CustomCarousel slides={AimTestimonial} slideWidth={{
                        lg: 387,
                        md: 347,
                        sm: 310
                    }} gap={20}
                        ArrowPosition='top'
                        ArrowActiveColor='#329A51'
                        ArrowInActiveColor='#A6A6A6'
                        ArrowAligned='end'
                        sliderTitle="Testimonials"

                    />



                </Box>



            </Box>



            {/* Our partener section */}

            <Box>
                <OurPartener />
            </Box>



            {/* Donaate now section  */}



            <DonateNowSetion Head6="Akhuwat Islamic Microfinance "
                Head2="Help Someone. Donate Now." HeadColor2="text-white" HeadColor6="text-simpleGreen"
                backColor="customGreen" />

        </Box>
    )

}

export default Aim