import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";

const ProductDesign = ({ gridImg, productTitle, productDescription,loanRange }) => {
    return (

        <Box className="lg:py-80px lg:px-120px bg-white md:py-60px md:px-10 py-6 px-4">

            <Box className="grid lg:grid-cols-[auto_692px] grid-cols-2 gap-5">
                <CustomTypography as="h2" variant="h2" weightFont="font-bold" alignment="text-left" color="text-customBlack">
                    {productTitle}
                </CustomTypography>


                <Box className="lg:w-[692px]">

                    <CustomTypography className="md:hidden hidden lg:block" as="p" variant="p2" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                        {productDescription}
                    </CustomTypography>




                </Box>



            </Box>

            <CustomTypography className="pt-10 lg:hidden" as="p" variant="p2" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                {productDescription}
            </CustomTypography>



            <Box className="grid lg:grid-cols-[auto_692px] grid-cols-1 gap-5 pt-10">
                <Box className="h-full">
                    <img className="h-full w-full object-cover rounded-[30px]" src={gridImg} alt="img" />
                </Box>

                <Box className="lg:w-[692px] w-auto bg-MistyLilc rounded-[30px] lg:p-10 md:p-10 p-4">
                    <CustomTypography as="h2" variant="h2" weightFont="font-bold" alignment="text-left" color="text-SlateGray">
                        85-90%
                    </CustomTypography>

                    <Box className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-10 gap-5 pt-10">
                        <Box>
                            <CustomTypography as="p" variant="p3" weightFont="font-normal" alignment="text-left" color="text-SlateGray">
                                Duration
                            </CustomTypography>
                            <CustomTypography className="pt-[2px]" as="p" variant="p3" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                                10 – 36 months
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography as="p" variant="p3" weightFont="font-normal" alignment="text-left" color="text-SlateGray">
                                Range
                            </CustomTypography>
                            <CustomTypography className="pt-[2px]" as="p" variant="p3" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                               {loanRange}
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography as="p" variant="p3" weightFont="font-normal" alignment="text-left" color="text-SlateGray">
                                Profit Range
                            </CustomTypography>
                            <CustomTypography className="pt-[2px]" as="p" variant="p3" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                                0% Interest Rate
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography as="p" variant="p3" weightFont="font-normal" alignment="text-left" color="text-SlateGray">
                                Initial Application Fee
                            </CustomTypography>
                            <CustomTypography className="pt-[2px]" as="p" variant="p3" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                                Up to Rs. 200
                            </CustomTypography>
                        </Box>

                        <Box className="lg:col-span-2 md:col-span-2">
                            <CustomTypography as="p" variant="p3" weightFont="font-normal" alignment="text-left" color="text-SlateGray">
                                Mutual Support Fund Contribution
                            </CustomTypography>
                            <CustomTypography className="pt-[2px]" as="p" variant="p3" weightFont="font-semibold" alignment="text-left" color="text-SlateGray">
                                0% Interest Rate
                            </CustomTypography>
                        </Box>
                    </Box>
                </Box>
            </Box>



        </Box>
    )
}

export default ProductDesign