import { Box } from "@material-ui/core";
import React, { useState } from 'react';
import CustomTypography from "../CustomTypography";

const ParwazAfpTestimonials = ({AfpHead,AfpDesc}) => {

    return (
        <Box className="lg:w-[478px] bg-customGreen lg:py-60px lg:px-6 md:w-[321px] md:py-10 md:px-5 py-8 px-4
        rounded-[20px]">

            <CustomTypography  as="p" variant="p1" color="text-white" weightFont="font-semibold">
                {AfpHead}
            </CustomTypography>


      <CustomTypography className="lg:pt-10 pt-7"  as="p" variant="p3" color="text-white" weightFont="font-normal">
                {AfpDesc}
            </CustomTypography>

        </Box>
    )

}


export default ParwazAfpTestimonials