import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CustomTypography from '../CustomTypography';
import AcbFaq1 from '../pictures/acb-faq-pic.png';
import AcbFaq2 from '../pictures/Acb_faq_pic2.png';
import WorkImg from "../pictures/disaster-work-img.png";

const faqData = [
  {
    question: "Collection of goods/distribution of products.",
    answer: "The team of Akhuwat Clothes Bank collects donated clothing through their dedicated staff, as well as branches. These donations are collected and brought to Akhuwat’s Head Office where they are cleaned and packed with care. Akhuwat’s existing network of over 800 branches helps identify neighborhoods and families in need of clothing, household accessories and other gifts. Efforts are made to distribute clothes in the poorest areas of Pakistan, including, Southern Punjab, Interior Sindh, Gilgit-Baltistan and Baluchistan.",
    image: AcbFaq1,
    titleColor: 'text-customBlack'
  },
  {
    question: "Empowering Transgenders",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    image: AcbFaq2,
    titleColor: 'text-customBlack'
  },
  {
    question: "Akhuwat Clothes Bank Gift Shop",
    answer: "The team of Akhuwat Clothes Bank collects donated clothing through their dedicated staff, as well as branches. These donations are collected and brought to Akhuwat’s Head Office where they are cleaned and packed with care. Akhuwat’s existing network of over 800 branches helps identify neighborhoods and families in need of clothing, household accessories and other gifts. Efforts are made to distribute clothes in the poorest areas of Pakistan, including, Southern Punjab, Interior Sindh, Gilgit-Baltistan and Baluchistan.",
    image: AcbFaq1,
    titleColor: 'text-customBlack'
  },
  {
    question: "Dewar–e– Akhuwat",
    answer: "The team of Akhuwat Clothes Bank collects donated clothing through their dedicated staff, as well as branches. These donations are collected and brought to Akhuwat’s Head Office where they are cleaned and packed with care. Akhuwat’s existing network of over 800 branches helps identify neighborhoods and families in need of clothing, household accessories and other gifts. Efforts are made to distribute clothes in the poorest areas of Pakistan, including, Southern Punjab, Interior Sindh, Gilgit-Baltistan and Baluchistan.",
    image: AcbFaq2,
    titleColor: 'text-customBlack'
  },
];

const faqDataDisaster = [
  {
    question: "Monitor the Situation",
    answer: "Whenever a natural disaster or a catastrophe happens, our team becomes active to monitor the situation of the disaster and understand the ways in which people are getting impacted.",
    image: WorkImg,
    titleColor: 'text-topGreen'
  },
  {
    question: "Identify the Needs",
    answer: "We identify their immediate needs within the context of the natural disaster and begin procuring the material goods we can provide for immediate relief.",
    image: AcbFaq2,
    titleColor: 'text-topGreen'
  },
  {
    question: "Provide Relief",
    answer: "Once the immediate needs are determined and material goods procured, we begin providing relief measures to help and support the affectees in the best possible",
    image: WorkImg,
    titleColor: 'text-topGreen'
  },
];

//Akhuwat college Kasur faq...

const faqKasurCollegeData = [
  {
    question: "Transformative Education for All ",
    answer: "Akhuwat College Kasur is dedicated to providing high-quality education to students from diverse backgrounds, ensuring that financial constraints do not stop their access to learning. By offering free intermediate and undergraduate education, the college breaks down barriers and empowers students with the knowledge and skills they need to succeed. Donations and support help sustain this inclusive educational model, enabling the college to continue offering scholarships, quality teaching, and resources that transform the lives of students and their communities. ",
    image: WorkImg,
    titleColor: 'text-customBlack'
  },
  {
    question: "Modern Facilities ",
    answer: "We identify their immediate needs within the context of the natural disaster and begin procuring the material goods we can provide for immediate relief.",
    image: AcbFaq2,
    titleColor: 'text-customBlack'
  },
  {
    question: "Promoting Values and Community Service ",
    answer: "Once the immediate needs are determined and material goods procured, we begin providing relief measures to help and support the affectees in the best possible",
    image: WorkImg,
    titleColor: 'text-customBlack'
  },
];




export default function AcbFaq({ FaqDisaster }) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {/* Using ternary operation here as this faq component is being used in 3 places */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 max-w-screen-2xl mx-auto ">
        <div className="faq-tabs">
          {(FaqDisaster === 'disaster' ? faqDataDisaster
            : FaqDisaster === 'college' ? faqKasurCollegeData
              : faqData
          ).map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
              disableGutters // Remove padding from Accordion
              elevation={0} // Remove shadow from Accordion
              square // Make Accordion edges square
              sx={{
                borderBottom: '1px solid #e0e0e0', // Add border-bottom for the title only
                '&:before': { display: 'none' }, // Remove the default divider line
              }}
            >
              <AccordionSummary
                expandIcon={null} // Remove the expand icon
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                className="pb-2" // Adjust padding if needed
              >
                <CustomTypography
                  as="p"
                  variant="p1"
                  color={faq.titleColor}
                  weightFont="font-semibold"
                >
                  {faq.question}
                </CustomTypography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <CustomTypography
                    as="p"
                    variant="p3"
                    color="text-SlateGray"
                    weightFont="font-normal"
                  >
                    {faq.answer}
                  </CustomTypography>
                  {/* Show image below the summary for mobile */}
                  {expanded === `panel${index + 1}` && (
                    <img src={faq.image} alt={`FAQ ${index + 1}`} className="mt-4 block md:hidden" />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        {/* Desktop image display */}
        <div className="faq-images hidden md:block">
          {(FaqDisaster === 'disaster' ? faqDataDisaster
            : FaqDisaster === 'college' ? faqKasurCollegeData
              : faqData
          ).map((faq, index) => (
            <div
              key={index}
              className={`faq-image ${expanded === `panel${index + 1}` ? 'block' : 'hidden'}`}
            >
              <img src={faq.image} alt={`FAQ ${index + 1}`} className="mt-4" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
