import React from 'react';
import { Box } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomTypography from '../CustomTypography';

const AhsWorks = ({ topHead, para, gridImage, paddingLength, belowHead }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className='flex flex-col h-full overflow-hidden rounded-[20px]'>
            <Box>
                <img src={gridImage} alt="Donate" className='rounded-t-[20px] w-full min-h-[238px] object-cover lg:h-auto' />
            </Box>

            <Box
                className='bg-white flex-grow lg:px-80px px-6 flex flex-col justify-between'
                style={{
                    paddingTop: isMobile ? "32px" : paddingLength,
                    paddingBottom: isMobile ? "32px" : paddingLength
                }}
            >
                <div>


                    <CustomTypography
                        as='h3' variant='h3' color='text-customGreen' weightFont='font-medium'

                    >
                        {topHead}
                    </CustomTypography>

                    <CustomTypography className="lg:pt-8 pt-6"
                        as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'


                    >
                        {para}
                    </CustomTypography>

                    <CustomTypography className="pt-6"
                        as='p' variant='p2' color='text-topGreen' weightFont='font-normal'


                    >
                        {belowHead}
                    </CustomTypography>

                </div>
            </Box>
        </Box>
    )
}

export default AhsWorks;
