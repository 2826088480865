import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";

//Akhuwat stories testimonials
const ActiveTestimonials = ({ storyImg, storyDesc,storyHead }) => {

    return (

        <Box className="rounded-[24px] overflow-hidden">

            <Box >
            <img src={storyImg} alt="story image" />
            </Box>
           

            <Box className="p-10 bg-primaryLight border-t-[8px] border-t-topGreen">
                

                <CustomTypography className="pt-8"
                    as='h3' variant='h3' color='text-topGreen' weightFont='font-medium' 
                    alignment="lg:text-left md:text-left text-center"
                >
                    {storyHead}
                </CustomTypography>

                <CustomTypography className="pt-8"
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment="lg:text-left md:text-left text-center"
                >
                    {storyDesc}
                </CustomTypography>



            </Box>

        </Box>
    )

}

export default ActiveTestimonials