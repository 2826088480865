import React from "react";
import { Box } from "@material-ui/core";
import AboutBanner from '../pictures/About-banner.png'
import AboutBannerMob from '../pictures/about-banner-mob.png'
import AboutCard from "./AboutCard";
import AboutMiddle from '../pictures/about-middle.png'
import AboutStory from '../pictures/about-story.png'
import AboutWork from '../pictures/about-work.png'
import Philso from '../pictures/philos.png'
import AboutLeader from '../pictures/about-leader.jfif'
import aboutFounder from '../pictures/about-founder.png'
import OurPrinciple from "./OurPrinciple";
import CustomButton from "../ReusableComponents/CustomButton";
import { useMediaQuery, useTheme } from '@material-ui/core';
import aboutPsy from '../pictures/about-psy-pattern.png'
import CustomTypography from "../CustomTypography";
import arrow from '../pictures/about-story-arrow.png'
import FaqCarasouel from "../FaqCarasouel";
import { Link } from "react-router-dom";

const About = () => {

    const MissionCards = [
        <AboutCard CardTitle="Mission" cardDesc="A poverty-free society built on the principles of compassion and equity" cardColor="topGreen" />,
        <AboutCard CardTitle="Vision" cardDesc=" To alleviate poverty by empowering socially and economically marginalised segments of the society through interest-free microfinance and education." cardColor="customGreen" />,
        <AboutCard CardTitle="Objective" cardDesc="To develop and sustain a social system based on mutual support where each individual lives a life full of respect and dignity." cardColor="schoolYellow" />,
    ]

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box >
            <Box className="h-auto bg-white pt-10 lg:pb-140px pb-10 lg:px-120px md:px-9 px-4
            max-w-screen-2xl mx-auto">
                <Box>


                    <CustomTypography as="h1" variant="h1" color="text-customBlack" weightFont="font-bold"
                        alignment="text-center">
                        About us
                    </CustomTypography>
                </Box>
                <Box className="pt-10">
                    <img className="w-full lg:h-full md:h-[308px] object-cover lg:block md:block hidden rounded-[16px]" src={AboutBanner} alt="banner"></img>
                    <img className="w-full h-full lg:hidden md:hidden block object-cover" src={AboutBannerMob} alt="banner"></img>
                </Box>

                <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-10 lg:pt-10 pt-5 gap-5 ">
                    <Box>

                        <CustomTypography as="h3" variant="h3" color="text-customBlack"
                            alignment="lg:text-left text-center" weightFont="font-semibold" >
                           Akhuwat initiatives include interest-free microfinance, fee-free quality 
                           education, and inclusion of the most marginalized segments of society.
                        </CustomTypography>
                    </Box>

                    <Box>


                        <CustomTypography as="p" variant="p2" color="text-SlateGray"
                            alignment="lg:text-left text-center" >
                          At Akhuwat, financial inclusion, access to education, and healthcare are valued
                           as fundamental human rights. Akhuwat was established with the hope that the
                            privileged among us can empower those who are underprivileged and thus,
                             practice Mawakhat (solidarity) to alleviate poverty and build a bond of 
                             brotherhood between those who have, and those who have not.
                        </CustomTypography>
                        <Box className="flex justify-center lg:justify-start lg:pt-8 pt-4">

                            <CustomButton btnText="Read more" btnURL="/about"
                                btnColor="white" btnBackground="customBlack" />
                        </Box>




                    </Box>
                </Box>

            </Box>

            {/* Mission section */}

            <Box className="h-auto lg:py-120px lg:px-120px bg-customGray py-60px md:px-2 px-4">
                <Box className="max-w-screen-2xl mx-auto"> {/* max width for the section */}
                    <Box className='flex justify-center items-center  text-center lg:w-[800px]  mx-auto'>


                        <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-bold"
                            alignment="text-center">
                            Akhuwat is Committed to Changing Lives
                        </CustomTypography>
                    </Box>



                    {/* Desktop Grid Layout */}
                    <Box className="hidden md:grid lg:grid grid-cols-3 lg:gap-5 gap-2 pt-80px">

                        {MissionCards}

                    </Box>
                </Box>
            </Box>

            {/* mission cards mobile layout...... */}

            <Box className="h-auto  bg-customGray  pr-0 pl-4 pb-60px">
                {/** mobile and tablet carasoule */}
                <Box className='lg:hidden md:hidden pt-9'>

                    <FaqCarasouel slides={MissionCards} slideWidth={{
                        lg: 158,
                        md: 158,
                        sm: 261
                    }} gap={12}

                    />

                </Box>
            </Box>


            {/**  pic section*/}
            <Box className="lg:h-[700px] md:h-[396px] h-[282px] bg-cover  object-cover
            " style={{ backgroundImage: `url(${AboutMiddle})` }}>

            </Box>


            <Box className="h-auto bg-white lg:py-[140px] md:py-[60px] md:px-[10px] py-10 lg:px-[120px] px-4">
                <Box className="max-w-screen-2xl mx-auto">

                    {/* Section Header */}
                    <Box className="grid lg:grid-cols-3 gap-4 grid-cols-1 gap-[10px]">
                        <Box className="col-span-1">
                            <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-bold" alignment="lg:text-left text-center">
                                Our Identity
                            </CustomTypography>
                        </Box>

                        <Box className="col-span-2">
                            <CustomTypography as="p" variant="p2" color="text-SlateGray" weightFont="font-normal" alignment="lg:text-left text-center">
                                Akhuwat embodies Mawakhat (solidarity) as a vision towards a poverty-free society based on the values of solidarity,
                                equity, and compassion. Akhuwat values financial support, education, and healthcare as fundamental human rights.
                            </CustomTypography>
                        </Box>
                    </Box>

                    {/* Cards Section */}
                    <Box className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 lg:pt-[90px] pt-8">

                        {/* Our Story Card */}
                        <Box className="bg-[#143862] h-full rounded-[20px] overflow-hidden flex flex-col justify-between">
                            {/* Background Image */}
                            <Box
                                style={{
                                    backgroundImage: `url(${AboutStory})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'top center',
                                    backgroundSize: 'cover',
                                    height: isMobile ? '85px' : '140px',
                                }}
                            />

                            {/* Card Content */}
                            <Box className="h-auto lg:px-10 px-4 lg:pt-10 pt-5 lg:pb-16 pb-[52px] flex flex-col flex-grow">
                                <CustomTypography as="h3" variant="h3" color="text-white" alignment="lg:text-left text-center">
                                    Our Story
                                </CustomTypography>

                                <CustomTypography className="lg:pt-10 md:pt-10 pt-[10px]" as="p" variant="p2" color="text-white" alignment="lg:text-left text-center">
                                    Before 2001, Dr. Muhammad Amjad Saqib was affiliated with the Punjab Rural Support Program (PRSP). He worked with marginalized families and witnessed
                                    their struggles. To support them in their financial difficulties, he conceived Akhuwat’s interest-free microfinance.
                                </CustomTypography>

                                {/* Arrow Icon aligned to end */}
                                <Box className="flex justify-end mt-auto">
                                    <Link to='/story'>
                                    <Box className="w-[40px] h-[40px] rounded-full border border-white flex items-center justify-center">
                                       
                                        <img src={arrow} alt="arrow" className="h-6 w-6 object-cover" />
                                    </Box>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>

                        {/* Our Work Card */}
                        <Box className="bg-customGreen h-full rounded-[20px] overflow-hidden flex flex-col justify-between">
                            {/* Background Image */}
                            <Box
                                style={{
                                    backgroundImage: `url(${AboutWork})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'top center',
                                    backgroundSize: 'cover',
                                    height: isMobile ? '85px' : '140px',
                                }}
                            />

                            {/* Card Content */}
                            <Box className="h-auto lg:px-10 px-4 lg:pt-10 pt-5 lg:pb-16 pb-[52px] flex flex-col flex-grow">
                                <CustomTypography as="h3" variant="h3" color="text-white" alignment="lg:text-left text-center">
                                    Our Work
                                </CustomTypography>

                                <CustomTypography className="lg:pt-10 md:pt-10 pt-[10px]" as="p" variant="p2" color="text-white" alignment="lg:text-left text-center">
                                    Akhuwat offers interest-free loans as Qarz-e-Hassan to financially disadvantaged families and individuals. The beneficiaries use these loans to invest
                                    in their businesses, farms, and homes. Akhuwat also works in the areas of education, healthcare services, transgender support, and disaster relief.
                                </CustomTypography>

                                {/* Arrow Icon aligned to end */}
                                <Box className="flex justify-end mt-auto">
                                <Link to='/work'>
                                    <Box className="w-[40px] h-[40px] rounded-full border border-white flex items-center justify-center">
                                        <img src={arrow} alt="arrow" className=" h-6 w-6 object-cover" />
                                    </Box>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>


            {/**Philosphy section  */}

            <Box className="bg-primaryLight  h-auto  lg:py-120px py-10 lg:px-[160px] px-4"
                style={{
                    backgroundImage: !isMobile ? `url(${aboutPsy})` : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: 'cover'
                }}>
                <Box className="max-w-screen-2xl mx-auto">
                    <Box className="grid lg:grid-cols-2 grid-cols-1 lg:gap-[127px] gap-10">
                        <Box className="lg:relative">


                            <CustomTypography as="h2" variant="h2" color="text-topGreen "
                                alignment="lg:text-left text-center">
                                Our Philosophy
                            </CustomTypography>

                            <Box className="flex justify-end lg:absolute bottom-0 pt-10 lg:pt-[0px]">

                                <img className="mx-auto lg:ml-0 lg:w-auto lg:h-auto h-[65px] w-[233px] 
                         " src={Philso} alt="philoso" />
                            </Box>
                        </Box>

                        <Box>


                            <CustomTypography as="p" variant="p3" color="text-SlateGray " weightFont="font-normal"
                                alignment="lg:text-left text-center">
                                In 622 CE, the Muhajireen (Immigrants) escaped their hometown
                                Mecca due to religious persecution and moved to Medina.
                                Prophet Muhammad (Peace be Upon him) called on the Ansars
                                (helpers) of Medina to share their wealth with the Muhajirs
                                (immigrants) and treat them like brothers. The event laid
                                down the enduring tradition of Mawakhat: solidarity and
                                social responsibility towards one’s fellow citizens.
                                <br /> <br />
                                From the beginning, Akhuwat adopted Qarz-a-Hassan, “a beautiful loan”,
                                as its core strategy for microfinance. Although Akhuwat derives its
                                inspiration from the Islamic tradition of Mawakhat
                                , it does not discriminate on the basis of religion, sect, caste,
                                color, creed or gender. Akhuwat propagates inclusion, social entrepreneurship and growth.
                            </CustomTypography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/**  pic section*/}
            <Box className="lg:h-[700px] md:h-[396px] h-[282px] bg-cover  object-cover
      " style={{ backgroundImage: `url(${AboutMiddle})` }}>

            </Box>


            {/**  Our Principle*/}

            <OurPrinciple />



            {/**  OUR Founder section */}

            <Box className="bg-Peach h-auto lg:py-140px md:py-60px md:p-10 py-8 px-4 lg:px-120px">
                <Box className="max-w-screen-2xl mx-auto  grid lg:grid-cols-2 grid-cols-1 rounded-[20px] overflow-hidden h-auto  lg:h-[620px]">
                    <Box>
                        <img
                            className="lg:h-[620px] md:h-[434px] h-[251px] w-full 
        object-cover object-top "
                            src={aboutFounder}
                            alt="founder"
                        />
                    </Box>

                    <Box className="bg-customGray lg:p-14 md:p-9 p-5
                    flex justify-center flex-col">


                        <CustomTypography as="h2" variant="h2" color="text-customBlack "
                            alignment="lg:text-left text-center">
                            Our Founder
                        </CustomTypography>

                        <Box className="lg:pt-10 pt-5">


                            <CustomTypography as="p" variant="p2" color="text-SlateGray " weightFont="font-normal"
                                alignment="lg:text-left text-center"
                            >
                                Dr. Muhammad Amjad Saqib (SI, HI) is a development practitioner, public 
                                servant, philanthropist and founder of Akhuwat. His expertise includes 
                                poverty alleviation initiatives, social development, governance, and 
                                educational reform.
                            </CustomTypography>
                        </Box>
                        <Box className="flex justify-center lg:justify-start pt-8">
                            <CustomButton btnText="View Profile" btnBackground="customBlack" btnColor="white"
                                alignment="lg:text-left text-center" btnURL="/founder" />
                        </Box>
                    </Box>
                </Box>
            </Box>


            {/**  OUR leadership section */}


            <Box className="h-auto bg-cover bg-center lg:py-140px lg:px-120px py-90px md:px-120px px-6"
                style={{ backgroundImage: `url(${AboutLeader})` }}>

                <Box className="grid lg:grid-cols-2 grid-cols-1 max-w-screen-2xl mx-auto  ">
                    <Box>


                        <CustomTypography as="h2" variant="h2" color="text-white "
                            alignment="lg:text-left text-center">
                            Our Leadership
                        </CustomTypography>
                    </Box>



                    <Box>
                        <h2 className="mt-4 lg:mt-[0px] text-center lg:text-left font-manrope text-white
                         text-[16px] leading-[21.86px] lg:text-[35px] lg:leading-line-52 lg:font-bold font-normal">
                            <span className="relative bg-clip-text text-transparent bg-gradient-to-r
                             from-green-600 to-green-300 font-manrope text-[16px] leading-[21.86px]
                              lg:text-[35px] lg:leading-line-52 font-bold">Akhuwat</span>  has a dedicated board of directors and heads of international chapters,
                            who help run the organization and work everyday towards its vision of poverty alleviation.
                        </h2>

                        <Box className="flex justify-center lg:justify-start lg:pt-8 pt-4">
                            <CustomButton btnText="Read more" btnURL="/leader" btnColor="customBlack" btnBackground="white" />
                        </Box>
                    </Box>
                </Box>
            </Box>


        </Box>
    )
}


export default About