// reducers/blogReducer.js
import {
    UPLOAD_JOBS_REQUEST, UPLOAD_JOBS_SUCCESS, UPLOAD_JOBS_FAIL,
    DELETE_JOBS_REQUEST, DELETE_JOBS_SUCCESS, DELETE_JOBS_FAIL,
    GET_JOBS_REQUEST, GET_JOBS_SUCCESS, GET_JOBS_FAIL,CLEAR_ERROS,
    UPDATE_JOBS_REQUEST, UPDATE_JOBS_SUCCESS, UPDATE_JOBS_FAIL
  } from '../Constants/JobConstants';
  

  
  export const getJobReducer = (state={jobs:[]},action)=> {

    switch (action.type) {
        case GET_JOBS_REQUEST:
           
        return{
            loading:true,
            jobs:[]
        }
        case GET_JOBS_SUCCESS:
           
            return{
                loading:false,
                jobs:action.payload.data
            }
            case GET_JOBS_FAIL:
           
                return{
                    loading:false,
                    error:action.payload
                }
                                   
                case CLEAR_ERROS:
           
                return{
                    ...state,
                    error:null
                }
                       
        default:
            return state
    }
   
  };


  //adding a new blog


export const addJobReducers=(state={jobs:{}},action)=>{

    switch (action.type) {
        case UPLOAD_JOBS_REQUEST:
            return{
                ...state,
                loading:true,
            }

            case UPLOAD_JOBS_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    success:true
                }
                case UPLOAD_JOBS_FAIL:
                    return{
                        ...state,
                        loading:false,
                        success:false,
                        error:action.payload
                    }
                
                           
            default:
                return state
        }
    }
  