import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import AkspBanner from "../pictures/aksp-banner.png"
import BannerDesign from './BannerDesign';
import ImpactIcon1 from "../pictures/aksp-impact-icon1.png"
import ImpactIcon2 from "../pictures/aksp-impact-icon2.png"
import ImpactIcon3 from "../pictures/aksp-impact-icon3.png"
import ImpactIcon4 from "../pictures/aksp-impact-icon4.png"
import ImpactIcon5 from "../pictures/aksp-impact-icon5.png"
import ImpactIcon6 from "../pictures/aksp-impact-icon6.png"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AkspCards from './AkspCards';
import AkspDonate from "../pictures/aksp-donate-pic.png"
import AkspArt from "../pictures/aksp-art.png"
import AkspDignity from "../pictures/aksp-dignity.png"
import AkspComm from "../pictures/aksp-comm.png"
import AkspCare from "../pictures/aksp-care.png"
import AkspStory1 from "../pictures/aksp-stories1.png"
import AkspStories from './AkspStories';
import CustomButton from '../ReusableComponents/CustomButton';
import AimStories from './AimStories';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AkspStoryComma from "../pictures/aksp-comma.png"
import AkspStoryUnion from "../pictures/aksp-stories-union.png"
import AkspMembership from './AkspMembership';
import AkspLinkage from './AkspLinkage';
import AkspLink1 from "../pictures/aksp-link-u.png"
import AkspLink2 from "../pictures/aksp-link2.png"
import AkspLink3 from "../pictures/aksp-link3.png"
import AkspLink4 from "../pictures/aksp-link4.png"
import AkspLink5 from "../pictures/aksp-link5.png"
import AkspLink6 from "../pictures/aksp-link6.png"
import AkspLink7 from "../pictures/aksp-link7.png"
import AkspLink8 from "../pictures/aksp-link8.png"
import AkspBannerpattern from "../pictures/aksp-banner-pattern.png"
import DonateNowSetion from '../ReusableComponents/DonateNowSection';
import Metadata from '../Metadata';
import CustomTypography from '../CustomTypography';
import AkspBusinessCard from './AkspBusinessCard';
import CustomCarousel from '../CustomCarasouel';
import FaqCarasouel from '../FaqCarasouel';
import VideoSectionImg from "../pictures/parwaz_cycle_img.png"


const Aksp = () => {

   
    const [currentSlide1, setCurrentSlide1] = useState(0);//stories



    const totalSlides = 4; // Total number of slides in the carousel
    const handleNext = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrev = () => {
        setCurrentSlide1((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };



    const AkspBusinessCards = [
        <AkspBusinessCard iconPic={ImpactIcon1} head="Created a community of over 2,205 Khawajasiras" backColor="#F0F4F9" textColor="#2A2A2A" />,
        <AkspBusinessCard iconPic={ImpactIcon2} head="Annual health screening tests of about 1200 Khawajasiras" backColor="#F0F4F9" textColor="#2A2A2A" />,
        <AkspBusinessCard iconPic={ImpactIcon3} head="Over 3,800 articles of clothing distributed amongst the Khawajasira" backColor="#F0F4F9" textColor="#2A2A2A" />,
        <AkspBusinessCard iconPic={ImpactIcon4} head="16 trainings conducted, imparting literacy and vocational skills." backColor="#F0F4F9" textColor="#2A2A2A" />,
        <AkspBusinessCard iconPic={ImpactIcon5} head="Arranged employment opportunities" backColor="#F0F4F9" textColor="#2A2A2A" />,
        <AkspBusinessCard iconPic={ImpactIcon6} head="Career Counseling & Financial Literacy" backColor="#F0F4F9" textColor="#2A2A2A" />
    ]


    const AkspSupportCards = [
        <AkspCards topHead="Community Dialogue" para="Each month, gatherings are arranged where registered
        Khawajasiras and members of the Civil Society are invited.
        These gatherings have been extremely successful in fostering dialogue and contributing to
         a mutual understanding and respect for the Khawajasira community overall."/>,

        <AkspCards topHead="Basic Literacy Workshops"
            para="With the expansion of the program, many Khawajasiras expressed the
          desire to develop basic literacy skills since they are unable to pursue 
          their education due to the stigma attached with their community. 
          AKSP collaborates with various entities to impart basic reading, numbering,
           arithmetic, and writing skills to educate and empower the Khawajasira community."/>,

        <AkspCards topHead="Economic Rehabilitation" para="
         The focus of the program is to assist Khawajasiras
          in acquiring sustainable livelihoods and to remove their dependency on alms. 
          Complemented with efforts aimed at social integration, it is envisioned that 
          their financial empowerment will translate into social inclusion and self-sufficiency 
          through initiatives such as skill development workshops, networking events and 
          connecting them with recruiters."/>,


        <AkspCards topHead="Health Services" para="
         Interest-free loans or Qarz-e-Hassan are extended to members 
         of the program to support them in launching or expanding their
          enterprises and to promote a culture of self-reliance."/>
    ]



    const AkspLinkageCards = [
        <AkspLinkage boxImage={AkspLink1} alt="link1" backColor="white" />,
        <AkspLinkage boxImage={AkspLink2} alt="link2" backColor="white" />,
        <AkspLinkage boxImage={AkspLink3} alt="link3" backColor="white" />,
        <AkspLinkage boxImage={AkspLink4} alt="link4" backColor="white" />,
        <AkspLinkage boxImage={AkspLink5} alt="link5" backColor="white" />,
        <AkspLinkage boxImage={AkspLink6} alt="link6" backColor="white" />,
        <AkspLinkage boxImage={AkspLink7} alt="link7" backColor="white" />,
        <AkspLinkage boxImage={AkspLink8} alt="link8" backColor="white" />
    ]

    const DonateCards=[
        <AkspStories topHead="Art & Expression " para="We organize games and competitions for 
        its Khwajasira members and encourage them to make art and participate in festive
         activities for communal support. "
               gridImage={AkspArt}
               paddingLength="40px" />,

           <AkspStories topHead="Khwajasiras Deserve Dignity " para="We have employed many 
           Khwajasiras in its Clothes Bank and have run campaigns to facilitate and financially
            empower Khwajasiras and reduce their dependence on alms. "
               gridImage={AkspDignity}
               paddingLength="40px" />,


           <AkspStories topHead="Mapping Khwajasira Community  " para="We gather information on 
           transgender (khwajasira) communities all over Pakistan and preserve their testimonies 
           to counter false and stereotypical narratives about them."
               gridImage={AkspComm}
               paddingLength="40px" />,


           <AkspStories topHead="We Care About Khwajasiras." para="For more than a decade, we have
            been helping to create a system of social support for the khwajasira community through
             the provision of health, counseling, and financial services. "
               gridImage={AkspCare}
               paddingLength="40px" />
    ]

    return (

        <Box >
            <Metadata title="Aksp" />

            {/* Banner design */}

            <Box>

                <BannerDesign imageURL={AkspBanner} headPart1={'Akhuwat Khawajasira'} headPart2={'Program (AKSP)'}
                    backColor="#7332AB" belowHead={'Akhuwat Khawajasira Program (AKSP)'}
                    belowPara1="The social and economic exclusion of the Khwajasira or transgender 
                    (Third Gender) community in Pakistan has left them dependent on alms, vulnerable to 
                    exploitation and increasingly susceptible to abuse. Realizing this and the consequent
                     need for action in this arena, Akhuwat, in collaboration with Fountain House launched 
                     the Akhuwat Khwajasira Support Program in 2011.  "

                    belowheadColor="text-DeepTeal"
                    belowParaColor="text-customBlack"
                    AkspLeftUnion={AkspBannerpattern}
                    donateBtn="yes"

                />

            </Box>

            {/* Community section */}

            <Box className='bg-customGreen lg:py-[180px] md:py-60px md:px-10 py-52px lg:px-120px px-4'>
                <Box className='max-w-screen-2xl mx-auto'>

                <CustomTypography as="h3" variant="h3" color="text-white " weightFont="font-medium">
                    The program works with the vision of creating a system of support for members of
                    the Khwajasira community.
                </CustomTypography>


                <CustomTypography className="pt-10 " as="p" variant="p2" color="text-white"
                    weightFont="font-normal">
                    This is done through economic and social interventions whereby thousands of Khwajasiras
                    have been registered across the country and are supported through direct financial
                    assistance, health services, psychological support and acquisition of identity
                    documents.
                </CustomTypography>

              </Box>
            </Box>

            {/* Impace section.... */}

            <Box className='bg-white lg:py-90px md:py-60px md:px-10 py-52px lg:px-120px px-4'>
               <Box className='max-w-screen-2xl mx-auto'>
                <CustomTypography
                    as='h2' variant='h2' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Our Impact
                </CustomTypography>

                <Box className='pt-60px lg:grid grid-cols-3  hidden gap-10'>

                    {AkspBusinessCards}

                </Box>

               </Box>
            </Box>

            {/* impace section slider for mobile and tablet */}

            

            <Box className='lg:hidden md:py-60px py-52px md:pl-10 pl-4 '>
                    <FaqCarasouel slides={AkspBusinessCards} slideWidth={{
                        lg:247,
                        md: 247,
                        sm: 247
                    }} gap={20}

                    />
                </Box>

            {/* Support section */}

            <Box className='bg-white lg:py-90px md:py-60px md:px-10 py-52px lg:px-120px px-4'>
            <Box className='max-w-screen-2xl mx-auto'>
                <Box className='grid lg:grid-cols-2 grid-cols-1 lg:gap-10 gap-6'>
                    <CustomTypography
                        as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment='lg:text-left text-center'
                    >
                        How AKSP Supports Khwajasiras?
                    </CustomTypography>


                    <CustomTypography className="lg:pt-0 pt-6"
                        as='p' variant='p2' color='text-SlateGray' weightFont='font-normal' alignment='lg:text-left text-center'

                    >
                        Through its extensive efforts in collaboration with Fountain House, Akhuwat has
                        been able to support Khwajasiras and facilitate them in several vital areas of
                        their lives:

                    </CustomTypography>
                </Box>

                <Box className='lg:pt-90px md:pt-10 lg:grid md:grid  grid-cols-2 hidden gap-5 lg:gap-[30px]'>

                    {AkspSupportCards}

                </Box>


                <Box className='pt-10 lg:hidden md:hidden'>

                    {/* Carousel */}

                    <CustomCarousel slides={AkspSupportCards} />

                </Box>


           </Box>
            </Box>


            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] md:h-[500px] h-[421px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${VideoSectionImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='flex flex-col lg:justify-center md:justify-center
                 justify-end h-full 
                lg:px-[128px] md:px-16 px-4 md:py-0 lg:py-0  lg:w-[900px] md:w-[500px] w-auto  py-6 
                 max-w-screen-2xl'>

                    <p className='font-manrope text-white font-extrabold text-[14px] 
                    lg:leading-[14px] lg:text-left md:text-left text-center '>
                        AKHUWAT KHAWAJASIRAS PROGRAM</p>

                    <CustomTypography className='pt-2' as="h2" variant="h21" color="text-white " weightFont="font-bold"
                        alignment="lg:text-left md:text-left text-center"
                    >
                        Equality, Inclusion and Diversity.
                    </CustomTypography>

                    <Box className='pt-6 flex lg:justify-start md:justify-start justify-center'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>


            {/* Why donate section .... */}

            <Box className='bg-LightAqua lg:py-120px py-52px md:py-60px md:px-10 lg:px-120px px-4'>
             
           <Box className='max-w-screen-2xl mx-auto'>
                
            <CustomTypography as="h2" variant="h21" color="text-customBlack " weightFont="font-bold"
                    alignment='text-center'
                >
                    Why Donate to Akhuwat?
                </CustomTypography>



                <Box className='pt-120px  hidden md:block lg:block'>
                    <AkspStories topHead="We Tell Their Stories. "
                        para="Nothing can help us understand a person more than their personal story.
                      We regularly interview and record stories of Khwajasiras to spread awareness
                       and empathy about their alienation."
                        gridImage={AkspDonate}
                        paddingLength="80px" />
                </Box>

                <Box className='pt-60px  lg:grid md:grid grid-cols-2 hidden gap-5'>

                    <AkspStories topHead="Art & Expression " para="We organize games and competitions for 
                 its Khwajasira members and encourage them to make art and participate in festive
                  activities for communal support. "
                        gridImage={AkspArt}
                        paddingLength="40px" />

                    <AkspStories topHead="Khwajasiras Deserve Dignity " para="We have employed many 
                    Khwajasiras in its Clothes Bank and have run campaigns to facilitate and financially
                     empower Khwajasiras and reduce their dependence on alms. "
                        gridImage={AkspDignity}
                        paddingLength="40px" />


                    <AkspStories topHead="Mapping Khwajasira Community  " para="We gather information on 
                    transgender (khwajasira) communities all over Pakistan and preserve their testimonies 
                    to counter false and stereotypical narratives about them."
                        gridImage={AkspComm}
                        paddingLength="40px" />


                    <AkspStories topHead="We Care About Khwajasiras." para="For more than a decade, we have
                     been helping to create a system of social support for the khwajasira community through
                      the provision of health, counseling, and financial services. "
                        gridImage={AkspCare}
                        paddingLength="40px" />


                </Box>




                <Box className='pt-8 lg:hidden md:hidden'>
                <CustomCarousel slides={DonateCards} />
                </Box>

            </Box>
            </Box>



            {/* Membership benefits section */}



            <Box>
                <AkspMembership />
            </Box>


            {/* Stories */}

            <Box className='bg-LightAqua lg:py-120px py-52px lg:px-140px px-4'>
                <Box className='max-w-screen-2xl mx-auto'>
                <Box className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1' >
                    <Box className='lg:col-span-2 md:col-span-2 cols-span-1'>
                        <h1 className='lg:text-left md:text-left text-center font-manrope font-bold text-customBlack 
                        lg:text-[48px] text-[28px] lg:leading-[46px] leading-[38.25px]'>Our Stories & Media </h1>
                    </Box>
                    <Box className='col-span-1 lg:flex md:flex hidden items-center justify-end'>
                        <Box
                            onClick={handlePrev}
                            className={`cursor-pointer ${currentSlide1 === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentSlide1 === 0 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowLeftIcon className={`text-customBlack ${currentSlide1 === 0 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                        </Box>
                        <Box
                            onClick={handleNext}
                            className={`ml-4 cursor-pointer ${currentSlide1 === totalSlides - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                            style={{ borderColor: currentSlide1 === totalSlides - 1 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowRightIcon className={`text-customBlack ${currentSlide1 === totalSlides - 1 ? 'text-[#A6A6A6]' : 'text-[#329A51]'}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className='lg:pt-80px pt-10'>
                    <Carousel
                        selectedItem={currentSlide1}
                        onChange={(index) => setCurrentSlide1(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%" // Ensures full width on all devices
                    >
                        <Box className='ml-2 mr-2'>
                        <AimStories gridImage={AkspStory1} vectorImage={AkspStoryComma} para="
                    “I am very grateful to the brotherhood who held our hands when there was no other support."
                            bottomHead="[Irshad Ahmed, Chungi Amar Sidhu, Lahore, Pakistan]"
                            backVector={AkspStoryUnion}
                            backColor="white"
                            textColor="white" />

                        </Box>
        
                        <Box className='ml-2 mr-2'>
                        <AimStories gridImage={AkspStory1} vectorImage={AkspStoryComma} para="
                    “I am very grateful to the brotherhood who held our hands when there was no other support."
                            backVector={AkspStoryUnion}
                            backColor="white"
                            textColor="white" />

                            </Box>

                    </Carousel>

     
                </Box>

                </Box>

            </Box>



            {/* How can you help section */}

            <Box className='bg-customGreen lg:py-[100px] md:py-[100px] md:px-10 py-52px lg:px-120px px-4 '>

                <Box className='grid lg:grid-cols-2 grid-cols-1 lg:gap-120px gap-10
                max-w-screen-2xl mx-auto'>
                    <Box>

                        <CustomTypography as="h2" variant="h2" color="text-white " weightFont="font-bold" >
                            How can you help?
                        </CustomTypography>

                        <CustomTypography className='pt-4'
                            as='p' variant='p3' color='text-white' weightFont='font-normal'
                        >
                            Khwajasiras are one of the most oppressed and alienated communities in Pakistan who
                            deserve and need your help. There are several ways in which you can help and
                            support them by donating to our program or helping Khwajasiras directly.

                        </CustomTypography>

                        <Box className="flex justify-start pt-10">
                            <CustomButton btnText='Contact Us' btnURL='/donate' btnBackground='customBlack'
                                btnColor='white' />
                        </Box>
                    </Box>

                    <Box >
                        <Box className='flex items-center'>
                            <Box className='h-10 w-10 rounded-full bg-white p-[10px]'>
                                <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"
                                    alignment='text-center'
                                >
                                    1
                                </CustomTypography>

                            </Box>

                            <CustomTypography className="ml-4"
                                as='p' variant='p3' color='text-white' weightFont='font-normal'

                            >
                                Connect a Khwajasira
                            </CustomTypography>

                        </Box>

                        <Box className='flex items-center pt-5'>
                            <Box className='h-10 w-10 rounded-full bg-white p-[10px]'>
                                <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"
                                    alignment='text-center'
                                >
                                    2
                                </CustomTypography>
                            </Box>

                            <CustomTypography className="ml-4"
                                as='p' variant='p3' color='text-white' weightFont='font-normal'

                            >
                                Interest-free Loan for Business
                            </CustomTypography>
                        </Box>

                        <Box className='flex items-center pt-5'>
                            <Box className='h-10 w-10 rounded-full bg-white p-[10px]'>
                                <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"
                                    alignment='text-center'>
                                    3
                                </CustomTypography>
                            </Box>

                            <CustomTypography className="ml-4"
                                as='p' variant='p3' color='text-white' weightFont='font-normal'

                            >
                                Employ a Khwajasira
                            </CustomTypography>
                        </Box>

                        <Box className='flex items-center pt-5'>
                            <Box className='h-10 w-10 rounded-full bg-white p-[10px]'>


                                <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"
                                    alignment='text-center'
                                >
                                    4
                                </CustomTypography>
                            </Box>


                            <CustomTypography className="ml-4"
                                as='p' variant='p3' color='text-white' weightFont='font-normal'

                            >
                                Fund a stipend for a Khwajasira
                            </CustomTypography>
                        </Box>

                        <Box className='flex items-center pt-5'>
                            <Box className='h-10 w-10 rounded-full bg-white p-[10px]'>
                                <CustomTypography as="p" variant="p2" color="text-customBlack " weightFont="font-semibold"
                                    alignment='text-center'
                                >
                                    5
                                </CustomTypography>
                            </Box>


                            <CustomTypography className="ml-4"
                                as='p' variant='p3' color='text-white' weightFont='font-normal'

                            >
                                Register as a Volunteer
                            </CustomTypography>
                        </Box>
                    </Box>
                </Box>


            </Box>


            {/* Linkages established  */}

            <Box className='bg-white lg:py-120px md:py-60px md:px-10 lg:px-120px py-52px px-4'>
               <Box className='max-w-screen-2xl mx-auto'>
                <Box className='lg:grid md:grid lg:grid-cols-4 grid-cols-2 hidden gap-5'>

                    <Box className='col-span-2'>
                        <CustomTypography
                            as='h2' variant='h2' color='text-customBlack' weightFont='font-bold'
                        >
                            Linkages Established
                        </CustomTypography>



                        <CustomTypography className="pt-8"
                            as='p' variant='p3' color='text-topGray' weightFont='font-normal'

                        >
                            Akhuwat Khwajasira Program has established partnerships with various organizations to
                            support the empowerment and social inclusion of the transgender community.
                            These collaborations aim to provide vocational training, employment opportunities, and
                            healthcare services to Khwajasiras.
                        </CustomTypography>

                    </Box>

                    {AkspLinkageCards}

                    <Box className='col-span-2 '>
                        <AkspLinkage head="Interested in Partnership?
                        "  contactNumber="Contact us at 111-111-111" />
                    </Box>


                </Box>

                {/* For mobile  */}


                <Box className='lg:hidden md:hidden '>
                    <CustomTypography
                        as='h2' variant='h2' color='text-customBlack' weightFont='font-bold'
                        alignment='text-center'
                    >
                        Linkages Established
                    </CustomTypography>
                    <CustomTypography className="pt-8"
                        as='p' variant='p3' color='text-topGray' weightFont='font-normal'
                        alignment='text-center'

                    >
                        Akhuwat Khwajasira Program has established partnerships with various organizations to
                        support the empowerment and social inclusion of the transgender community.
                        These collaborations aim to provide vocational training, employment opportunities, and
                        healthcare services to Khwajasiras.
                    </CustomTypography>
                </Box>


                <Box className='pt-8 lg:hidden md:hidden'>
                    <FaqCarasouel slides={AkspLinkageCards} slideWidth={{
                        lg: 158,
                        md: 158,
                        sm: 244
                    }} gap={24}

                    />
                </Box>

               </Box>
            </Box>


            {/* Donaate now section  */}



            <DonateNowSetion Head6="Akhuwat Khwajasira Program"
                Head2="Help Someone. Donate Now." HeadColor2="text-white" HeadColor6="text-simpleGreen"
                backColor="customGreen" />



        </Box>
    )

}

export default Aksp