// reducers/blogReducer.js
import {
    UPLOAD_BLOG_REQUEST, UPLOAD_BLOG_SUCCESS, UPLOAD_BLOG_FAIL,
    DELETE_BLOG_REQUEST, DELETE_BLOG_SUCCESS, DELETE_BLOG_FAIL,
    GET_BLOGS_REQUEST, GET_BLOGS_SUCCESS, GET_BLOGS_FAIL,CLEAR_ERROS,
    UPDATE_BLOG_REQUEST, UPDATE_BLOG_SUCCESS, UPDATE_BLOG_FAIL
  } from '../Constants/BlogConstants';
  

  
  export const getBlogReducer = (state={blogs:[]},action)=> {

    switch (action.type) {
        case GET_BLOGS_REQUEST:
           
        return{
            loading:true,
            blogs:[]
        }
        case GET_BLOGS_SUCCESS:
           
            return{
                loading:false,
                blogs:action.payload.data
            }
            case GET_BLOGS_FAIL:
           
                return{
                    loading:false,
                    error:action.payload
                }
                                   
                case CLEAR_ERROS:
           
                return{
                    ...state,
                    error:null
                }
                       
        default:
            return state
    }
   
  };


  //adding a new blog


export const addBlogReducers=(state={blogs:{}},action)=>{

    switch (action.type) {
        case UPLOAD_BLOG_REQUEST:
            return{
                ...state,
                loading:true,
            }

            case UPLOAD_BLOG_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    success:true
                }
                case UPLOAD_BLOG_FAIL:
                    return{
                        ...state,
                        loading:false,
                        success:false,
                        error:action.payload
                    }
                
                           
            default:
                return state
        }
    }
  