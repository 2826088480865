import React from 'react';
import { Box } from '@material-ui/core';
import AesBanner from '../pictures/Aes-banner.png'
import BannerDesign from './BannerDesign';
import AkCollege from './AkCollege';
import collegeKasur from "../pictures/college-kasur.png"
import collegechakwal from "../pictures/collegeChakwal.png"
import toursimSchool from "../pictures/tourismSchool.png"
import njvSchool from "../pictures/njv-school.png"
import HubsRight from "../pictures/aes-hubs-right-pattern.png"
import HubsLeft from "../pictures/aes-hubs-left-pattern.png"
//import PrimaryPattern from "../pictures/aes-primary-pattern.png"
import AkSchool from './AkSchool';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CustomButton from '../ReusableComponents/CustomButton';
import DonateNowSetion from '../ReusableComponents/DonateNowSection';
import CustomTypography from '../CustomTypography';

const Aes = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <Box >
            <Box>

                <BannerDesign imageURL={AesBanner} headPart1={'Akhuwat Education'} headPart2={'Services (AES)'}
                    backColor="white" belowHead={'What Does AES Do?'}
                    belowPara1="To pursue Akhuwat’s vision of creating a poverty-free society, in 2014,
                     Akhuwat ventured into the field of education by founding Akhuwat Education Services 
                     (AES). AES was initiated to provide quality education, free of cost to deserving 
                     students across Pakistan. "


                    belowheadColor="text-AesHead" belowParaColor="customBlack"
                    donateBtn="yes"

                />

            </Box>

            {/* Primry section  */}

            <Box className='lg:py-[128px] py-52px md:px-52px  px-4 lg:px-[128px] bg-darkBlue'>
               <Box className='max-w-screen-2xl mx-auto'>


                <CustomTypography
                    as='p' variant='p2' color='text-white' weightFont='font-semibold'

                >
              Through its network of schools and colleges, AES strives for academic excellence while 
              inspiring students to become leaders of tomorrow. All of Akhuwat’s institutes provide 
              state-of-the-art facilities and expert teaching staff to transform the lives of these 
              students and create sustainable pathways out of poverty.

                </CustomTypography>
        </Box>
            </Box>

            {/* College section */}

            <Box className='lg:px-120px px-4 lg:py-[128px] md:py-[26px] md:px-[38px] py-5 bg-white '>
<Box className='max-w-screen-2xl mx-auto'>
                <Box>
                    <AkCollege colgImage={collegeKasur} collegeHead="Akhuwat College, Kasur"
                        collegePara="Established in 2015, Akhuwat College is a residential college in Kasur 
                     that houses and educates bright, young people selected on merit from all 
                     corners of Pakistan. The college caters to students from low-income households."
                        backColor="ModernBlue"
                        paraColor="white"
                        btnColor="customBlack"
                        headColor="text-white"
                        btnLink='/college/kasur' />
                </Box>



                {/* Tourism schol */}
                <Box className='grid grid-cols-1 lg:pt-10 pt-5'>

                    <Box>
                        <img className='lg:h-[449px] md:h-[316px] h-[255px] w-full rounded-t-[40px] object-cover' src={toursimSchool} alt="kasur" />
                    </Box>


                    <Box className=' grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 h-auto rounded-b-[40px] 
               lg:p-10 p-5 bg-white border border-[#BCBCBC] lg:gap-10 gap-7' >
                        <Box>

                            <CustomTypography
                                as='h2' variant='h2' color='text-customGreen' weightFont='font-bold'
                                alignment='lg:text-left md:text-left text-center'
                            >
                                Akhuwat Mushahida School of Hospitality and Tourism, Kasur
                            </CustomTypography>

                        </Box>


                        <Box>


                            <CustomTypography
                                as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                alignment='lg:text-left md:text-left text-center'

                            >
                                The Akhuwat Mushahida School of Hospitality and Tourism provides world-class
                                hospitality education to the most deserving students and caters to the tourism
                                industry’s growing need for an educated, skilled workforce.
                            </CustomTypography>


                            <Box className="lg:pt-8 pt-5 flex  lg:justify-start md:justify-start  justify-center items-center ">
                                <CustomButton btnText='Learn more' btnColor="white" btnBackground="customBlack" />
                            </Box>
                        </Box>
                    </Box>
                </Box>

           

           {/* chakwal school */}
              
                <Box className='pt-10 '>
                    <AkCollege colgImage={collegechakwal} collegeHead="Akhuwat Women’s College, Chakwal"
                        collegePara=" Akhuwat College for Women, located in Chakwal, houses women from all
                         over Pakistan and provides fee-free education. The young women are provided a 
                         residential facility that provides a safe and encouraging environment that 
                         empowers them to become agents of change."

                        backColor="ModernSky"
                        paraColor="customBlack"
                        btnColor="white" />
                </Box>





                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 pt-10 '>

                    <Box className='flex'>
                        <AkSchool collegeHead="Akhuwat Communal Hubs"
                            collegePara="Akhuwat Communal Hubs provide education and vocational training
                             to children of unknown parentage, who are often found in alleys plagued by 
                             drugs, prostitution, and violence. They seek to provide children with
                             educational, health, vocational, recreational, and counseling services."
                            backColor="darkBlue"
                            cardRadius="yes"
                            cardHeight="700px"
                            isMobile={isMobile}
                            headColor="text-schoolYellow " />
                    </Box>

                    <Box className='flex '>
                        <AkSchool collegeHead="Akhuwat Schools Public School Support Program (PSSP) "
                            collegePara="Through the Public-School Support Program, Akhuwat has 
                            collaborated with the Government of Punjab to restore non-functioning schools. 
                            Through this collaboration, it has also adopted over 305 public schools in six 
                            districts of Punjab and works to provide quality education to its students."
                            backColor="darkBlue"
                            cardRadius="yes"
                            cardHeight="700px"
                            isMobile={isMobile}
                            headColor="text-schoolYellow " />
                    </Box>

                </Box>




                <Box className='grid lg:grid-cols-2 grid-cols-1  lg:pt-10 pt-5'>
                    <Box className='flex'>
                        <img className=' w-full lg:h-[571px] md:h-[316px] h-[255px] objct-cover' src={njvSchool} alt="toursim school ]"
                            style={{
                                
                                borderTopLeftRadius: '40px',
                                borderTopRightRadius: isMobile ? '40px' : '0px',
                                borderBottomLeftRadius: isMobile ? '0px' : '40px'
                            }} />
                    </Box>

                    <Box className='flex'>
                        <AkSchool collegeHead="NJV School, Karachi "
                            collegePara="Narain Jagannath Vaidya (NJV) High School is Sindh’s first public
                         school which was established in 1855. Since 2015, in partnership with the Government 
                         of Sindh, Akhuwat has devoted itself to restoring this historical school."
                            backColor="blueTest"
                            isMobile={isMobile}
                            cardHeight="571px"
                            headColor="text-white" />
                    </Box>
                </Box>

                <Box className='pt-10 hidden'>
                    <AkCollege collegeHead=" Akhuwat Learning Hubs"
                        collegePara="Akhuwat Learning Hubs provide education and vocational training to children 
         of unknown parentage, who are often found in alleys plagued by drugs, prostitution and
         violence. They seek to provide children with educational, health, vocational, recreational
          and counseling services."

                        headColor="text-blueTest"
                        backColor="white"
                        paraColor="customBlack"
                        regionBorder="yes"
                        btnColor="white"
                        rightpattern={HubsRight}
                        leftPattern={HubsLeft}

                    />
                </Box>

            </Box>
            </Box>

            {/* Donaate now section  */}



            <DonateNowSetion  Head6="Akhuwat Education"
                Head2="Support a school. Donate Now." HeadColor2="text-schoolYellow" HeadColor6="text-white"
                backColor="darkBlue" />



        </Box>
    )

}

export default Aes