import { Box } from "@material-ui/core";
import React, { useState } from 'react';
import CustomTypography from "../CustomTypography";
import CustomButton from '../ReusableComponents/CustomButton';
import BookImg from "../pictures/makhwat-banner-img.png";
import MakVideo from "../pictures/Makhwat-video.png";
import MakTextImg from "../pictures/makhwat-text-img.png";
import MakCopy from "../pictures/makhwat-copy.png";
import fullSlide1 from "../pictures/makhwat-slider-1.png";
import fullSlide2 from "../pictures/makhwat-slider-img2.png";
import AhsStories from "../ACB_AHS/AhsStories";
import Story1 from "../pictures/disaster-stories-img.png";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const MakhwatBook = () => {

    const totalSlidesStories = 4
    const [currentSlideStories, setCurrentSlidestories] = useState(0);//stories
    const handleChangeSlideStories = (index) => {
        setCurrentSlidestories(index); // Update current slide index when a button is clicked
    };


    const handleNextStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === totalSlidesStories - 1 ? 0 : prevSlide + 1));
    };

    const handlePrevStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === 0 ? totalSlidesStories - 1 : prevSlide - 1));
    };


    return (
        <Box>

            {/* Banner section */}
            <Box className="bg-primaryLight">
            <Box className=" lg:py-16 lg:px-[180px] md:py-18 md:px-16 py-6 px-[18px]
        grid lg:grid-cols-2 grid-cols-1 lg:gap-[106px] md:gap-18 gap-8 
        max-w-screen-2xl mx-auto">


                <Box className="flex flex-col justify-center items-center lg:items-start">
                    <CustomTypography
                        as="h1"
                        variant="h1"
                        color="text-customBlack"
                    >
                        Mawakhat
                    </CustomTypography>

                    <CustomTypography className="lg:pt-4 md:pt-4 pt-3"
                        as="p"
                        variant="p1"
                        color="text-customBlack"
                        alignment="lg:text-left text-center"
                    >
                        Stories of Resilience And Rebuilding After the 2022 Floods

                    </CustomTypography>
                    <Box className="flex lg:justify-start justify-center lg:pt-10 md:pt-10 pt-6">
                        <CustomButton btnText="Buy now" btnURL="/buy" btnColor="white" btnBackground="customBlack" />
                    </Box>


                </Box>

                <Box className="flex justify-center">
                    <img src={BookImg} alt="img" className="h-[290px] w-[245px] lg:h-auto lg:w-auto 
                    md:h-auto md:w-auto"/>
                </Box>

                <Box>

                </Box>
               </Box>
            </Box>



            {/* Makhwat section */}

            <Box className="bg-white lg:py-140px lg:px-[128px] md:py-18 md:px-16 py-6 px-[18px]
             max-w-screen-2xl mx-auto">
                <Box className="flex justify-center items-center">
                    <img src={MakTextImg} alt="image" />
                </Box>

                <CustomTypography className="lg:pt-80px pt-10"
                    as="p"
                    variant="p2"
                    color="text-SlateGray"
                    alignment="lg:text-center md:text-center text-left">
                    Mawakhat, meaning ‘solidarity between haves and have-nots,’ is a word associated with
                    a pivotal moment in Islamic history, when citizens of Mecca, the Muhajireen or
                    immigrants, relocated to Medina to escape religious persecution. The people of
                    Medina, the Ansar or helpers, welcomed the migrants selflessly, sharing half of
                    their wealth to help them start a new life; this is the spirit of kinship that
                    inspires the work of Akhuwat.
                </CustomTypography>
            </Box>



            {/*  Video sectoin  */}

            <Box className='lg:h-[718px] h-[500px] '
                style={{
                    backgroundImage: ` linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    url(${MakVideo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                <Box className='flex flex-col justify-center items-center lg:items-start h-full 
                lg:px-120px px-4  lg:w-[700px] w-auto  max-w-screen-2xl mx-auto'>
                    <p className='font-manrope text-white font-normal lg:text-[18px] text-[12px] 
                    lg:leading-[27px] leading-[18px]'>
                        Akhuwat Health Services</p>

                    <CustomTypography className="lg:pt-4 md:pt-4 pt-3"
                        as="h1"
                        variant="h21"
                        color="text-white"
                        alignment="lg:text-left md:text-left text-center"

                    >
                        The Catastrophic Floods of 2022
                    </CustomTypography>
                    <Box className='pt-6'>
                        <CustomButton btnText='Watch video' btnURL='/video' btnColor='customBlack'
                            btnBackground='white' />
                    </Box>
                </Box>
            </Box>




            {/* Poverty Block */}
            <Box className="bg-white lg:py-120px lg:px-120px md:py-18 md:px-16 py-6 px-[18px]
             max-w-screen-2xl mx-auto">
                <CustomTypography
                    as="p"
                    variant="p2"
                    color="text-SlateGray"
                >

                    In the summer of 2022, Pakistan faced the world’s deadliest floods of the last few years. The monsoon
                    caused record levels of rainfall, totalling 370mm between July and August— about 210% above the average.
                    The unrelenting rains, combined with melting glaciers due to climate change, resulted in severe flooding
                    across the country. Out of the 33 million Pakistanis, who were impacted, 1,700 people lost their lives
                    to the floods. In addition, crops, livestock, infrastructure, homes, and livelihoods were affected.
                    The floods fully or partially damaged more than 2 million houses across the country.
                </CustomTypography>


                <CustomTypography className="lg:pt-16 md:pt-60px pt-10"
                    as="p"
                    variant="p2"
                    color="text-SlateGray"
                >
                    Akhuwat carried out rehabilitation efforts across the four provinces of Pakistan and
                    helped build about 6,000+ houses for the affectees through interest-free loans.
                    Moreover, Akhuwat successfully supported over 10,000 entrepreneurs and farmers by
                    providing them interest-free loans.
                </CustomTypography>

                <CustomTypography className="lg:pt-16 md:pt-60px pt-10"
                    as="p"
                    variant="p1"
                    color="text-topGreen"
                    weightFont="font-bold"
                >

                    During the rehabilitation efforts, Akhuwat also compiled about one hundred stories and
                    portraits of the families who rebuilt their houses through Akhuwat’s support.
                </CustomTypography>

            </Box>


            {/* Img Box section */}

            <Box className="lg:pt-120px md:pt-18 pt-10  max-w-screen-2xl mx-auto">

                <img src={fullSlide1} alt="slide1" />

                <img src={fullSlide2} alt="slide1" className="lg:pt-60px md:pt-60px pt-10" />

            </Box>

            <Box className="bg-white lg:py-120px lg:px-120px md:py-18 md:px-16 py-6 px-[18px]
             max-w-screen-2xl mx-auto">


                <CustomTypography className="lg:pt-16 md:pt-60px pt-10"
                    as="p"
                    variant="p2"
                    color="text-SlateGray"
                >
                    The stories from Mawakhat take us to different areas of Pakistan and reveal to us the
                    lives of people who were left homeless by the floods. These stories capture their
                    vulnerability and invincible strength in the face of crises. They share with us the
                    pains they suffered during the floods and the misery that befall them after they
                    lost their homes. They also share with us their dreams and hopes and how they look
                    forward to rebuilding their lives.
                </CustomTypography>

                <Box className=' h-auto border-[5px] py-16 lg:px-16 px-6 border-topYellow 
                rounded-[40px] lg:mt-16 md:mt-60px mt-10'>


                    <CustomTypography
                        as="p"
                        variant="p1"
                        color="text-MidnightTest"
                        weightFont="font-bold"
                        alignment="text-center"
                    >
                        The stories in this book share the plight of the families who lost everything but hope.
                        Despite the dire circumstances, their faith in life remained unwearied. Families endured terrible
                        living  conditions in tents, living without food and clean water. They always believed that society
                        would not  leave them alone and would bring them and their loved ones back to their homes.
                        They hoped to build  homes much stronger than the ones washed by the floods.
                    </CustomTypography>


                    <CustomTypography className="pt-10"
                        as="p"
                        variant="p3"
                        color="text-SlateGray"
                        alignment="text-center"
                    >
                        Dr. Sohail Chughtai
                    </CustomTypography>

                    <CustomTypography
                        as="p"
                        variant="p3"
                        color="text-v"
                        alignment="text-center"
                        weightFont="font-normal"
                    >
                        Chairman Chughtai Foundation
                    </CustomTypography>



                </Box>
            </Box>




            {/* Stories section */}

            <Box className="bg-white lg:p-[128px] md:p-10  p-4   max-w-screen-2xl mx-auto">

                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1'>
                    <Box className='flex lg:justify-start justify-center items-center'>

                        <CustomTypography className="pt-10"
                            as="h2"
                            variant="h2"
                            color="text-customBlack"
                        >
                            Mawakhat Stories
                        </CustomTypography>
                    </Box>


                    <Box className='col-span-1 lg:flex md:flex hidden items-center justify-end '>
                        <Box
                            onClick={handlePrevStories}
                            className={`cursor-pointer ${currentSlideStories === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentSlideStories === 0 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowLeftIcon className={` ${currentSlideStories === 0 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                        <Box
                            onClick={handleNextStories}
                            className={`ml-4 cursor-pointer ${currentSlideStories === totalSlidesStories - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                            style={{ borderColor: totalSlidesStories === totalSlidesStories - 1 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowRightIcon className={`${currentSlideStories === totalSlidesStories - 1 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className='lg:pt-12 pt-8  '>

                    <Carousel className='md:hidden hidden lg:block'
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >

                        {/* Different view for mobile and desktop */}

                        <AhsStories topHead="“With the grace of God and the interest-free loan from Akhuwat, I was able to rebuild my home”" 
                        belowHead="Sadiq Ali - Dera Ghazi Khan, Punjab"
                            gridImage={Story1}
                            btnCondt="yes" 
                            gridColor='SoftVanila'
                            btnText='Mawakhat Stories'
                            textColor='text-customBlack'
                            btnColor='topGreen'/>



                    </Carousel>


                    {/* For mobile */}

                    <Carousel className='lg:hidden  '
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >



                        <Box className='rounded-[30px] overflow-hidden'>
                            <AhsStories gridImage={Story1} />
                            <AhsStories topHead="“With the grace of God and the interest-free loan from Akhuwat, I was able to rebuild my home”" 
                        belowHead="Sadiq Ali - Dera Ghazi Khan, Punjab"
                            gridImage={Story1}
                            btnCondt="yes" 
                            gridColor='SoftVanila'
                            btnText='Mawakhat Stories'
                            textColor='text-customBlack'
                            btnColor='topGreen'

                            />



                        </Box>






                    </Carousel>

                    {/* Navigation Buttons */}
                    <Box className="lg:hidden md:hidden flex justify-center pt-8">
                        <button
                            onClick={() => handleChangeSlideStories(0)}
                            className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideStories === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(1)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full focus:outline-none  ${currentSlideStories === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(2)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full   ${setCurrentSlidestories === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>


                    </Box>

                </Box>





            </Box>



            {/* Copy section */}
            <Box className="bg-topGreen">
            <Box className=" lg:py-[128px] lg:px-[128px] md:py-16 md:px-10 py-16 px-5
        grid lg:grid-cols-2 grid-cols-1 lg:gap-60px md:gap-12 gap-8  max-w-screen-2xl mx-auto">


                <Box className="flex flex-col justify-center items-center lg:items-start">
                    <CustomTypography
                        as="h1"
                        variant="h21"
                        color="text-white"
                    >
                        Get a copy of Mawakhat

                    </CustomTypography>

                    <CustomTypography className="lg:pt-4 md:pt-4 pt-3"
                        as="p"
                        variant="p2"
                        color="text-white"
                        alignment="lg:text-left text-center"
                    >
                        Mawakhat is a book where every frame tells a story. Covering four provinces, this
                        book features over one hundred portraits and stories and captures the struggles,
                        hopes, and triumphs of the brave and resilient people of Pakistan in the
                        aftermath of the devastating floods of 2022.

                    </CustomTypography>
                    <Box className="flex lg:justify-start justify-center lg:pt-10 md:pt-10 pt-6">
                        <CustomButton btnText="Buy now" btnURL="/buy" btnColor="customBlack" btnBackground="white" />
                    </Box>


                </Box>

                <Box className="flex justify-center">
                    <img src={MakCopy} alt="img" />
                </Box>

                <Box>

                </Box>
                </Box>
            </Box>
        </Box>
    )

}


export default MakhwatBook;