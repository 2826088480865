import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Users,
  UserCircle,
  BookOpen,
  Briefcase,
  FileText,
  Video,
  LayoutDashboard,
} from "lucide-react";
import { useSelector } from "react-redux";

const sidebarNavItems = [
  { title: "Dashboard", path: "/dashboard", icon: <LayoutDashboard size={20} /> },
  { title: "All Users", path: "/admin/users", icon: <Users size={20} />, restricted: true },
  { title: "Create User", path: "/admin/create/user", icon: <Users size={20} />, restricted: true },
  { title: "Roles", path: "/admin/all/roles", icon: <UserCircle size={20} />, restricted: true },
  { title: "Add Role", path: "/admin/add/role", icon: <UserCircle size={20} />, restricted: true },
  { title: "Create Blog", path: "/createBlog", icon: <BookOpen size={20} /> },
  { title: "Create Job", path: "/add/job", icon: <Briefcase size={20} /> },
  { title: "Jobs", path: "/admin/jobs", icon: <Briefcase size={20} /> },
  { title: "Text Content", path: "/text/content", icon: <FileText size={20} /> },
  { title: "Videos", path: "/media/content", icon: <Video size={20} /> },
];

export function Sidebar() {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  // Filter nav items based on authentication and role
  const filteredNavItems = sidebarNavItems.filter((item) => {
    // Show restricted items only to Super Admins
    if (item.restricted) {
      return isAuthenticated && user?.role_name === "Super_admin";
    }
    // Show non-restricted items to all authenticated users
    return isAuthenticated;
  });

  return (
    <Box
      sx={{
        width: 330,
        bgcolor: "background.paper",
        borderRight: 1,
        borderColor: "divider",
        boxShadow: 3,
        padding: "50px",
      }}
    >
      {/* Sidebar Header */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="h6" fontWeight="bold">
          Akhuwat Admin
        </Typography>
      </Box>

      {/* Sidebar Links */}
      <List>
        {filteredNavItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon sx={{ minWidth: 36 }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* Optional Divider */}
      <Divider />
    </Box>
  );
}
