import { Box } from "@material-ui/core";
import React, { useState } from 'react';
import banner from "../pictures/perwaz_banner.png";
import logo from "../pictures/parwaz_logo.png";
import insta from "../pictures/parwaz-insta.png";
import mail from "../pictures/parwaz-mail.png";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Cycle from "../pictures/parwaz_cycle_img.png";
import ParwazAfpTestimonials from "./ParwazAfpTestimonials";
import CustomCarousel from "../CustomCarasouel";
import AhsStories from "../ACB_AHS/AhsStories";
import Story1 from "../pictures/Parwaz_story1.png";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
    borderRadius: '16px',
    backgroundColor: expanded ? 'white' : '#F7F7F7',
    padding: '16px 16px 16px 16px',
    gap: theme.spacing(0),
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
        padding: '16px 8px 16px 8px',
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            props.expanded ? (
                <RemoveCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#737373', width: '24px', height: '24px', marginRight: '8px' }} />
            ) : (
                <AddCircleOutlineIcon sx={{ fontSize: '1.2rem', color: '#737373', width: '24px', height: '24px', marginRight: '8px' }} />
            )
        }
        {...props}
    />
))(({ theme, expanded }) => ({

    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: '8px',
    paddingLeft: '55px'
}));

const PerwazProgram = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };



    const [currentSlideStories, setCurrentSlidestories] = useState(0);//stories
    const totalSlides = 4, totalSlidesStories = 4;
    //disaster response page





    const handleNextStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === totalSlides - 1 ? 0 : prevSlide + 1));
    };

    const handlePrevStories = () => {
        setCurrentSlidestories((prevSlide) => (prevSlide === 0 ? totalSlides - 1 : prevSlide - 1));
    };

    const handleChangeSlideStories = (index) => {
        setCurrentSlidestories(index); // Update current slide index when a button is clicked
    };



    const slides = [
        <ParwazAfpTestimonials

            AfpHead="Microfinance and Entrepreneurship"
            AfpDesc="Participants are introduced to the core principles of microfinance and Akhuwat’s transformative impact on communities. They learn how small loans can empower individuals to start businesses, thereby facilitating economic growth and reducing poverty. The program also informs the participants about the impact Akhuwat has been able to create in the last two decades through its interest-free microfinance."
        />,
        <ParwazAfpTestimonials

            AfpHead="Innovative Solutions in Education"
            AfpDesc="The fellowship challenges participants to think critically about the educational landscape in Pakistan, particularly the challenges faced by underprivileged and deserving students. The fellows are informed about the various initiatives of Akhuwat Education Services (AES) and how it provides fee-free education to students from far-flung regions of the country. The fellows are encouraged to propose innovative solutions that enhance educational access and quality. "
        />,
        <ParwazAfpTestimonials

            AfpHead="Gender Sensitivity and Inclusion"
            AfpDesc="The program places a strong emphasis on understanding and addressing gender dynamics in society. Participants undergo gender sensitivity training and explore ways to support marginalized groups, including the Khwajasira community. By fostering a deep appreciation for diversity and inclusion, the fellowship prepares fellows to advocate for gender equality and create inclusive environments in their personal and professional lives.  "
        />,
        <ParwazAfpTestimonials

            AfpHead="Microfinance and Entrepreneurship"
            AfpDesc="Participants are introduced to the core principles of microfinance and Akhuwat’s transformative impact on communities. They learn how small loans can empower individuals to start businesses, thereby facilitating economic growth and reducing poverty. The program also informs the participants about the impact Akhuwat has been able to create in the last two decades through its interest-free microfinance."
        />,
    ];

    return (
        <Box>
            {/* Banner section */}
            <Box className="bg-primaryLight">
                <Box className="lg:py-60px lg:px-120px  md:py-18 md:px-16 py-6 px-4
            max-w-screen-2xl mx-auto">
                    <Box className="grid lg:grid-cols-[auto_minmax(0,448px)] md:grid-cols-[auto_minmax(0,237px)] grid-cols-[auto_minmax(0,106px)] overflow-hidden rounded-[32px]">
                        <Box className="lg:p-[50px] md:p-[26px] p-3 bg-white flex flex-col justify-between">
                            <h1 className="font-Maison text-customGreen font-semibold lg:text-[62px] lg:leading-[62px] md:text-[32px] md:leading-[32px] text-[14.71px] leading-[14.71px]">
                                Akhuwat Fellowship Program - Parwaaz
                            </h1>

                            <Box className="flex justify-start">
                                <img src={logo} alt="logo" className="lg:h-[128px] lg:w-[78px] md:w-[41px] md:h-[68px] w-[18px] h-[30px]" />
                            </Box>
                        </Box>

                        <Box className="lg:w-[448px] md:w-[237px] w-[106px]">
                            <img src={banner} alt="banner" />
                        </Box>
                    </Box>

                    <Box className="lg:py-6 md:py-6 py-3 border-b border-b-schoolYellow grid lg:grid-cols-3 md:grid-cols-4 grid-cols-1 justify-between   gap-3">
                        <Box>
                            <CustomTypography as="p" variant="p3" color="text-Charcol"
                                weightFont="font-normal">
                                6th Sep, 2024
                            </CustomTypography>
                        </Box>

                        <Box className="flex lg:w-[448px] lg:col-span-1 md:col-span-2">
                            <Box className="flex">
                                <img src={mail} alt="mail" className="object-contain" />
                                <CustomTypography className="ml-2" as="p" variant="p3" color="text-Charcol" weightFont="font-normal">
                                    info.parwaaz@akhuwat.org.pk
                                </CustomTypography>
                            </Box>
                        </Box>

                        <Box>
                            <Box className="flex">
                                <img src={insta} alt="instagram" className="object-contain" />
                                <CustomTypography className="ml-2" as="p" variant="p3" color="text-Charcol" weightFont="font-normal">
                                    @parwaazofficial
                                </CustomTypography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Below banner section */}
            <Box className="lg:py-60px lg:px-120px bg-white md:py-18 md:px-16 py-6 px-[18px]
             max-w-screen-2xl mx-auto">
                <CustomTypography as="p" variant="p2" color="text-customBlack" weightFont="font-normal" alignment="lg:text-left md:text-left text-center">
                    Founded in 2011, the Akhuwat Fellowship Program seeks to build and cultivate a community of changemakers, creative entrepreneurs, and moral leaders committed to making a difference. Akhuwat has now trained over a thousand young adults to play their part in resolving the varied and complex development challenges facing the world today.
                </CustomTypography>

                <Box className="lg:h-[680px] bg-customGray rounded-[30px] lg:mt-90px md:h-[396px] h-[325px] md:mt-80px mt-8"></Box>

                <CustomTypography className="lg:mt-90px md:mt-80px mt-8" as="p" variant="p2" color="text-customBlack" weightFont="font-normal" alignment="lg:text-left md:text-left text-center">
                    During their time at Akhuwat, fellows get extensive exposure to the various facets of Akhuwat via a comprehensive social development training platform. Their exposure combined with field work, project development, and research provides them with the skills needed to excel in their fields.
                </CustomTypography>
            </Box>


            {/* Application cycle */}



            <Box className="bg-white lg:p-120px  md:p-18  p-6  max-w-screen-2xl mx-auto ">

                <Box className="grid lg:grid-cols-2 grid-cols-1 rounded-[30px] overflow-hidden">

                    {/* Left Column */}
                    <Box className="lg:py-60px lg:px-60px md:py-10 md:px-10 py-6 px-3 bg-PowderColor flex flex-col justify-between">
                        <CustomTypography
                            as="h2" variant="h2" color="text-DarkBlack"
                            alignment="text-left"
                        >
                            Application Cycle
                        </CustomTypography>

                        {/* Timeline Items */}
                        {[
                            { step: 1, text: "April  Online applications open, in order to be eligible for the process each applicant must submit a complete online application." },
                            { step: 2, text: "Early May  Candidates are short-listed and calls for interviews are made." },
                            { step: 3, text: "Late May  Interviews are conducted and selected candidates are informed about application decisions." },
                            { step: 4, text: "Late March – Early April  Call for applications is sent out." },
                            { step: 5, text: "June  The Akhuwat Fellowship Program begins!" }
                        ].map((item, index) => (
                            <Box key={index} className={`flex pt-${index === 0 ? '10' : '3'}`}>
                                <Box className=" absolute  w-10 h-10 flex items-center justify-center  rounded-full bg-topYellow object-contain">
                                    <CustomTypography
                                        as="p" variant="p2" color="text-customBlack"
                                        alignment="text-center"
                                        weightFont="font-semibold"
                                        className="w-[40px] h-[40px] flex items-center justify-center"
                                    >
                                        {item.step}
                                    </CustomTypography>
                                </Box>
                                <CustomTypography className="ml-14 mt-2"
                                    as="p" variant="p3" color="text-customBlack"
                                    alignment="text-left"
                                    weightFont="font-normal"
                                >
                                    {item.text}
                                </CustomTypography>
                            </Box>
                        ))}
                    </Box>

                    {/* Right Column - Image */}
                    <Box className="bg-PowderColor flex justify-center items-center">
                        <img src={Cycle} alt="cycle" className="object-cover h-full w-full" />
                    </Box>

                </Box>


            </Box>


            {/* AFP testimonial section */}
            <Box className="bg-PowderColor  max-w-screen-2xl mx-auto ">
                <Box className=" lg:p-120px  md:p-18  p-6 
            ">
                    <CustomTypography
                        as="h2" variant="h21" color="text-topGreen"
                        alignment="text-center"
                        weightFont="font-bold"
                    >
                        What AFP Covers
                    </CustomTypography>


                    <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                        as="p" variant="p2" color="text-SlateGray"
                        alignment="text-center"
                        weightFont="font-normal"
                    >
                        The Akhuwat Fellowship Program at Parwaaz teaches its participants the following:
                    </CustomTypography>

                </Box>
                {/* carasouel */}

                <Box className="lg:pb-120px md:pb-18 pb-6">

                    <CustomCarousel slides={slides} slideWidth={{
                        lg: 478,
                        md: 321,
                        sm: 310
                    }} gap={40}
                        ArrowPosition="bottom"
                        ArrowAligned="end"
                        ArrowActiveColor="#4D4D4D"

                    />
                </Box>
            </Box>



            {/* Stories section */}

            <Box className="bg-white lg:p-[128px] md:p-10  p-4  max-w-screen-2xl mx-auto">

                <Box className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1'>
                    <Box className='flex lg:justify-start justify-center items-center'>
                        <CustomTypography as="h2" variant="h2" color="text-customBlack" weightFont="font-bold"
                            alignment="lg:text-left md:text-left text-center">
                            What out Alumni think?
                        </CustomTypography>
                    </Box>


                    <Box className='col-span-1 lg:flex md:flex hidden items-center justify-end '>
                        <Box
                            onClick={handlePrevStories}
                            className={`cursor-pointer ${currentSlideStories === 0 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-white flex items-center justify-center`}
                            style={{ borderColor: currentSlideStories === 0 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowLeftIcon className={` ${currentSlideStories === 0 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                        <Box
                            onClick={handleNextStories}
                            className={`ml-4 cursor-pointer ${currentSlideStories === totalSlidesStories - 1 ? 'pointer-events-none opacity-50' : ''} w-12 h-12 rounded-full border-[2px] border-#2A2A2A flex items-center justify-center`}
                            style={{ borderColor: totalSlidesStories === totalSlidesStories - 1 ? '#A6A6A6' : '#2A2A2A' }}
                        >
                            <KeyboardArrowRightIcon className={`${currentSlideStories === totalSlidesStories - 1 ? 'text-[#A6A6A6]' : 'text-customBlack'}`} />
                        </Box>
                    </Box>
                </Box>

                <Box className='lg:pt-12 pt-8  '>

                    <Carousel className='md:hidden hidden lg:block'
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >

                        {/* Different view for mobile and desktop */}

                        <AhsStories topHead="They not only taught me how to lead but also how to inspire and motivate 
        others to join me in creating positive change." belowHead="Usman Javed - Alumni"
                            gridImage={Story1}
                            btnCondt="yes"
                            gridColor='MidnightTest'
                            btnText='Parwaaz Fellowship'
                            textColor='text-white'
                            btnColor='white'
                        />



                    </Carousel>


                    {/* For mobile */}

                    <Carousel className='lg:hidden  '
                        selectedItem={currentSlideStories}
                        onChange={(index) => setCurrentSlidestories(index)}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        interval={5000}
                        showIndicators={false}
                        autoPlay
                        infiniteLoop
                        emulateTouch // Ensures smooth touch behavior
                        swipeable // Allows swipe gestures on mobile devices
                        width="100%"
                    >



                        <Box className='rounded-[30px] overflow-hidden'>
                            <AhsStories gridImage={Story1} />
                            <AhsStories topHead="I am very grateful to the brotherhood who held our hands 
        when there was no other support." belowHead="Sanam"
                                btnCondt="yes"
                                gridColor='MidnightTest'
                                btnText='Parwaaz Fellowship'
                                textColor='text-white'
                                btnColor='white'

                            />



                        </Box>






                    </Carousel>

                    {/* Navigation Buttons */}
                    <Box className="lg:hidden md:hidden flex justify-center pt-8">
                        <button
                            onClick={() => handleChangeSlideStories(0)}
                            className={` w-[48px] h-[4px]  rounded-full focus:outline-none ${currentSlideStories === 0 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(1)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full focus:outline-none  ${currentSlideStories === 1 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>
                        <button
                            onClick={() => handleChangeSlideStories(2)}
                            className={`w-[48px] h-[4px] ml-[6px]  rounded-full   ${setCurrentSlidestories === 2 ? 'bg-topGreen' : 'bg-topAlmond'
                                }`}
                        ></button>


                    </Box>

                </Box>





            </Box>

            {/* FAQ section */}
            <Box className="bg-customGray">
                <Box className=" max-w-screen-2xl mx-auto  lg:py-120px lg:px-[180px] md:py-18 md:px-16 py-6 px-[18px]">
                    <CustomTypography as="h2" variant="h2" color="text-SlateGray" alignment="text-center" weightFont="font-bold">
                        Frequently Asked Questions (FAQs)
                    </CustomTypography>

                    <Box className="flex lg:justify-center justify-start lg:pt-10 pt-8">
                        <Box className="flex flex-col lg:items-center items-left w-full max-w-[768px]">
                            {/* Accordion 1 */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{
                                width: '100%',

                            }}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-semibold">
                                        How can I make a donation?
                                    </CustomTypography>

                                </AccordionSummary>
                                <AccordionDetails style={{ justifyContent: 'center' }}>
                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-normal">
                                        You can make a donation through our secure online donation form.
                                        Simply click on the "Donate Now" button on our homepage, choose your
                                        preferred donation amount, and follow the instructions to complete
                                        your contribution.
                                    </CustomTypography>


                                </AccordionDetails>
                            </Accordion>

                            {/* Accordion 2 */}
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{
                                width: '100%'
                            }}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">


                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-semibold">
                                        Is my donation tax-deductible?
                                    </CustomTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{ justifyContent: 'center' }}>
                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-normal">
                                        You can make a donation through our secure online donation form.
                                        Simply click on the "Donate Now" button on our homepage, choose your
                                        preferred donation amount, and follow the instructions to complete
                                        your contribution.
                                    </CustomTypography>
                                </AccordionDetails>
                            </Accordion>

                            {/* Accordion 3 */}
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{
                                width: '100%'
                            }}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">


                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-semibold">
                                        How can I get involved with Akhuwat?
                                    </CustomTypography>
                                </AccordionSummary>
                                <AccordionDetails style={{ justifyContent: 'center' }}>
                                    <CustomTypography as="p" variant="p3" color="text-SlateGray"
                                        weightFont="font-normal">
                                        You can make a donation through our secure online donation form.
                                        Simply click on the "Donate Now" button on our homepage, choose your
                                        preferred donation amount, and follow the instructions to complete
                                        your contribution.
                                    </CustomTypography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>

                    <Box className="py-3 border-t border-t-SlateGray mt-6">

                        <CustomTypography
                            as="p" variant="p2" color="text-topGray"
                            weightFont="font-normal"
                            alignment="text-center"
                        >
                            For questions and queries, please reach out to us at info@parwaaz.com.pk.

                        </CustomTypography>
                    </Box>
                </Box>
            </Box>



            {/* Support section */}
            <Box className="bg-customGreen">
                <Box className="max-w-screen-2xl mx-auto  lg:py-[128px] lg:px-120px md:py-16 md:px-10 py-8 px-4">

                    <CustomTypography
                        as="h2"
                        variant="h2"
                        color="text-simpleGreen"
                        alignment=" text-center"
                    >
                        Support Us

                    </CustomTypography>




                    <CustomTypography className="pt-5"
                        as="p"
                        variant="p2"
                        color="text-white"
                        alignment=" text-center"
                        weightFont="font-semibold"
                    >
                        Support the Akhuwat Fellowship Program to empower the next generation of leaders who are committed to
                        creating sustainable, inclusive change in our communities. Your donation will help provide these young
                        visionaries with the skills, knowledge, and opportunities they need to drive impactful social innovation
                        across Pakistan. Join us in shaping a brighter future by investing in those who will lead it.

                    </CustomTypography>

                    <Box className="flex justify-center pt-7">
                        <CustomButton btnURL="/donae" btnText="Donate" btnColor="customBlack"
                            btnBackground="white" />
                    </Box>

                </Box>

            </Box>
        </Box>
    );
};

export default PerwazProgram;
