import React, { Fragment, useState, useEffect } from "react";
import { register, clearErrors, getAllRoles, clearSuccess } from "../Actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Loader";
import { useAlert } from 'react-alert';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import { Link } from "react-router-dom";
import { Sidebar } from "./Sidebar";

const Register = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, loading, success } = useSelector(state => state.newUser);
    const { roles } = useSelector(state => state.adminRoles) || {};

    const [registerName, setRegisterName] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [newRole, setNewRole] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");

    const loginSubmit = (e) => {
        e.preventDefault();
        dispatch(register(registerName, registerEmail, registerPassword, newRole, firstName,
            lastName, mobileNumber, dateOfBirth));
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (success) {
            alert.success('New user added successfully');
            dispatch(clearSuccess());
            navigate('/admin/users')
        }
        dispatch(getAllRoles());
    }, [dispatch, error, success, alert, navigate]);

    return (
        <Fragment>
            {loading ? <LoadingSpinner /> : (
                <div className="flex min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 pb-6 pr-6">
                    <Sidebar />
                    <div className="flex-1 mt-6 p-6 max-w-3xl mx-auto bg-white shadow-lg rounded-xl">
                        <h2 className="text-3xl font-extrabold text-gray-800 text-center mb-6 animate-fade-in-down">
                            Create an Account
                        </h2>
                        <form className="space-y-6" onSubmit={loginSubmit}>
                            {/* Grid layout for fields */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* First Name */}
                                <div className="relative group">
                                    <PersonIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Last Name */}
                                <div className="relative group">
                                    <PersonIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Username */}
                                <div className="relative group">
                                    <PersonIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={registerName}
                                        onChange={(e) => setRegisterName(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Email */}
                                <div className="relative group">
                                    <EmailIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        value={registerEmail}
                                        onChange={(e) => setRegisterEmail(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Password */}
                                <div className="relative group">
                                    <LockIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={registerPassword}
                                        onChange={(e) => setRegisterPassword(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Mobile Number */}
                                <div className="relative group">
                                    <PhoneIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="text"
                                        placeholder="Mobile Number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Date of Birth */}
                                <div className="relative group">
                                    <CakeIcon className="absolute left-4 top-3 text-gray-400 group-hover:text-indigo-600 transition" />
                                    <input
                                        type="date"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-300 focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    />
                                </div>

                                {/* Role */}
                                <div>
                                    
                                    <select
                                        value={newRole}
                                        onChange={(e) => setNewRole(e.target.value)}
                                        className="w-full px-4 py-3 border-2 border-gray-300 rounded-lg focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition"
                                        required
                                    >
                                        <option value="" disabled>Select Role</option>
                                        {roles && roles.map((role) => (
                                            <option key={role.roleid} value={role.roleid}>
                                                {role.role_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Submit Button */}
                            <Button
                                type="submit"
                                disabled={loading}
                                variant="contained"
                                endIcon={<SendIcon />}
                                className="w-full py-3 mt-6 bg-gradient-to-r from-indigo-500 to-pink-500 text-white text-lg font-bold rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                            >
                                Register
                            </Button>

                            <p className="text-center text-gray-500 mt-4">
                                Already Registered?{" "}
                                <Link to="/login" className="text-indigo-600 hover:underline">
                                    Login
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Register;
