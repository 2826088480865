import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoadingSpinner from "../Loader";
import CustomTypography from "../CustomTypography";

const AddRoleLine = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [authorization, setAuthorization] = useState("");
  const [pages, setPages] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [pageid, setPageId] = useState("");
  const [roleid, setRoleId] = useState(id);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_BASE_URL);
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true, // Include cookies
};

  const handleDeletePermission = async (permissionId) => {
    try {
      setLoading(true);
      await axios.delete(`${API_BASE_URL}/api/v1/admin/all/permissions/${permissionId}`,config);
      Swal.fire({
        icon: "success",
        title: "Deleted",
        text: "Permission deleted successfully!",
      });

      // Refresh the remaining permissions
      const updatedPermissionsResponse = await axios.get(
        `${API_BASE_URL}/api/v1/admin/all/permissions/${id}`,config
      );
      setPermissions(updatedPermissionsResponse.data.data || []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to delete permission.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!roleid || !pageid || !authorization.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "All fields are required.",
      });
      return;
    }

    setLoading(true);
    try {
      await axios.post(`${API_BASE_URL}/api/v1/role/detail/new`, {
        roleid,
        pageid,
        authorization,
      },config);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Permission added successfully!",
      });

      const updatedPermissionsResponse = await axios.get(
        `${API_BASE_URL}/api/v1/admin/all/permissions/${id}`,config
      );
      setPermissions(updatedPermissionsResponse.data.data || []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message?.errors?.[0]?.message || "Failed to add permission.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      setLoading(true);
      try {
        const [pagesResponse, rolesResponse, permissionResponse] =
          await Promise.all([
            axios.get(`${API_BASE_URL}/api/v1/admin/all/pages`,config),
            axios.get(`${API_BASE_URL}/api/v1/admin/roles/${id}`,config),
            axios.get(`${API_BASE_URL}/api/v1/admin/all/permissions/${id}`,config),
          ]);

        const fetchedRole = rolesResponse.data.data;
        setRoleName(fetchedRole.role_name);
        setRoleId(fetchedRole.roleid);
        setRoleDescription(fetchedRole.role_description);
        setPermissions(permissionResponse.data.data || []);
        setPages(pagesResponse.data.data || []);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "Failed to load data.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDropdownData();
  }, [id]);

  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-col items-center min-h-screen bg-gray-100 p-6">
          <div className="w-full max-w-6xl bg-white p-8 rounded-lg shadow-md space-y-8">
            {/* Display Section */}
            <div className="grid gap-6 md:grid-cols-2">
              <div className="p-6 bg-blue-100 rounded-md shadow">
                <h3 className="text-xl font-bold text-blue-900">Role Name</h3>
                <p className="text-lg text-blue-700 mt-2">{roleName}</p>
              </div>
              <div className="p-6 bg-green-100 rounded-md shadow">
                <h3 className="text-xl font-bold text-green-900">
                  Role Description
                </h3>
                <p className="text-lg text-green-700 mt-2">{roleDescription}</p>
              </div>
            </div>

            {/* Permissions Table */}
            <Box>
              <CustomTypography
                as="h2"
                variant="h2"
                alignment="text-center"
                color="text-customBlack"
              >
                All Permissions
              </CustomTypography>
              <TableContainer
                component={Paper}
                className="p-4 mt-6"
                sx={{ boxShadow: 3 }}
              >
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "red" }}>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Page Name
                      </TableCell>
                      <TableCell style={{ color: "white", fontWeight: "bold" }}>
                        Authorization
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions.length > 0 ? (
                      permissions.map((permission) => (
                        <TableRow
                          key={permission.role_line_id}
                          sx={{
                            "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                          }}
                        >
                          <TableCell>{permission.page_name}</TableCell>
                          <TableCell>{permission.authorization}</TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <IconButton color="primary">
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                handleDeletePermission(permission.role_line_id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No Permissions Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* Form Section */}
            <form onSubmit={handleSubmit} className="space-y-6 pt-[80px]">
              <div className="grid gap-6 md:grid-cols-3">
                <div>
                  <label
                    htmlFor="pages"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Select Page
                  </label>
                  <select
                    id="pages"
                    value={pageid}
                    onChange={(e) => setPageId(e.target.value)}
                    className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    <option value="">Select a Page</option>
                    {pages.map((page) => (
                      <option key={page.pageid} value={page.pageid}>
                        {page.page_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="authorization"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Select Permission
                  </label>
                  <select
                    id="authorization"
                    value={authorization}
                    onChange={(e) => setAuthorization(e.target.value)}
                    className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  >
                    <option value="">Select a Permission</option>
                    <option value="SEO">SEO</option>
                    <option value="Content">Content</option>
                  </select>
                </div>

              </div>

              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition duration-200"
              >
                Apply Changes
              </button>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddRoleLine;
