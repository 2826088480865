import { Box } from "@material-ui/core";
import React from "react";
import PhilosphyBanner from "../pictures/philosophy-banner.png";
import CustomTypography from "../CustomTypography";

const AkhuwatPhilosophy = () => {


    return (

        <Box>


            <Box
                className="lg:h-[688px] md:h-[426px] h-[245px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${PhilosphyBanner})
                    `
                }}
            >

            </Box>

            {/* About us section */}

            <Box className="max-w-screen-2xl mx-auto lg:pt-[72px] pt-10  lg:pb-60px md:pb-10 lg:px-[260px] md:px-10 px-4 pb-4 bg-white">

                <CustomTypography
                    as='p' variant='p2' color='text-topGray' weightFont='font-semibold' alignment='text-center'
                >
                    About Us
                </CustomTypography>


                <CustomTypography
                    as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Akhuwat’s Philosophy
                </CustomTypography>

                <CustomTypography className="pt-10"
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-semibold' alignment='text-center'
                >
                    Akhuwat gives interest-free loans, educates deserving students, and supports the underprivileged in our society.
                </CustomTypography>

            </Box>


            <Box className="max-w-screen-2xl mx-auto lg:pt-[72px] pt-10  lg:px-140px md:px-14 px-4  bg-white">

                <CustomTypography
                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    In 622 CE, the Muhajireen (Immigrants) escaped their hometown Mecca due to religious
                    persecution and moved to Medina. Prophet Muhammad (Peace be Upon him) called on the
                    Ansars (helpers) of Medina to share their wealth with the Muhajirs (immigrants) and
                    treat them like brothers. The Ansars responded positively to the call for Mawakhat
                    (solidarity) and generously supported their destitute brothers and sisters. The event
                    didn’t only defuse the crisis of the Muhajirs, but also, laid down the enduring
                    tradition of Mawakhat: solidarity and social responsibility towards one’s fellow
                    citizens.
                </CustomTypography>



            </Box>



            <Box className="max-w-screen-2xl mx-auto lg:pt-[72px] pt-10 lg:px-120px md:px-10 px-4 lg:pb-[72px]  pb-10   bg-white">

                <Box className="lg:py-16 md:py-10 py-6 lg:px-140px md:px-14 px-4 border-[5px] border-topYellow rounded-[40px]">

                    <CustomTypography
                        as='p' variant='p1' color='text-MidnightTest' weightFont='font-semibold' alignment='text-center'
                    >
                        From the beginning, Akhuwat adopted Qarz-a-Hassan, a term used by the Qur’an that literally
                        translates to “a beautiful loan”, as its core strategy for microfinance.
                    </CustomTypography>
                </Box>


                <Box className="lg:pt-[72px] pt-10">

                    <CustomTypography
                        as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                    >This concept is an attempt to reignite the same pledge of Mawakhat (solidarity)
                        and use it as a guiding light. Although Akhuwat derives its inspiration from the
                        Islamic tradition of Mawakhat, it does not discriminate on the basis of religion,
                        sect, caste, color, creed or gender. It provides support to all those in need,
                        particularly  ethnic and religious minorities with the hope to create a better and
                        more just world; a world advocating mutual fraternity and inter-faith harmony.
                        Akhuwat propagates inclusion instead of expulsion, social entrepreneurship rather
                        than charity, and growth as opposed to stagnation.
                    </CustomTypography>

                </Box>

            </Box>

        </Box>
    )
}

export default AkhuwatPhilosophy;