import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const FaqCarousel = ({
    slides,
    slideWidth = { lg: 478, md: 400, sm: 310 },
    gap = 40,
    autoplay = true,
    interval = 3000,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesPerPage, setSlidesPerPage] = useState(1);
    const [currentSlideWidth, setCurrentSlideWidth] = useState(slideWidth.lg);
    const slideContainerRef = useRef(null);

    // Clone slides to create a looping effect
    const clonedSlides = [...slides, ...slides, ...slides]; // Tripling slides for continuous loop effect

    const updateSlidesPerPage = () => {
        const totalWidth = window.innerWidth;
        let appliedSlideWidth = slideWidth.lg;

        if (totalWidth < 768) {
            appliedSlideWidth = slideWidth.sm;
        } else if (totalWidth <= 1024) {
            appliedSlideWidth = slideWidth.md;
        }

        const slidesToShow = Math.floor((totalWidth + gap) / (appliedSlideWidth + gap));
        setSlidesPerPage(slidesToShow || 1);
        setCurrentSlideWidth(appliedSlideWidth);
    };

    useEffect(() => {
        updateSlidesPerPage();
        window.addEventListener('resize', updateSlidesPerPage);
        return () => window.removeEventListener('resize', updateSlidesPerPage);
    }, [slideWidth, gap]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    // Autoplay effect
    useEffect(() => {
        let autoplayInterval;
        if (autoplay) {
            autoplayInterval = setInterval(nextSlide, interval);
        }
        return () => {
            if (autoplayInterval) clearInterval(autoplayInterval);
        };
    }, [autoplay, interval]);

    // Adjust translate based on current index and reset for infinite effect
    useEffect(() => {
        const offset = (currentSlideWidth + gap) * currentIndex;
        slideContainerRef.current.style.transition = 'transform 0.5s ease';
        slideContainerRef.current.style.transform = `translateX(${-offset}px)`;

        // Infinite loop logic to reset index after reaching midpoint
        const totalSlides = slides.length;
        if (currentIndex >= totalSlides * 2) {
            // Jump to start of the cloned slides after passing midpoint
            setTimeout(() => {
                slideContainerRef.current.style.transition = 'none';
                setCurrentIndex(totalSlides); // Reset position to the start of the cloned slides
            }, 500); // Timing to match transition duration
        }
    }, [currentIndex, currentSlideWidth, gap, slides.length]);

    return (
        <Box className="carousel-container overflow-hidden" style={{ width: '100%' }}>
            <Box
                ref={slideContainerRef}
                className="flex slider-wrapper"
                style={{
                    width: '100%',
                    display: 'flex',
                }}
            >
                {clonedSlides.map((slide, index) => (
                    <Box
                        key={index}
                        className="slide"
                        style={{
                            minWidth: `${currentSlideWidth}px`,
                            marginRight: `${gap}px`,
                            boxSizing: 'border-box',
                        }}
                    >
                        {slide} {/* Slide content goes here */}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default FaqCarousel;
