import React from 'react';
import ReactDOM from 'react-dom';
import './output.css'; // Import Tailwind CSS
import App from './App';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import '@fontsource/manrope'; // Import Manrope font
import '@fontsource/bebas-neue'; // Import Bebas Neue font
import { Provider } from 'react-redux'; // Import Redux Provider
import Store from './Component/Store'; // Adjust the import path if necessary

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

ReactDOM.render(
  <Provider store={Store}> {/* Wrap with Redux Provider */}
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);


