import { Box } from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography";
import CustomButton from "../ReusableComponents/CustomButton";
import Location from "../pictures/contact-location.png";
import Mobile from "../pictures/contact-mobile.png";




const ContactUs = () => {

    return (
        <Box>
            <Box className="lg:py-[128px] lg:px-[128px] md:py-16 md:px-16 py-10 px-8 bg-primaryLight">
                    <Box className="max-w-screen-2xl mx-auto">
                <CustomTypography
                    as='h2' variant='h21' color='text-topGreen' weightFont='font-bold' alignment='text-center'
                >
                    Contact Us
                </CustomTypography>



                <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                    as='p' variant='p1' color='text-SlateGray' weightFont='font-normal' alignment='text-center'
                >
                    Have questions or want to get involved in our mission of poverty alleviation? Reach
                    out to us, and let's build a more inclusive society rooted in the philosophy of
                    Mawakhat. Together, we can create lasting impact and empower communities.
                </CustomTypography>
                </Box>
            </Box>


                <Box className=" bg-PowderColor">
            <Box className="py-16 lg:px-16 md:px-16  px-4 max-w-screen-2xl mx-auto grid lg:grid-cols-3 md:grid-cols-2 
            grid-cols-1 gap-10">


                <Box>

                    <CustomTypography
                        as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' alignment='text-left'
                    >
                        Office
                    </CustomTypography>



                    <CustomTypography className="pt-3"
                        as='p' variant='p3' color='text-Charcol' weightFont='font-normal' alignment='text-left'
                    >
                        19 Civic Center, Sector A2, Township, Lahore, Pakistan
                    </CustomTypography>

                </Box>


                <Box>

                    <CustomTypography
                        as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' alignment='text-left'
                    >
                        Email
                    </CustomTypography>


                    <Box className="flex flex-row gap-3 pt-3">
                        <CustomTypography
                            as='p' variant='p3' color='text-Charcol' weightFont='font-normal' alignment='text-left'
                        >
                            For queries:
                        </CustomTypography>

                        <a href="mailto:info@akhuwat.org.pk">   <CustomTypography
                            as='p' variant='p3' color='text-topGreen' weightFont='font-normal' alignment='text-left'
                        >
                            info@akhuwat.org.pk
                        </CustomTypography>  </a>
                    </Box>


                    <Box className="flex flex-row gap-3 pt-3">
                        <CustomTypography
                            as='p' variant='p3' color='text-Charcol' weightFont='font-normal' alignment='text-left'
                        >
                            For complaints:
                        </CustomTypography>

                        <a href="mailto:complaints@akhuwat.org.pk">   <CustomTypography
                            as='p' variant='p3' color='text-topGreen' weightFont='font-normal' alignment='text-left'
                        >
                            complaints@akhuwat.org.pk
                        </CustomTypography>  </a>
                    </Box>



                </Box>




                <Box>

                    <CustomTypography
                        as='p' variant='p2' color='text-customBlack' weightFont='font-semibold' alignment='text-left'
                    >
                        Phone
                    </CustomTypography>



                    <Box className="pt-3">


                        <a href="tel:+042 111 448 464" >   <CustomTypography
                            as='p' variant='p3' color='text-topGreen' weightFont='font-normal' alignment='text-left'
                        >
                            +042 111 448 464
                        </CustomTypography>  </a>





                        <a href="tel:+92 42 35122743">   <CustomTypography
                            as='p' variant='p3' color='text-topGreen' weightFont='font-normal' alignment='text-left'
                        >
                            +92 42 35122743
                        </CustomTypography>  </a>


                    </Box>






                    </Box>
                </Box>

            </Box>


            {/* Contact form */}

            <Box className="max-w-screen-2xl mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-120px md:gap-80px gap-10 bg-white lg:py-120px lg:px-16 md:py-18 md:px-16 py-6 px-4">

                <Box>


                    <CustomTypography className="lg:pt-10 md:pt-10 pt-6"
                        as='p' variant='p1' color='text-topGreen' weightFont='font-semibold' alignment="lg:text-left text-center"
                    >
                        Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum
                        eu. Curabitur pellentesque nibh nibh,
                    </CustomTypography>

                </Box>




                <Box className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6">
                    {/* Name Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Name
                        </label>
                        <input
                            type="text"
                            placeholder="Enter your full name"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Email Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Email
                        </label>
                        <input
                            type="email"
                            placeholder="Enter your Email"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Phone Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            placeholder="Enter your phone number"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Company Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Company
                        </label>
                        <input
                            type="text"
                            placeholder="Enter your company name"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Website Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Website
                        </label>
                        <input
                            type="url"
                            placeholder="Enter your website URL"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Select Service Field */}
                    <Box className="relative">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Select Service
                        </label>
                        <select
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[56px] border border-black rounded-[4px] px-3 outline-none"
                        >
                            <option value="">Select a service</option>
                            <option value="service1">Service 1</option>
                            <option value="service2">Service 2</option>
                            <option value="service3">Service 3</option>
                        </select>
                    </Box>

                    {/* Message Field */}
                    <Box className="relative lg:col-span-2 md:col-span-2 ">
                        <label className="absolute font-manrope -top-2 left-2 bg-white px-1 text-[12px] text-SurfaceVariant">
                            Message
                        </label>
                        <textarea
                            placeholder="Enter your message"
                            className="p-1 text-[16px] font-manrope text-blackSurface w-full h-[172px] border border-black rounded-[4px] px-3 outline-none"
                        />
                    </Box>

                    {/* Submit Button */}
                    <Box className="flex justify-center lg:col-span-2 md:col-span-2">
                        <CustomButton btnText="Submit" btnColor="white" btnBackground="customBlack" />
                    </Box>
                </Box>


            </Box>


            {/* Branch section */}

            <Box className="lg:py-60px lg:px-120px md:py-[72px] md:px-16 py-6 px-4 bg-customGreen flex lg:flex-row flex-col justify-between">

                <Box className="lg:w-[521px] w-auto">


                    <CustomTypography
                        as='p' variant='p3' color='text-MintGreen' weightFont='font-semibold' alignment="lg:text-left md:text-left text-center"
                    >
                        BRANCH NETWORK
                    </CustomTypography>



                    <CustomTypography className="pt-2"
                        as='h2' variant='h2' color='text-white' weightFont='font-bold' alignment="lg:text-left md:text-left text-center"
                    >
                        Visit an Akhuwat branch today
                    </CustomTypography>

                </Box>

                <Box className="relative flex lg:justify-center md:justify-start justify-center items-center
                lg:pt-0 pt-8">

                    <select
                        className="p-1 text-[16px] font-manrope text-blackSurface w-[300px] h-[45px]  rounded-full py-[10px] px-6 outline-none"
                    >
                        <option value="">ISLAMABAD</option>
                        <option value="service1">LAHORE</option>
                        <option value="service2">KARACHI</option>
                        <option value="service3">QUETTA</option>
                    </select>
                </Box>

            </Box>


            <Box className="lg:py-120px lg:px-120px md:py-[72px] md:px-16 py-6 px-4 bg-white
       grid lg:grid-cols-2 grid-cols-1 gap-10">



                <Box className="rounded-[30px] border border-MistyLilc">
                    <Box className="lg:py-6 lg:px-6 md:py-6 md:px-6 py-5 px-3   border-b-MistyLilc border-b">

                        <CustomTypography
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold' alignment="lg:text-left  text-center"
                        >
                            G6, Aabpara Branch, Islamabad
                        </CustomTypography>



                        <CustomTypography
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment="lg:text-left  text-center"
                        >
                            Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur
                        </CustomTypography>

                        <Box className="grid grid-cols-2 lg:gap-6 md:gap-6 gap-3 pt-5">

                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Location} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    Directions
                                </CustomTypography>


                            </Box>



                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Mobile} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    +92 342 898 8232
                                </CustomTypography>


                            </Box>

                        </Box>

                    </Box>


                    {/* 2nd */}


                    <Box className="lg:py-6 lg:px-6 md:py-6 md:px-6 py-5 px-3  border-b-MistyLilc border-b">

                        <CustomTypography
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold' alignment="lg:text-left  text-center"
                        >
                            G6, Aabpara Branch, Islamabad
                        </CustomTypography>



                        <CustomTypography
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment="lg:text-left  text-center"
                        >
                            Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur
                        </CustomTypography>

                        <Box className="grid grid-cols-2 lg:gap-6 md:gap-6 gap-3 pt-5">

                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Location} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    Directions
                                </CustomTypography>


                            </Box>



                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Mobile} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    +92 342 898 8232
                                </CustomTypography>


                            </Box>

                        </Box>

                    </Box>


                    {/* 3rd */}


                    <Box className="lg:py-6 lg:px-6 md:py-6 md:px-6 py-5 px-3  ">

                        <CustomTypography
                            as='p' variant='p3' color='text-customBlack' weightFont='font-semibold' alignment="lg:text-left  text-center"
                        >
                            G6, Aabpara Branch, Islamabad
                        </CustomTypography>



                        <CustomTypography
                            as='p' variant='p3' color='text-SlateGray' weightFont='font-normal' alignment="lg:text-left  text-center"
                        >
                            Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur
                        </CustomTypography>

                        <Box className="grid grid-cols-2 lg:gap-6 md:gap-6 gap-3 pt-5">

                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Location} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    Directions
                                </CustomTypography>


                            </Box>



                            <Box className="flex flex-row gap-2">
                                <Box className="flex items-center">
                                    <img src={Mobile} alt="location" className="h-[20px] w-[20px] object-cover " />
                                </Box>


                                <CustomTypography
                                    as='p' variant='p3' color='text-SlateGray' weightFont='font-normal'
                                >
                                    +92 342 898 8232
                                </CustomTypography>


                            </Box>

                        </Box>

                    </Box>


                </Box>


            </Box>


        </Box>
    )
}

export default ContactUs;